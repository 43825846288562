import React, { useEffect, useRef, useState } from "react";
import DAGSteppers from "./DAGSteppers";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PersonalsDatasForm from "./PersonalsDatasForm";
import RestaurantDatasForm from "./RestaurantDatasForm";
import ValidationInscriptionForm from "./ValidationInscriptionForm";
import "../styles/Footer.scss";
import "../styles/Inscription.scss";
import { Footer } from "./Footer";
import SuccessPage from "./SuccessPage";
import { useNavigate, useParams } from "react-router";
import FadeComponent from "./FadeComponent";
import { hasAuthentication } from "../services/Auth";
import { useTranslation } from "react-i18next";
import myGa from "../Google-Analytics/myGa";
import { useDispatch, useSelector } from "react-redux";
import { setReferralCode } from "../redux/slice/referralCode.slice";

const steps = [
  "register_personalform_title",
  "register_restaurant_form",
  // "Abonnement",
  "register_validation_form",
];

const Inscription = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [containsError, setContainsError] = useState(false);
  const childRef = useRef(null);
  const { t } = useTranslation();

  const [personnalDatas, setPersonnalDatas] = useState();
  const [restaurantDatas, setRestauranDatas] = useState();
  const [abonnementDatas, setAbonnementDatas] = useState();
  const [infoPayment, setInfoPayment] = useState();
  const navigate = useNavigate();
  const { referralCode } = useParams();
  const dispatch = useDispatch();
  const {referral} = useSelector((state) => state.referral);


  useEffect(() => {
    hasAuthentication() && navigate("/manage/menu/");
    myGa();
    referralCode && referralCode !== "undefined" && dispatch(setReferralCode(referralCode));
  }, [referralCode]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    const noErrors = childRef.current?.checkForErrors();

    if (noErrors) {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    }
  };

  const sections = [
    <PersonalsDatasForm
      ref={childRef}
      setContainsError={setContainsError}
      handleComplet={handleComplete}
      setPersonnalDatas={setPersonnalDatas}
      personnalDatas={personnalDatas}
      t={t}
      referral={referral}
    />,
    <RestaurantDatasForm
      ref={childRef}
      setContainsError={setContainsError}
      handleComplet={handleComplete}
      setRestauranDatas={setRestauranDatas}
      restaurantDatas={restaurantDatas}
      t={t}
    />,
    // <TariffPlanForm
    //   ref={childRef}
    //   setContainsError={setContainsError}
    //   handleComplet={handleComplete}
    //   setAbonnementDatas={setAbonnementDatas}
    //   abonnementDatas={abonnementDatas}
    // />,
    <ValidationInscriptionForm
      ref={childRef}
      setContainsError={setContainsError}
      handleComplet={handleComplete}
      abonnementDatas={abonnementDatas}
      personnalDatas={personnalDatas}
      restaurantDatas={restaurantDatas}
      setInfoPayment={setInfoPayment}
      t={t}
      referral={referral}
    />,
    <SuccessPage
      message={"Inscription réussie !"}
      action={() => navigate("menu/login")}
      t={t}
    />,
    // (infoPayment?.plan) && <Checkout plan={infoPayment?.plan} paymentPage={infoPayment?.page} />
  ];

  return (
    <FadeComponent>
      <div>
        <div className="container pt-5 inscription">
          <DAGSteppers
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            completed={completed}
            setCompleted={setCompleted}
            t={t}
          />
          <Typography sx={{ mt: 2, mb: 1 }}> {sections[activeStep]}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </FadeComponent>
  );
};

export default Inscription;
