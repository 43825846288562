//import Axios from 'axios';

import Axios from "axios";
import { imagePath } from "../utils/utils";
import { getItem } from "../services/localStorage";
import { tokenName } from "../services/Auth";
//import { urlImageProduct } from "../utils/utils";

let baseUrl = "";

if (process.env.NODE_ENV !== "development")
  baseUrl = "https://o-resto-api.vercel.app";
else baseUrl = "http://localhost:5000";

export { baseUrl };

/* .......................................... METHODES ........................................ */
export const createSubscription = async ({
  email,
  price,
  stripeToken,
  paymentMethod,
  productKey,
}) => {
  return await Axios({
    method: "post",
    url: `${baseUrl}/subscription`,
    data: {
      email,
      price,
      stripeToken,
      paymentMethod,
      productKey,
    },
  });
};

export const manageSubscription = async ({ customer }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "post",
    url: `${baseUrl}/subscription/manage-billing`,
    data: {
      customer,
    },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const cancelSubscription = async ({ id, restaurantId }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "delete",
    url: `${baseUrl}/subscription/cancel`,
    data: {
      id,
      restaurantId,
    },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getSubscription = async ({ email }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "post",
    url: `${baseUrl}/subscription`,
    data: {
      email,
    },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const createMenu = async ({ label, restaurantId, isActive = false }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "post",
    url: `${baseUrl}/menu/create`,
    data: { label, isActive, restaurantId },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const updateMenu = async ({
  label,
  restaurantId,
  isActive,
  oldLabel,
}) => {
  const token = getItem(tokenName);


  return await Axios({
    method: "patch",
    url: `${baseUrl}/menu/update`,
    data: { label, isActive, restaurantId, oldLabel },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteMenu = async ({ id }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "delete",
    url: `${baseUrl}/menu/delete`,
    data: { id },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const sendMail = async ({ to, templateId, data }) => {
  return await Axios({
    method: "post",
    url: `${baseUrl}/mail/send`,
    data: { data, templateId, to },
  });
};

export const getRestaurantByUserId = async ({ id }) => {
  return await Axios.get(`${baseUrl}/restaurants/owner/${id}`);
};

export const getActiveRestaurants = async () => {
  return await Axios.get(`${baseUrl}/restaurants/`);
};

export const getRestaurantById = async ({ id }) => {
  return await Axios.get(`${baseUrl}/restaurants/${id}`);
};

export const getUserById = async ({ id }) => {
  return await Axios.get(`${baseUrl}/users/${id}`);
};

export const getUserByEmail = async ({ email }) => {
  return await Axios.get(`${baseUrl}/users/retrieve/${email}`);
};

export const createRestaurant = async ({
  name,
  country,
  address,
  city,
  logo = `${imagePath}/default-logo-resto.png`,
  owner,
  certified,
  zipCode = false,
}) => {
  return await Axios({
    method: "post",
    url: `${baseUrl}/restaurants/subscribe`,
    data: {
      name,
      country,
      address,
      city,
      logo,
      owner,
      certified,
      zipCode,
    },
  });
};

export const getAllergens = async () => {
  return await Axios.get(`${baseUrl}/allergens/`);
};

export const getSectionByCategory = async ({ categoryId }) => {
  return await Axios.get(`${baseUrl}/category/section/${categoryId}`);
};

export const uploadImage = async ({ formData }) => {
  const token = getItem(tokenName);

  return await Axios.post(`${baseUrl}/image/upload`, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadImages = async ({ formData }) => {
  const token = getItem(tokenName);

  return await Axios.post(`${baseUrl}/image/uploads`, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const removeImage = async ({ fileName }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "delete",
    url: `${baseUrl}/image/delete-image`,
    data: {
      fileName,
    },

    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteImages = async ({ fileNames }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "delete",
    url: `${baseUrl}/image/delete-images`,
    data: {
      fileNames,
    },

    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteImage = async ({ fileName, productId, image }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "delete",
    url: `${baseUrl}/image/delete`,
    data: {
      fileName,
      productId,
      image,
    },

    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const addProduct = async ({
  label,
  categoryId,
  price,
  images,
  ingredients,
  allergens,
}) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "post",
    url: `${baseUrl}/products/create`,
    data: {
      label,
      categoryId,
      price,
      images,
      ingredients,
      allergens,
    },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const updateProduct = async ({ id, newProduct, oldLabel }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "patch",
    url: `${baseUrl}/products/update`,
    data: { id, newProduct, oldLabel },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteProduct = async ({ product }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "delete",
    url: `${baseUrl}/products/delete`,
    data: { product },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const registration = async ({ user, restaurant }) => {
  return await Axios({
    method: "post",
    url: `${baseUrl}/users/subscribe`,
    data: {
      user,
      restaurant,
    },
  });
};

export const createUser = async ({
  email,
  password,
  country,
  phone,
  address,
  city,
  isAdmin = false,
  zipCode,
}) => {
  return await Axios({
    method: "post",
    url: `${baseUrl}/users/create`,
    data: {
      email,
      password,
      country,
      phone,
      address,
      city,
      admin: isAdmin,
      zipCode,
    },
  });
};

export const createSection = async ({ label, menuId }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "post",
    url: `${baseUrl}/section/create`,
    data: { label, menuId },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteSection = async ({ label, menuId, id }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "delete",
    url: `${baseUrl}/section/delete`,
    data: { label, menuId, id },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const updateSection = async ({ label, menuId, oldLabel, id }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "patch",
    url: `${baseUrl}/section/update`,
    data: { label, menuId, oldLabel, id },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getMenu = async (restaurantId, active) => {
  return await Axios({
    method: "post",
    url: `${baseUrl}/menu/`,
    data: {
      restaurantId,
      active,
    },
  });
};

export const createCategory = async ({ label, sectionId }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "post",
    url: `${baseUrl}/category/create`,
    data: { label, sectionId },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const updateCategory = async ({ label, sectionId, oldLabel, id }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "patch",
    url: `${baseUrl}/category/update`,
    data: { label, sectionId, oldLabel, id },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteCategory = async ({ id }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "delete",
    url: `${baseUrl}/category/delete`,
    data: { id },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getPrice = async (priceId) => {
  return await Axios({
    method: "get",
    url: `${baseUrl}/subscription/price/${priceId}`,
  });
};

export const getProduct = async (productId) => {
  return await Axios({
    method: "get",
    url: `${baseUrl}/subscription/product/${productId}`,
  });
};

export const updateUser = async ({ id, newUser, oldEmail }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "patch",
    url: `${baseUrl}/users/update`,
    data: { id, newUser, oldEmail },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const updatePassword = async ({ id, password }) => {
  return await Axios({
    method: "patch",
    url: `${baseUrl}/users/password-reset`,
    data: { id, password },
  });
};

export const updateRestaurant = async ({ id, newResto }) => {
  const token = getItem(tokenName);

  return await Axios({
    method: "patch",
    url: `${baseUrl}/restaurants/update`,
    data: { id, newResto },
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const convertCurrency = async ({ amounts, devise }) => {
  return await Axios({
    method: "post",
    url: `${baseUrl}/currency/convert`,
    data: {
      amounts,
      devise,
    },
  });
};
