import React, { useState } from "react";
import "../styles/HorizontalProductList.scss";
import HorizontalItem from "./HorizontalItem";
import { Drawer, Paper, useMediaQuery } from "@mui/material";
import { AddBox } from "@mui/icons-material";
import { ProductAdd } from "./PagesUpdate";
import { useTranslation } from "react-i18next";

const HorizontalProductList = ({
  products,
  section,
  sectionList,
  configMode = false,
  categoryId,
  country,
}) => {
  const [open, setOpen] = useState(false);
  const mobileTabletteScreen = useMediaQuery("(max-width:898px)");
  const { t } = useTranslation();
  return (
    <div id={!configMode ? "horizontalList" : "listConfig"}>
      {products.map((product, key) => {
        return (
          <div id="item" key={key}>
            <HorizontalItem
              sectionList={sectionList}
              section={section}
              key={key}
              product={product}
              configMode={configMode}
              country={country}
            />
          </div>
        );
      })}
      {configMode && (
        <div>
          {" "}
          <Paper
            onClick={() => setOpen(true)}
            id={"itemAdd"}
            sx={{
              textAlign: "center",
              borderTop: "1px solid transparent",

              fontSize: "large",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
            elevation={4}
          >
            <div style={{ margin: "0 auto" }}>
              <div>{t("add_procduct_label")}</div>
              <div>
                <AddBox />
              </div>
            </div>
          </Paper>
          <Drawer
            PaperProps={{
              style: { width: mobileTabletteScreen ? "100%" : "50%" },
            }}
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
          >
            <ProductAdd categoryId={categoryId} setOpen={setOpen} />
          </Drawer>
        </div>
      )}
    </div>
  );
};

export default HorizontalProductList;
