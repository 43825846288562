import React, {  } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import "../styles/Modal.scss";
import { greenOlive } from "../utils/utils";
import Choice from "./Choice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ open, setOpen, fullScreen = true, component, title }) => {
  
  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          style={{ background: greenOlive }}
          sx={{ position: "relative" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              className="title"
              color={"white"}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            >
              {title}
            </Typography>

           {/* <Choice white={true} /> */}
          </Toolbar>
        </AppBar>
        {component}
      </Dialog>
      </div>
  );
};

export default Modal;
