import React from "react";
import '../../styles/LoadersStyle/LoaderThreeDot.scss';

const LoaderThreeDot = () => {
  return (
    <div>
      <div className="three-body">
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
      </div>
    </div>
  );
};

export default LoaderThreeDot;
