import { configureStore } from "@reduxjs/toolkit";
import { productSlice } from "./slice/product.slice";
import { choiceSlice } from "./slice/choice.slice";
import { restaurantSlice } from "./slice/restaurant.slice";
import { allergenSlice } from "./slice/allergen.slice";
import { userSlice } from "./slice/user.slice";
import { referralCodeSlice } from "./slice/referralCode.slice";

export const store = configureStore({
  reducer: {
    products: productSlice.reducer,
    choices: choiceSlice.reducer,
    infosRestaurant: restaurantSlice.reducer,
    allergens : allergenSlice.reducer,
    user : userSlice.reducer,
    referral : referralCodeSlice.reducer,
  },
});
