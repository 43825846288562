import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";

export default function AlertMessage({
  toOpen,
  title,
  text,
  acceptText,
  declineText,
  acceptAction,
  setToOpen
}) {
  const [open, setOpen] = React.useState(toOpen);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setOpen(toOpen);
  }, [toOpen]);

  
  const handleClose = () => {
    setOpen(false);
    setToOpen(false);
  };

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="info" autoFocus onClick={handleClose}>
            {declineText}
          </Button>
          <Button
            onClick={acceptAction ? acceptAction : handleClose}
            autoFocus
            color="warning"
          >
            {acceptText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
