import { MDBFooter } from "mdb-react-ui-kit";
import React from "react";
import { imagePath } from "../utils/utils";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FooterLink = () => {
  const {t} = useTranslation()

  return (
    <MDBFooter className=" bg-dark text-center text-white text-md-start">
      <div
        style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        className="row pt-3"
      >
        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <h6 className="text-uppercase fw-bold mb-4">
            <i className="fas text-secondary">
              {" "}
              <img
                style={{ width: "2rem", height: "2rem" }}
                src={`${imagePath}/orestoICO.png`}
                alt="logo oresto"
              />{" "}
              o'resto
            </i>
          </h6>

          <p className="p-2 pt-0 pb-0">
            {t('footer_oresto_description')}
          </p>
        </div>

        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 className="text-uppercase fw-bold mb-4">démo</h6>
          <p>
           
            <a
              href="/menu/6497fd81e3cfe9daa193d0d4"
              target="_blank"
              className="text-reset"
              style={{ textDecoration: "none" }}
            >
              {t('menupage_final_demo')}
            </a>
          </p>
        </div>

        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 className="text-uppercase fw-bold mb-4">Liens Utiles</h6>
          <p>
            <NavLink style={{ textDecoration: "none", color: 'white' }} to={"/manager/menu"}>
              E-Menu
            </NavLink>
          </p>
          <p>
            <NavLink style={{ textDecoration: "none", color: 'white' }} to={"/manager/pricing"}>
            {t('pricing_label')}
            </NavLink>
          </p>
        </div>

        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
          <p>
            <i className="fas fa-home me-3 text-secondary"></i> 5000, Namur
          </p>
          <p>

            <a
              className="text-light"
              data-mdb-ripple-color="light"
              href="mailto:contact@oresto.company"
              style={{ textDecoration: "none" }}

            >
              <i className="fas fa-envelope me-3 text-secondary"></i>
              contact@oresto.company
            </a>
          </p>
          {/* <p>
            <i className="fas fa-phone me-3 text-secondary"></i> + 01 234 567 88
          </p>
          <p>
            <i className="fas fa-print me-3 text-secondary"></i> + 01 234 567 89
          </p> */}
        </div>
      </div>
    </MDBFooter>
  );
};

export default FooterLink;
