import { Button, Chip, Divider, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import {
  consoleLog,
  countriesWithDevise,
  euro,
  imagePath,
  paymentLink,
} from "../utils/utils";
import "../styles/Tarifs.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import FadeComponent from "./FadeComponent";
import { getConnectedUser, hasAuthentication } from "../services/Auth";
import { useNavigate } from "react-router";
import {
  convertCurrency,
  getSubscription,
  manageSubscription,
} from "../API/api";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import { useTranslation } from "react-i18next";
import myGa from "../Google-Analytics/myGa";
import CountryInfo from "./CountryInfo";

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 60,
    width: "100%",
    backgroundColor: "#F58216",
  },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "uppercase",

    fontSize: theme.typography.pxToRem(16),

    color: "black",
    "&.Mui-selected": {
      color: "#F58216",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//___________________________________________________

const Plan = ({ p, key, convertCurrency, country, isLoading }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubscription = () => {
    if (!loading) {
      if (hasAuthentication()) {
        const { email } = getConnectedUser();

        setLoading(true);

        getSubscription({ email })
          .then((res) => {
            manageSubscription({ customer: res.data.customer })
              .then((res) => {
                const link = document.createElement("a");
                link.href = res.data.url;
                setLoading(false);
                link.click();
              })
              .catch((e) => {
                consoleLog("error manage sub tarif: ", e);
                setLoading(false);
              });
          })
          .catch((e) => {
            consoleLog("error get subscription in tarif: ", e);
            setLoading(false);

            const link = document.createElement("a");
            link.href = paymentLink({ key: p?.idLink, email });

            link.click();
          });
      } else {
        navigate("/manager/menu/login");
      }
    }
  };
  return (
    <div
      style={{
        alignItems: "center",
        cursor: "default",
        position: "relative",
      }}
      className="item"
      key={key}
    >
      {p?.prefered && (
        <Chip
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "60%",
            // padding: "0.5rem",
            textTransform: "uppercase",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            letterSpacing: "2px",

            position: "absolute",
            top: "60px",
          }}
          variant="outlined"
          label="Most popular"
          size="small"
          color="warning"
        />
      )}
      <div className="top">
        <div className="title"> {p.title} </div>
        {
          <div
            style={{
              textDecoration: p?.title === "Event" && "line-through",
              color: p?.title === "Event" && "grey",
              fontWeight: p?.title === "Event" && "lighter",
            }}
            className="firstPrice"
          >
            {" "}
            {`0 ${country?.symbole}`}{" "}
          </div>
        }
        {!isLoading && (
          <div className="price">
            {p?.title !== "Event" && (
              <span>
                {" "}
                {`${t("puis")} ${
                  convertCurrency?.amount
                    ? convertCurrency?.result[country?.currency]?.toFixed(2)
                    : p.price.toFixed(2)
                } ${country?.symbole} HT / ${t("mois")}`}{" "}
              </span>
            )}
            {p?.title === "Event" && (
              <span> {`${p.price.toFixed(2)}${euro} ${t("semaine")}`} </span>
            )}
          </div>
        )}
        {isLoading && <LoaderThreeDot />}
        <div className="freeBtn"> {t(p?.ruban)} </div>
        <div className="description"> {t(p.description)} </div>
      </div>

      <Divider
        style={{
          width: "50%",
          margin: "0 auto",
          textAlign: "center",
          marginTop: "4rem",
          marginBottom: "4rem",
        }}
        color={"black"}
      />

      <div className="bottom">
        <div className="checks">
          {p.items.map((i, k) => {
            return (
              <div key={k} className="check">
                <div className="icon">
                  <img src={`${imagePath}/check.png`} alt="check" />
                </div>
                <div className="text"> {t(i)} </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="select">
        <div
          style={{
            backgroundColor: (loading || p?.disable) && "lightgray",
            color: p?.disable && "gray",
          }}
          onClick={handleSubscription}
          className="btn"
        >
          {loading ? <LoaderThreeDot /> : t("button_choose")}
        </div>
      </div>
    </div>
  );
};

//______________________________________________________________

const Tarifs = ({ mobile = false }) => {
  const maxWidth1026 = useMediaQuery("(max-width:790px)");
  const [value, setValue] = useState(1);
  const { t } = useTranslation();
  const [country, setCountry] = useState(null);
  const [convertedCurrencyies, setConvertedCurrencies] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    myGa();
    setCountry(countriesWithDevise[19]);
    setSelectedCountry(countriesWithDevise[19]);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCurrrency = () => {
    if (
      country.currency?.toLowerCase() !==
      selectedCountry.currency?.toLowerCase()
    ) {
      setLoading(true);
      setSelectedCountry(country);
      const amounts = plans.map((p) => p.price);
      convertCurrency({ amounts, devise: country?.currency })
        .then((res) => {
          setLoading(false);
          setConvertedCurrencies(res.data);
        })
        .catch((e) => {
          consoleLog("error convert currency: ", e);
          setLoading(false);
          setConvertedCurrencies([]);
        });
    }
  };

  return (
    <FadeComponent>
      <div className="tarif">
        <div className="header">
          <div className="title"> {t("tarif_title")} </div>
          <div className="subTitle">{t("tarif_subtitle")}</div>
        </div>
        <div className="countryInfo">
          <CountryInfo
            showDevise={true}
            selectCountryName={true}
            label={"Change a currency"}
            setInfoCountry={setCountry}
          />
          <Button
            onClick={() => {
              handleChangeCurrrency();
            }}
            fullWidth
            variant="contained"
            color="secondary"
          >
            {loading ? <LoaderThreeDot /> : "change currency"}
          </Button>
        </div>

        {maxWidth1026 ? (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                centered
              >
                {plans.map((p, key) => {
                  return (
                    <StyledTab
                      key={key}
                      label={p?.title}
                      {...a11yProps(p?.title)}
                    />
                  );
                })}
              </StyledTabs>
            </Box>
            {/* <div className="items"> */}
            <TabPanel className="items" value={value} index={0}>
              <Plan
                isLoading={loading}
                country={selectedCountry}
                convertCurrency={convertedCurrencyies[0]}
                p={plans[value]}
                key={0}
              />
            </TabPanel>
            <TabPanel className="items" value={value} index={1}>
              <Plan
                isLoading={loading}
                country={selectedCountry}
                convertCurrency={convertedCurrencyies[1]}
                p={plans[value]}
                key={1}
              />
            </TabPanel>
            <TabPanel className="items" value={value} index={2}>
              <Plan
                isLoading={loading}
                country={selectedCountry}
                convertCurrency={convertedCurrencyies[2]}
                p={plans[value]}
                key={2}
              />
            </TabPanel>
            {/* </div> */}
          </Box>
        ) : (
          <Row className="items">
            {plans.map((p, key) => {
              return (
                <Plan
                  isLoading={loading}
                  country={selectedCountry}
                  convertCurrency={convertedCurrencyies.find(
                    (c) => c.amount === p.price.toString()
                  )}
                  p={p}
                  key={key}
                />
              );
            })}
          </Row>
        )}
      </div>
    </FadeComponent>
  );
};

export const plans = [
  {
    title: "Basic",
    price: 70,
    ruban: "tarif_ruban_default",
    description: "tarif_description_default",
    items: [
      `tarif_item_formation`,
      `tarif_item_nb_product`,
      `tarif_item_without_engagemnt`,

      `tarif_item_update`,
      // `50 plaques ou 100 autocollants de table avec votre QRcode`,
    ],
    plan: process.env.REACT_APP_PRICE_BASIC,
    idLink:
      process.env.NODE_ENV === "production"
        ? "28o6p83OPfVX3BeeV1"
        : "test_eVa6oWd2pcbkgaAcMU",
    disable: false,
  },

  {
    title: "VIP",
    price: 49.99,
    ruban: "tarif_ruban_default",
    description: "tarif_description_default",
    items: [
      `tarif_item_formation`,
      `tarif_item_nb_product`,
      `tarif_item_with_engagemnt`,
      `tarif_item_update`,
      // `50 plaques ou 100 autocollants de table avec votre QRcode`,
    ],
    plan: process.env.REACT_APP_PRICE_PRO,
    idLink:
      process.env.NODE_ENV === "production"
        ? "eVadRAfxx8tv2xaeV0"
        : "test_5kA4gOfax4IS3nOeV1",
    disable: false,
    prefered: true,
  },
  {
    title: "Welcome",
    price: 1,
    ruban: "tarif_ruban_free",
    description: "tarif_description_default",
    items: [
      `tarif_item_formation`,
      `tarif_item_update`,
      `tarif_item_without_engagemnt`,
      `tarif_item_nb_product`,
    ],
    plan: process.env.REACT_APP_PRICE_FREE,
    idLink:
      process.env.NODE_ENV === "production"
        ? "8wM28Sdpp39b0p2bIS"
        : "test_8wMeVsfax4IS5vWeV5",
    disable: false,
  },
  // {
  //   title: "Event",
  //   price: 29.99,
  //   ruban: "ACHAT UNIQUE !",
  //   description: `Création du menu pour vos évènements (mariages, cérémonies, anniversaires, etc...) par NOS SOINS.
  //   Vous avez aussi la possibilité d'ajouter , de modifier et de supprimer les plats selon vos besoins.
  //   Une fois terminé, vous pourrez facilement imprimer votre propre QR-Code directement à partir de notre plateforme.`,
  //   items: [
  //     `Mises à jour illimitées de vos plats`,
  //     `Nombre illimité de plats`,
  //     `Disponible pendant 1 semaine`,
  //     // `Frais de mise en service : 400 € HT`,
  //   ],
  //   plan: process.env.REACT_APP_PRICE_BASIC,
  //   idLink:
  //     process.env.NODE_ENV === "production"
  //       ? "28ocNw5WXfVX9ZC146"
  //       : "test_8wMdRod2p0sC1fG28a",
  //   disable: true,
  // },
];
//Création de votre menu par NOS SOINS (sous réserve de la fourniture de vos plats et leurs images)
export default Tarifs;
