import * as React from "react";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "../styles/SideBar.scss";
import { useState, useEffect } from "react";
import {
  Close,
  Logout,
  MonetizationOn,
  Person,
  QrCode,
  Restaurant,
  Settings,
} from "@mui/icons-material";
import ShopIcon from "@mui/icons-material/Shop";
import {
  AppBar,
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { removeItem } from "../services/localStorage";
import {
  getConnectedUser,
  hasAuthentication,
  tokenName,
} from "../services/Auth";
import AlertMessage from "./AlertMessage";
import { Outlet, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import {
  getAllergens,
  getMenu,
  getRestaurantByUserId,
  getSubscription,
  updateMenu,
} from "../API/api";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveMenu,
  menuActivationStatus,
  setMenu,
  setRestaurant,
} from "../redux/slice/restaurant.slice";
import { greenOlive, imagePath } from "../utils/utils";
import { setAllergens } from "../redux/slice/allergen.slice";
import LoadingPage from "./LoadingPage";
import MenuCreaction from "./MenuCreation";
import AbonnementUser from "./AbonnementUser";
import ProfilUser from "./ProfilUser";
import QrCodeManage from "./QrCodeManage";
import Shop from "./Shop";
import { useTranslation } from "react-i18next";
import { LanguageSelect } from "./LanguageSelect";

const drawerWidth = 400;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "rgba(0, 0, 0, 0.699)",
  color: "white",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  border: 0,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "rgba(0, 0, 0, 0.699)",
  color: "white",
  border: 0,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerPermanent = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  backgroundColor: "transparent",
  border: 0,
}));

const items = [
  {
    label: "qrcode_label",
    icon: <QrCode />,
    link: "qrcode",
    component: <QrCodeManage />,
  },
  {
    label: "subscription_label",
    icon: <MonetizationOn />,
    link: "abonnement",
    component: <AbonnementUser />,
  },

  {
    label: "emenu_label",
    icon: <Restaurant />,
    link: "menu",
    component: <MenuCreaction />,
  },

  {
    label: "shop_label",
    icon: <ShopIcon />,
    link: "shop",
    component: <Shop />,
  },
  {
    label: "profil_label",
    icon: <Person />,
    link: "profil",
    component: <ProfilUser />,
  },
];

const Dashbord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [restaurant, setRestaurantData] = useState();
  const [toOpen, setToOpen] = useState(false);
  const handleLogout = () => {
    setToOpen(true);
  };
  const mobileTabletteScreen = useMediaQuery("(max-width:898px)");
  const isPhone = useMediaQuery("(max-width: 644px)");
  const [open, setOpen] = useState(!mobileTabletteScreen && !isPhone);

  const [loadingPage, setLoadingPage] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const { menu } = useSelector((state) => state.infosRestaurant);
  const [pageNumber, setPageNumber] = useState(2);

  useEffect(() => {
    if (!hasAuthentication()) {
      navigate("/manager/menu/login");
      return;
    }

    const connectedUser = getConnectedUser();

    setLoadingPage(true);

    getRestaurantByUserId(connectedUser)
      .then((res) => {
        dispatch(setRestaurant(res?.data));
        setRestaurantData(res?.data);

        getMenu(res?.data._id)
          .then((res) => {
            dispatch(setMenu(res?.data));

            getSubscription({ email: connectedUser?.email })
              .then((res) => {
                const sub = res.data;
                handleUpdateActiveMenu(sub);
              })
              .catch((e) => {
                console.error("error sub : ", e);
              });

            getAllergens()
              .then((res) => {
                dispatch(setAllergens(res.data));
                setLoadingPage(false);
              })
              .catch((e) => {
                console.error("error getAllergens : ", e);
                setLoadingPage(false);
                setErrorLoading(true);
              });
          })
          .catch((e) => {
            console.error(e.response?.data || e.response?.data);
            setLoadingPage(false);
          });
      })
      .catch((e) => {
        console.error(e);
        setLoadingPage(false);
      });
  }, []);

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const handleUpdateActiveMenu = (subscription) => {
    if (!subscription) return;

    let readyToActivate = false;

    const valid =
      new Date(subscription?.current_period_end * 1000) >= Date.now();

    if (
      subscription?.product?.name.toLowerCase() === "basic" ||
      subscription?.product?.name.toLowerCase() === "welcome plan"
    ) {
      readyToActivate = subscription && valid;
    } else if (subscription?.product?.name.toLowerCase() === "vip") {
      readyToActivate = subscription !== undefined;
    }

    patchMenu(readyToActivate);
    dispatch(menuActivationStatus(readyToActivate));
  };

  const patchMenu = (isActive) => {
    updateMenu({
      oldLabel: menu?.label,
      restaurantId: menu?.restaurantId,
      isActive,
    })
      .then((res) => {
        dispatch(changeActiveMenu(isActive));
      })
      .catch((e) => {
        console.error("error active menu : ", e);
      });
  };

  const content = (permanent = true) => {
    return (
      <>
        <div className="content">
          <List className="liste">
            {items.map((i, key) => (
              <NavLink
                key={key}
                style={({ isActive }) => {
                  return {
                    color: "black",
                    textDecoration: "none",
                  };
                }}
                to={i?.link}
              >
                <ListItem
                  onClick={() => {
                    setPageNumber(key);
                    !permanent && setOpen(false);
                  }}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "whitesmoke",
                      }}
                    >
                      {i?.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={t(i?.label)}
                      sx={{ opacity: open ? 1 : 0, color: "whitesmoke" }}
                    />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            ))}
          </List>
        </div>
        <div>
          <LanguageSelect text={open && "language"} />
        </div>
        <div className="bottom">
          <Divider color="white" />
          <div onClick={handleLogout}>
            <Item
              open={open}
              icon={<Logout sx={{ color: "whitesmoke" }} />}
              label={t("logout_label")}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {loadingPage ? (
        <LoadingPage />
      ) : (
        <Box
          component={"div"}
          className="sideBar"
          sx={{ display: mobileTabletteScreen || isPhone ? "block" : "flex" }}
        >
          {!mobileTabletteScreen && (
            <DrawerPermanent variant={"permanent"} open={open}>
              <DrawerHeader className="header">
                {open && (
                  <div className="head">
                    <Avatar
                      src={`${imagePath}/default-logo-resto.png`}
                      srcSet={restaurant?.logo}
                    />
                    <div style={{ color: "whitesmoke", paddingLeft: "1rem" }}>
                      {`${restaurant?.name || "DASHBORD"}`}
                    </div>
                  </div>
                )}
                <div>
                  <IconButton onClick={handleDrawerClose}>
                    {!open ? (
                      <MenuIcon sx={{ color: "whitesmoke" }} />
                    ) : (
                      <ChevronLeftIcon sx={{ color: "whitesmoke" }} />
                    )}
                  </IconButton>
                </div>
              </DrawerHeader>
              <Divider />
              {content()}
            </DrawerPermanent>
          )}

          <AlertMessage
            toOpen={toOpen}
            title={t("logout_label")}
            text={t("modal_logout_text")}
            acceptText={t("navbar_logout_btn")}
            declineText={t("cancel_label")}
            acceptAction={() => {
              removeItem(tokenName);
              dispatch(setMenu(undefined));
              dispatch(setRestaurant(undefined));
              !hasAuthentication() && navigate("/manager/menu/login");
            }}
            setToOpen={setToOpen}
          />

          {/* _________________DRAWER MOBILE________________ */}

          <Box
            className="main"
            component="main"
            // sx={{ flexGrow: 1, p: isPhone || mobileTabletteScreen ? 0 : 3 }}
          >
            {mobileTabletteScreen && (
              <Box sx={{ display: "block" }}>
                <ThemeProvider
                  theme={createTheme({
                    palette: {
                      primary: { main: "rgb(0, 0, 0)" },
                    },
                  })}
                >
                  {/* barre de tache */}
                  <AppBar position="static">
                    <Toolbar>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                      >
                        Dashbord
                      </Typography>
                      <div>
                        {" "}
                        <IconButton
                          onClick={handleDrawerClose}
                          size="large"
                          color="inherit"
                          aria-label="menu"
                        >
                          <Settings />
                        </IconButton>
                      </div>
                    </Toolbar>
                  </AppBar>
                </ThemeProvider>

                <Drawer
                  PaperProps={{
                    style: {
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.699)",
                      textTransform: "capitalize",
                      position: "relative",
                      color: "white",
                    },
                  }}
                  anchor="top"
                  ModalProps={{
                    style: { backgroundColor: "rgba(0, 0, 0, 0.699)" },
                  }}
                  onClose={() => setOpen(false)}
                  open={open}
                >
                  <DrawerHeader
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "3rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "1rem",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <IconButton
                        onClick={handleDrawerClose}
                        size="large"
                        color="inherit"
                        aria-label="menu"
                      >
                        <Settings />
                      </IconButton>
                      <span> {t("settings_label")} </span>
                    </div>
                    <div>
                      <IconButton size="large" onClick={handleDrawerClose}>
                        <Close sx={{ color: "white" }} />
                      </IconButton>
                    </div>
                  </DrawerHeader>
                  <div>
                    <LanguageSelect setOpen={setOpen} text={"language"} />
                  </div>
                  <div
                    onClick={handleLogout}
                    style={{ padding: "1rem", cursor: "pointer" }}
                  >
                    <Logout /> <span> {t("logout_label")} </span>
                  </div>
                </Drawer>
              </Box>
            )}
            {/* <DrawerHeader /> */}
            <div className="contentDashbord">
              <div className="outlet">
                <Outlet />
                {/* {items[pageNumber]?.component} */}
              </div>
            </div>
            {mobileTabletteScreen && (
              <Box
                sx={{
                  zIndex: 1000,
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                }}
              >
                <BottomNavigation
                  showLabels
                  value={pageNumber}
                  onChange={(event, newValue) => setPageNumber(newValue)}
                  sx={{
                    border: "1px solid gray",
                    backgroundColor: greenOlive,
                  }}
                >
                  {items.map((item, key) => (
                    <BottomNavigationAction
                      key={key}
                      label={t(item.label)}
                      icon={item.icon}
                      color="secondary"
                      onClick={() => navigate(item?.link)}
                    />
                  ))}
                </BottomNavigation>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

const Item = ({ icon, label, open, link }) => {
  return (
    <NavLink
      style={({ isActive }) => {
        return {
          color: "black",
          textDecoration: "none",
        };
      }}
      to={link ? link : ""}
    >
      <ListItem>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={label}
          sx={{ opacity: open ? 1 : 0, color: "whitesmoke" }}
        />
      </ListItem>
    </NavLink>
  );
};

export default Dashbord;
