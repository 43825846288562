import React from "react";
import "../styles/ListOfChoice.scss";
import { useSelector } from "react-redux";
import ChoiceItem from "./ChoiceItem";
import { euro, findCountryDevise } from "../utils/utils";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// passer le pays en props
const ListOfChoice = ({country}) => {
  const { choices } = useSelector((state) => state.choices);
  const { t } = useTranslation();

  const showList = () => {
    const sections = [];
    let total = 0;

    let i = 0;
    while (i < choices.length) {
      const currentSection = choices[i].section;
      const items = [];

      while (i < choices.length && currentSection === choices[i].section) {
        items.push(<ChoiceItem key={i} choice={choices[i]} />);
        total += Number(choices[i].product.price);
        i++;
      }

      sections.push(
        <div className="section" key={i}>
          <h1 className="sectionName">{currentSection}</h1>
          <Typography
            variant="subTitle1"
            color="text.primary"
            component="div"
            align="left"
            paddingBottom={2}
            textTransform={"uppercase"}
          >
            {items}
          </Typography>
        </div>
      );
    }

    sections.push(<Divider light={false} color={"orange"} />);

    sections.push(
      <div className="footer">
        <div className="addition">
          <div className="title"> {t("cart_title")} </div>
          <div className="total">

            {/* changer la dévise */}
            {total.toFixed(2)} {findCountryDevise(country || "Belgium")}
          </div>
        </div>
        <div id="appetit"> {t("cart_last_word")} </div>
      </div>
    );

    return <>{sections}</>;
  };

  return (
    <div className="list">
      {choices.length !== 0 ? (
        <div>
          {showList()}
          <div className="bgImage"></div>
        </div>
      ) : (
        <Typography
          variant="subTitle1"
          color="text.secondary"
          component="h6"
          align="center"
          paddingTop={2}
          justifyContent={"center"}
          flex={1}
          textTransform={"uppercase"}
        >
          <div style={{ paddingBottom: "2rem" }}>
            {t("cart_empty_text_1")}
          </div>
          <div>
          {t("cart_empty_text_2")}
          </div>
          <div className="bgImage"></div>
        </Typography>
      )}
    </div>
  );
};

export default ListOfChoice;
