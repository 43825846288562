import React, { useState } from "react";
import "../styles/UpdatePasswordPage.scss";
import {
  Button,
  Collapse,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import { consoleLog, greenOlive } from "../utils/utils";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import { Done } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { updatePassword } from "../API/api";
import { useLocation, useNavigate } from "react-router";
import { TemporaryAlert } from "./PagesUpdate";
import FadeComponent from "./FadeComponent";
import ShowPassword from "./ShowPassword";

export default function UpdatePasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasGoodSize, setHasGoodSize] = useState(false);
  const [hasUpper, setHasUpper] = useState(false);
  const [hasLower, setHasLower] = useState(false);
  const [hasDigit, setHasDigit] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passMsgError, setPwdMsgError] = useState("");
  const { t } = useTranslation();
  const { id } = useLocation()?.state;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = () => {
    if (passwordConform()) {
      setError(false);
      setPwdMsgError("");
      setLoading(true);
      updatePassword({ id, password })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            navigate("/manager/menu/login");
          } else {
            setError(true);
            setPwdMsgError("Error updating password! Please try again.");
          }
        })
        .catch((err) => {
          consoleLog(err);
          setLoading(false);
          setError(true);
          setPwdMsgError(err.response.data?.error);
        });
    } else {
      setError(true);
      setPwdMsgError("Bad password format!");
    }
  };

  const passwordConform = () => {
    return hasDigit && hasUpper && hasLower && hasSpecialChar && hasGoodSize;
  };

  const handlePassword = (e) => {
    setPwdMsgError("");
    setError(false);
    const pass = e.target.value;
    setPassword(pass);

    setHasGoodSize(pass?.length >= 6);
    setHasUpper(/[A-Z]/.test(pass));
    setHasLower(/[a-z]/.test(pass));
    setHasDigit(/\d/.test(pass));
    setHasSpecialChar(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(pass));
  };

  const samePassword = () => {
    return (
      password !== "" && confirmPassword !== "" && password === confirmPassword
    );
  };
  return (
    <FadeComponent>
      <div className="updatePwd">
        <div className="bloc">
          <h2 className="title"> {t("password_page_title")} </h2>

          <TextField
            error={error}
            fullWidth
            label={`${t("password_label")}*`}
            className="field"
            variant="outlined"
            color="warning"
            value={password}
            onChange={(event) => {
              handlePassword(event);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ShowPassword
                    show={showPassword}
                    setShow={() => setShowPassword(!showPassword)}
                  />
                </InputAdornment>
              ),
            }}
            type={showPassword ? "text" : "password"}
          />

          <Collapse in={password !== ""}>
            <FormHelperText className="contentPwd">
              <div className="indicator">
                <Done
                  sx={{ width: 17 }}
                  color={hasGoodSize ? "success" : "disabled"}
                />{" "}
                <span style={{ color: hasGoodSize && "green" }}>
                  {t("register_min_caracter")}
                </span>
              </div>
              <div className="indicator">
                <Done
                  sx={{ width: 17 }}
                  color={hasUpper ? "success" : "disabled"}
                />{" "}
                <span style={{ color: hasUpper && "green" }}>
                  {t("register_min_upperCase")}
                </span>
              </div>
              <div className="indicator">
                <Done
                  sx={{ width: 17 }}
                  color={hasLower ? "success" : "disabled"}
                />{" "}
                <span style={{ color: hasLower && "green" }}>
                  {t("register_min_lowercase")}
                </span>
              </div>
              <div className="indicator">
                <Done
                  sx={{ width: 17 }}
                  color={hasDigit ? "success" : "disabled"}
                />{" "}
                <span style={{ color: hasDigit && "green" }}>
                  {t("register_min_digit")}
                </span>
              </div>
              <div className="indicator">
                <Done
                  sx={{ width: 17 }}
                  color={hasSpecialChar ? "success" : "disabled"}
                />{" "}
                <span style={{ color: hasSpecialChar && "green" }}>
                  {t("register_min_special_caracter")}
                </span>
              </div>
            </FormHelperText>
          </Collapse>

          <TextField
            error={confirmPassword && !samePassword()}
            label={`${t("confirm_password_label")}*`}
            fullWidth
            variant="outlined"
            className="field"
            color="warning"
            value={confirmPassword}
            onChange={(event) => {
              setConfirmPassword(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ShowPassword
                    show={showConfirmPassword}
                    setShow={() =>
                      setShowConfirmPassword(!showConfirmPassword)
                    }
                  />
                </InputAdornment>
              ),
            }}
            type={showConfirmPassword ? "text" : "password"}
          />

          <Button
            fullWidth
            sx={{
              padding: "1rem",
              backgroundColor: greenOlive,
              color: "white",
            }}
            className="field"
            variant="contained"
            onClick={handleSubmit}
            color="inherit"
            disabled={!passwordConform() || !samePassword()}
          >
            {loading ? <LoaderThreeDot /> : t("button_confirm")}
          </Button>

          <TemporaryAlert
            severity="error"
            open={error}
            setOpen={setError}
            text={passMsgError}
          />
        </div>
      </div>
    </FadeComponent>
  );
}
