import {createSlice} from '@reduxjs/toolkit';

export const  productSlice = createSlice({
    name : 'productSlice',
    initialState : {
        products : null,
        total : 0

    },
    reducers : {
        setProducts : (state, action) => {
            const products = action.payload;
            state.products = products;
        },
        setTotalProducts : (state, action) => {
            state.total = action.payload;
        }
    }
})

export const {setProducts, setTotalProducts} = productSlice.actions;