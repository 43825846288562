import React, { useEffect, useState } from "react";
import "../styles/VerticalItem.scss";
import Typography from "@mui/material/Typography";
import CarousselImage from "./CarousselImage";
import { consoleLog, findCountryDevise, formaterPrix } from "../utils/utils";
import Modal from "./Modal";
import ProductDetails from "./ProductDetails";
import { getSectionByCategory } from "../API/api";
import { useTranslation } from "react-i18next";

const VerticalItem = ({ product, section, sectionList, country }) => {
  const [open, setOpen] = useState(false);
  const [sectionFound, setSectionFound] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!section) {
      getSectionByCategory({ categoryId: product?.categoryId })
        .then((res) => {
          setSectionFound(res.data);
        })
        .catch((err) => {
          consoleLog(err);
        });
    }
  }, [section, product]);

  return (
    <div>
      {product && (
        <div onClick={() => setOpen(true)} id="verticalItem">
          <div className="card">
            <div className="caroussel">
              <CarousselImage
                showOne={true}
                forList={false}
                images={product?.images}
              />
            </div>
            <div className="content">
              <Typography className={"name"} component="div" variant="h5">
                {product?.label}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                {formaterPrix(product?.price)} {findCountryDevise(country)}
              </Typography>

              <Typography
                variant="subtitle2"
                color="text.secondary"
                component="div"
                className="ingredients"
              >
                <span className="title">{t("ingredient_label")} : </span>
                {product.ingredients.map((ingredient, k) => {
                  return (
                    <li key={k}>
                      {" "}
                      {ingredient}
                      {k + 1 >= product.ingredients?.length ? "" : ","}
                    </li>
                  );
                })}
              </Typography>
            </div>
          </div>
        </div>
      )}
      <Modal
        fullScreen={true}
        title={t("details")}
        open={open}
        setOpen={setOpen}
        component={
          <ProductDetails
            sectionList={sectionList}
            section={section || sectionFound?.label}
            product={product}
          />
        }
      />
    </div>
  );
};

export default VerticalItem;
