import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Modal from "./Modal";
import CategoryPage from "./CategoryPage";
import { Collapse, Divider } from "@mui/material";
import HorizontalProductList from "./HorizontalProductList";
import { consoleLog } from "../utils/utils";

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },

  // marginTop: "1rem",
  // marginBottom: "1rem",
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.7rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  padding: 0,
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "0 solid rgba(0, 0, 0, .125)",
  textAlign: "left",
  paddingLeft: "1rem",
}));

export const Section = ({
  keyProps,
  className = "",
  section,
  sectionList,
  menu,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [openListCateg, setOpenListCateg] = React.useState(false);
  const [selectedCateg, setSelectedCateg] = React.useState();

  React.useEffect(() => {
    if (keyProps === 0) {
      setExpanded(true);
    }
  }, [keyProps]);


  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Accordion expanded={expanded} onChange={() => handleChange()}>
        <AccordionSummary
          className={className}
          sx={{ marginBottom: "3px" }}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <div> {section?.label} </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {section.categories?.map((categ, key_c) => {
              return (
                <Collapse in={categ.products.length > 0} className="category" key={key_c}>
                  <Divider color="black" />

                  <div className="blocTitre">
                    <h4 className="categoryTitle"> {categ.label} </h4>
                    <div
                      onClick={() => {
                        setSelectedCateg(categ);
                        setOpenListCateg(true);
                      }}
                      className="seeMore"
                    >
                      tout afficher
                    </div>
                  </div>

                  <HorizontalProductList
                    sectionList={sectionList}
                    section={section?.label}
                    products={categ.products}
                    country={menu?.restaurant?.country}
                  />
                </Collapse>
              );
            })}
            <Modal
              title={selectedCateg?.label}
              open={openListCateg}
              setOpen={setOpenListCateg}
              component={
                <CategoryPage
                  sectionList={sectionList}
                  section={section.label}
                  category={selectedCateg}
                />
              }
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
