import { Avatar, Badge, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { imagePath } from "../utils/utils";
import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ListOfChoice from "./ListOfChoice";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

const theme = createTheme({
  palette: {
    white: {
      main: "#FFFFFF",
      contrastText: "#000000",
    },
  },
});

const Choice = ({ white = false, country }) => {
  const { choices } = useSelector((state) => state.choices);
  const [allChoices, setAllChoices] = useState([]);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setAllChoices(choices);
  }, [choices]);

  return (
    <ThemeProvider theme={theme}>
      <IconButton onClick={() => setOpen(!open)} sx={{ p: 0 }}>
        <Badge
          color={white ? "white" : "warning"}
          sx={{ color: "black" }}
          badgeContent={allChoices?.length}
          max={10}
        >
          <Avatar
            color="orange"
            sx={{ width: 27, height: 27 }}
            alt="Vially Dag"
            src={`${imagePath}/liste.png`}
          />
        </Badge>
      </IconButton>
      <Modal
        title={t("cart_modal_title")}
        open={open}
        setOpen={setOpen}
        component={<ListOfChoice country={country} />}
      />
    </ThemeProvider>
  );
};

export default Choice;
