import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import "../styles/SearchBar.scss";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const SearchBar = ({ setProducts, configMode = false }) => {
  const [keyWord, setKeyWord] = useState("");
  const { products } = useSelector((state) => state.products);
  const mobileTabletteScreen = useMediaQuery("(max-width:898px)");
  const {t} = useTranslation();

  return (
    <Paper
      className={configMode ? "configSearch" : "search"}
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
       
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={t("search_label")}
        inputProps={{ "aria-label": "Rechercher un plat" }}
        value={keyWord}
        onChange={(e) => {
          e.preventDefault();
          const value = e.target.value;
          setKeyWord(e.target.value);

          const productsToShow = products?.filter((p) => {
            return p?.label.toLowerCase().includes(value.toLowerCase());
          });
          setProducts(value !== "" ? productsToShow || [] : []);
        }}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
