import jwtDecode from "jwt-decode";
import axios from "axios";
import { baseUrl } from "../API/api";
import { addItem, getItem, removeItem } from "./localStorage";

export const tokenName = "orestoJwtWebToken";

export function hasAuthentication() {
  let isValid = false;

  try {
    var token = getItem(tokenName);
    token && (isValid = tokenIsValid(token));
  } catch (error) {
    console.log("error token : ", error);
  }

  if (token && isValid === false) {
    logout();
  }

  return isValid;
}

export function getConnectedUser() {
  const token = getItem(tokenName);
  const { value } = jwtDecode(token);
  return value;
}

export const login = async ({ email, password }) => {
  const response = await axios.post(`${baseUrl}/users/login`, {
    email,
    password,
  });
  const token = response.data;
  addItem(tokenName, token);
  const { status, value } = jwtDecode(token);
  return { status, value };
};

export function logout() {
  removeItem(tokenName);
}

function tokenIsValid(token) {
  const { exp } = jwtDecode(token);
  return exp * 1000 > new Date().getTime();
}
