import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import "../styles/ColorPicker.scss";
import { Avatar, Collapse } from "@mui/material";

export default function ColorPicker({
  selectedColor = "red",
  setSelectedColor,
  isOpen,
  setIsOpen,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div className="colorPicker">
      <div className="container">
        <div
          className="color"
          onClick={() => {
            setIsOpen(!open);
            setOpen(!open);
          }}
        >
          {" "}
          <Avatar
            sx={{
              backgroundColor: selectedColor,
              border: "1px solid gray",
              color: selectedColor,
              boxShadow:
                selectedColor === "white" && "5px 6px 7px rgba(0, 0, 0, 0.5);",
            }}
            src={
              "https://www.iconbunny.com/icons/media/catalog/product/3/8/3847.13-colorize-icon-iconbunny.jpg"
            }
          />
        </div>
        <Collapse in={open}>
          <div className="picker">
            <SketchPicker
              color={selectedColor}
              disableAlpha
              onChangeComplete={(e) => {
                const value = e.hex;
                setSelectedColor && setSelectedColor(value);
              }}
              onChange={(e) => {
                const value = e.hex;
                setSelectedColor && setSelectedColor(value);
              }}
              onSwatchHover={(e) => {
                const value = e.hex;
                setSelectedColor && setSelectedColor(value);
              }}
            />
          </div>
        </Collapse>
      </div>
    </div>
  );
}
