import React, { useEffect, useState } from "react";
import "../styles/ResetPasswordPage.scss";
import {
  PASSWORD_TEMPLATE,
  consoleLog,
  convertArrayToNumber,
  generateCode,
  goodFormatEmail,
  imagePath,
} from "../utils/utils";
import { Button, Collapse, TextField } from "@mui/material";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import FadeComponent from "./FadeComponent";
import { getUserByEmail, sendMail } from "../API/api";
import { TemporaryAlert } from "./PagesUpdate";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const ResetPasswordPage = ({}) => {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState();
  const [emailSend, setEmailSend] = useState(false);
  const [codeGenerated, setCodeGenerated] = useState();
  const [compteur, setCompteur] = useState(60);
  const [containsEmptyValue, setContainsEmptyValue] = useState(false);
  const [exists, setExists] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [goodEmailFormat, setGoodEmailFormat] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  const [values, setValues] = useState(["", "", "", ""]);
  const handleInputChange = (index, event) => {
    const newValue = event.target.value;

    // Vérifier si la nouvelle valeur est un chiffre
    if (/^\d*$/.test(newValue)) {
      // Mettre à jour l'état avec la nouvelle valeur
      setValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = newValue;

        return newValues;
      });

      // Déplacer automatiquement le focus vers le champ d'entrée suivant
      if (index < 3 && newValue !== "") {
        document.getElementById(`input-${index + 1}`).focus();
      }
    }
  };

  const sameCode = () => {
    const valid = false;

    return convertArrayToNumber(values) === codeGenerated;
  };

  const handleSendEmail = () => {
    setLoading(true);
    setValues(["", "", "", ""]);

    const code = generateCode();
    setCodeGenerated(code);
    setCompteur(60);
    setEmailSend(true);

    sendMail({
      to: email,
      templateId: PASSWORD_TEMPLATE,
      data: { email: email, code: code },
    })
      .then((res) => {
        setCompteur(60);
        setEmailSend(true);
        setLoading(false);
      })
      .catch((err) => {
        consoleLog(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (emailSend) {
      const interval = setInterval(() => {
        if (compteur >= 1) {
          setCompteur(compteur - 1);
        } else {
          clearInterval(interval);
          // setEmailSend(false);
          setCodeGenerated(undefined);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [compteur, emailSend]);

  useEffect(() => {
    setExists(emailExist(email));
  }, [email]);

  function checkValuesNotEmpty(values) {
    // Utilise la méthode every() pour vérifier si tous les éléments ne sont pas vides
    setContainsEmptyValue(values.every((value) => value.trim() !== ""));
    return values.every((value) => value.trim() !== "");
  }

  const handleDelete = (index, event) => {
    // Vérifier si la touche pressée est la touche de suppression (Backspace)
    if (event.key === "Backspace" && index > 0) {
      // Supprimer la valeur dans le champ précédent
      setValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = "";
        setContainsEmptyValue(checkValuesNotEmpty(newValues));
        return newValues;
      });

      // Déplacer automatiquement le focus vers le champ d'entrée précédent
      document.getElementById(`input-${index}`).focus();
    }
  };

  const handleConfirmCode = () => {
    setLoading(true);
    if (sameCode()) {
      setLoading(false);
      navigate("reset", { state: { id: id } });
    } else {
      setErrorMessage(compteur > 0 ? "Incorrect code !" : "Code expired !");
      setError(true);
      setLoading(false);
    }
  };

  const emailExist = (email) => {
    if (goodFormatEmail(email)) {
      setLoading(true);
      getUserByEmail({ email })
        .then((res) => {
          setLoading(false);
          setSuccess(true);
          setExists(res?.data?.email);
          setId(res?.data?._id);
          return true;
        })
        .catch((err) => {
          setLoading(false);
          setExists(false);
          setError(true);
          return false;
        });
    }
  };
  return (
    <FadeComponent>
      <div className="reset">
        <div className="content">
          <div>
            {!emailSend && (
              <div className="sendEmail">
                <div className="image">
                  <img src={`${imagePath}/cadenard.png`} alt="cadenard" />
                </div>
                <div className="title">{t("loginpage_password_forgot")} ?</div>
                <div className="instruction">
                  {t("reset_password_forgot_instruction")}
                </div>
                <TextField
                  className="emailField"
                  variant="outlined"
                  label={t("email_address_label")}
                  color="warning"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    // emailExist(e.target.value);
                    setGoodEmailFormat(goodFormatEmail(e.target.value));
                  }}
                />
                {!loading ? (
                  <Button
                    disabled={!exists}
                    onClick={() => {
                      handleSendEmail();
                    }}
                    color="warning"
                  >
                    {t("reset_password_send_code")}
                  </Button>
                ) : (
                  <LoaderThreeDot />
                )}

                <TemporaryAlert
                  severity="error"
                  open={error && !success}
                  setOpen={setError}
                  text={"This email does not exist !"}
                />
                <TemporaryAlert
                  severity="success"
                  open={success && !error}
                  setOpen={setSuccess}
                  text={"E-mail found !"}
                />
              </div>
            )}
            {emailSend && (
              <FadeComponent>
                <div className="enterCode">
                  <div className="image">
                    <img
                      style={{ width: "20%" }}
                      src={`${imagePath}/password-deblock.gif`}
                      alt="cadenard"
                    />
                  </div>
                  <div className="title"> {t("confirm_code_title")} </div>
                  <div className="instruction">
                    {t("confirm_code_instruction")} {email}
                  </div>
                  <div className="inputs">
                    {values.map((value, index) => (
                      <TextField
                        key={index}
                        id={`input-${index}`}
                        className="digit"
                        variant="standard"
                        color="warning"
                        inputProps={{ maxLength: 1 }}
                        value={value}
                        onChange={(event) => {
                          handleInputChange(index, event);
                          checkValuesNotEmpty(values);
                        }}
                        onKeyDown={(event) => {
                          handleDelete(index, event);
                          checkValuesNotEmpty(values);
                        }}
                      />
                    ))}
                  </div>

                  <Collapse in={compteur > 1}>
                    <div className="compteur">
                      {t("confirm_code_compteur_label")} {compteur} s{" "}
                    </div>
                  </Collapse>

                  <Collapse in={compteur <= 1}>
                    <div onClick={handleSendEmail} className="codeResend">
                      {" "}
                      {t("confirm_code_resend")}
                    </div>
                  </Collapse>
                  {!loading ? (
                    <Button
                      onClick={handleConfirmCode}
                      disabled={containsEmptyValue}
                      color="warning"
                    >
                      {t("confirm_code_validate")}
                    </Button>
                  ) : (
                    <LoaderThreeDot />
                  )}

                  <TemporaryAlert
                    open={error}
                    setOpen={setError}
                    text={errorMessage}
                    severity="error"
                  />
                </div>
              </FadeComponent>
            )}
          </div>
        </div>
      </div>
    </FadeComponent>
  );
};

export default ResetPasswordPage;
