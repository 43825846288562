import {createSlice} from '@reduxjs/toolkit';

export const  allergenSlice = createSlice({
    name : 'allergenSlice',
    initialState : {
        allergens : null,

    },
    reducers : {
        setAllergens : (state, action) => {
            const allergens = action.payload;
            state.allergens = allergens;
        }
    }
})

export const {setAllergens} = allergenSlice.actions;