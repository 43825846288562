import React from 'react';
import '../styles/Avantage.scss';
import { Box } from '@mui/material';
import { withTranslation } from 'react-i18next';

const Avantage = ({avantage, t}) => {
    return (
        <Box  className='avantage'>
            <div className="icon">
                <img src={avantage?.icon} alt="icon" />
            </div>
            <div className="label"> {t(avantage?.title)} </div>
            <div className="text"> {t(avantage?.description)} </div>
        </Box>
    );
};

export default withTranslation() (Avantage);