import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import "../styles/NavBarManager.scss";
import { greenOlive, imagePath } from "../utils/utils";
import { NavLink, useNavigate } from "react-router-dom";
import { hasAuthentication, tokenName } from "../services/Auth";
import { Button, Drawer, useMediaQuery } from "@mui/material";
import { Close, Logout } from "@mui/icons-material";
import AlertMessage from "./AlertMessage";
import { removeItem } from "../services/localStorage";
import { useDispatch } from "react-redux";
import { setMenu, setRestaurant } from "../redux/slice/restaurant.slice";
import { LanguageSelect } from "./LanguageSelect";
import { useTranslation } from "react-i18next";

const pages = ["emenu_label" /*, "Réservations"*/, "pricing_label"];
const links = ["menu" /*, "reservations"*/, "pricing"];

const NavBarManager = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [connected, setConnected] = useState(false);
  const [open, setOpen] = useState(false);
  const mobileTabletteScreen = useMediaQuery("(max-width:898px)");
  const [toOpen, setToOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setConnected(hasAuthentication());
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      className="appBar"
      color="transparent"
      position="fixed"
      sx={{ backgroundColor: "white" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <img
              onClick={() => navigate("/")}
              alt="logo"
              className="logo"
              src={`${imagePath}/orestoLogo.png`}
            />
          </Box>

          {/* render for phone & tablet */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <div onClick={() => setOpen(true)}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </div>

            <Drawer
              PaperProps={{
                style: { width: mobileTabletteScreen ? "100%" : "50%" },
              }}
              anchor="left"
              open={open}
              onClose={() => setOpen(false)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <Box
                  className="blocLogo"
                  sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
                >
                  <img
                    alt="logo"
                    style={{ width: "9rem", height: "auto" }}
                    src={`${imagePath}/orestoLogo.png`}
                  />
                </Box>
                <div onClick={() => setOpen(false)} className="close">
                  <Close />
                </div>
              </div>
              {pages.map((page, key) => (
                <MenuItem
                  key={key}
                  onClick={() => {
                    handleCloseNavMenu();
                    setOpen(false);
                  }}
                >
                  <NavLink
                    style={({ isActive }) => {
                      return {
                        color: isActive ? greenOlive : "darkgray",
                        textDecoration: "none",
                      };
                    }}
                    to={links[key]}
                  >
                    <Typography
                      textTransform={"uppercase"}
                      padding={1}
                      borderRadius={1}
                      fontSize={22}
                      fontWeight={"600"}
                      textAlign="center"
                    >
                      {t(page)}
                    </Typography>
                  </NavLink>
                </MenuItem>
              ))}
             
              {connected && (
                <div
                  style={{
                    display: "flex",
                    bottom: "1rem",
                    position: "absolute",
                    alignItems: "center",
                    padding: "1rem",
                  }}
                  className="logout"
                  onClick={() => setToOpen(true)}
                >
                  <Button color="warning">
                    <div>
                      <Logout />
                    </div>
                    <div>
                      <Typography
                        textTransform={"uppercase"}
                        padding={3}
                        borderRadius={1}
                        fontSize={22}
                        fontWeight={"400"}
                      >
                        {t("navbar_logout_btn")}
                      </Typography>
                    </div>
                  </Button>
                </div>
              )}
            </Drawer>
          </Box>

          {/* Déconnexion */}
          <AlertMessage
            toOpen={toOpen}
            title={"Se déconnecter ?"}
            text={"Retourner à la page de connexion ?"}
            acceptText={"Déconnexion"}
            declineText={"Annuler"}
            acceptAction={() => {
              removeItem(tokenName);
              dispatch(setMenu(undefined));
              dispatch(setRestaurant(undefined));
              !hasAuthentication() && navigate("/");
              setOpen(false);
            }}
            setToOpen={setToOpen}
          />

          <Box
            className="blocLogo"
            sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
          >
            <img
              onClick={() => navigate("/")}
              alt="logo"
              className="logo"
              src={`${imagePath}/orestoLogo.png`}
            />
          </Box>

          {/* render for web */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, key) => (
              <NavLink
              className={"element"}
                key={key}
                style={({ isActive }) => {
                  return {
                    color: isActive ? greenOlive : "darkgray",
                    textDecoration: "none",
                  };
                }}
                to={links[key]}
              >
                <Typography
                  textTransform={"uppercase"}
                  padding={1}
                  borderRadius={1}
                  fontSize={19}
                  fontWeight={"600"}
                >
                  {t(page)}
                </Typography>
              </NavLink>
            ))}
          </Box>

          {connected && (
            <NavLink style={{ textDecoration: "none" }} to={"/manage/menu"}>
              <div className="connect">dashbord</div>
            </NavLink>
          )}

          {!connected && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              {
                <div className="lang">
                  <LanguageSelect />
                </div>
              }
              <NavLink style={{ textDecoration: "none" }} to={"menu/login"}>
                <div className="connexionBtn">{t("navbar_login_btn")}</div>
              </NavLink>
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBarManager;
