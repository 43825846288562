import React from 'react';
import { Fade } from "@mui/material";

const FadeComponent = ({transitionDelay = 500, children}) => {
    return (
        <Fade unmountOnExit style={{ transitionDelay: `${transitionDelay}ms` }} in={true}>
            {children}
        </Fade>
    );
};

export default FadeComponent;