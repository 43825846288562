import React, { useCallback, useEffect, useState } from "react";
import FadeComponent from "./FadeComponent";
import "../styles/Shop.scss";
import { Backdrop, Button, Fade, Modal, Typography } from "@mui/material";
import {
  consoleLog,
  countriesWithDevise,
  euro,
  imagePath,
  paymentLink,
} from "../utils/utils";
import CarousselImage from "./CarousselImage";
import { Col, Row } from "react-bootstrap";
// import { useSelector } from "react-redux";
import { getConnectedUser, hasAuthentication } from "../services/Auth";
import { useNavigate } from "react-router";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import myGa from "../Google-Analytics/myGa";
import { convertCurrency } from "../API/api";
// import TeamCreator from "./TeamCreator";

const articles = [
  {
    id: 1,
    name: "QR-CODE display fashion",
    images: [
      imagePath + "/modele3_1.jpg",
      imagePath + "/modele3_2.jpg",
      imagePath + "/modele3_3.jpg",
      imagePath + "/modele3_4.jpg",
    ],
    price: 14.99,
    oldPrice: 19.99,
    description: "QR-CODE display fashion, format A6.",
    idLink:
      process.env.NODE_ENV !== "production"
        ? "test_dR600yfax7V4f6weV6"
        : "14k7tc5WXaBDfjW9AL",
    mesure: "Format A6 (10.5 x 14.8 cm)",
    unite: "shop_page_piece_label",
    active: true,
  },
  {
    id: 2,
    name: "QR-CODE display multi",
    images: [
      imagePath + "/modele4_1.jpg",
      imagePath + "/modele4_2.jpg",
      imagePath + "/modele4_3.jpg",
    ],
    price: 19.99,
    oldPrice: 29.99,
    description: "QR-CODE display multi, format A6.",
    idLink:
      process.env.NODE_ENV !== "production"
        ? "test_8wM00yd2pejs2jKaER"
        : "fZe28S855h016Nq00e",
    mesure: "Format A6 (10.5 x 14.8 cm)",
    unite: "shop_page_piece_label",
    active: true,
  },
  {
    id: 3,
    name: "QR-CODE display basic",
    images: [
      imagePath + "/modele1_1.jpg",
      imagePath + "/modele1_2.jpg",
      imagePath + "/modele1_3.jpg",
      imagePath + "/modele1_4.jpg",
    ],
    price: 17.95,
    oldPrice: 25.39,
    description: "QR-CODE display basic, format 120x80 mm.",
    idLink:
      process.env.NODE_ENV !== "production"
        ? "test_4gwfZw0fD3EOgaA5ky"
        : "3cs9BkgBB8tvgo0bIV",
    mesure: "Format (120 x 80 mm)",
    unite: "shop_page_piece_label",
    active: true,
  },
  {
    id: 4,
    name: "QR-CODE display luxe",
    images: [
      imagePath + "/modele2_1.jpg",
      imagePath + "/modele2_2.jpg",
      imagePath + "/modele2_3.jpg",
      imagePath + "/modele2_4.jpg",
    ],
    price: 19.99,
    description: "QR-CODE display luxe, format A6.",
    idLink:
      process.env.NODE_ENV !== "production"
        ? "test_9AQcNk9Qd4IS2jKaET"
        : "6oE6p81GHcJL1t63co",
    mesure: "Format A6 (10.5 x 14.8 cm)",
    unite: "shop_page_piece_label",
    active: true,
  },

  {
    id: 5,
    name: "Set of 50 QR-Code Labels",
    images: [imagePath + "/code_eti_1.jpg"],
    price: 49.99,
    description:
      "Lot de 10 étiquettes adhésives rondes pour personnaliser vos QR-Codes.",
    idLink: "test_dR600yfax4IS9McdR0",
    unite: "shop_page_lot_label",
    active: false,
  },
];

const Article = ({ article, country = "Belgium", email }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const [convertedCurrencies, setConvertedCurrencies] = useState([]);
  const [convertedOldCurrencies, setConvertedOldCurrencies] = useState([]);
  const [countryInfo, setCountry] = useState(countriesWithDevise[19]);
  const handleConvertDevise = useCallback(async () => {
    const amounts = articles.map((a) => a.price);
    const oldPrices = articles
      .filter((a) => a.oldPrice !== undefined)
      .map((a) => a.oldPrice);

    try {
      const convertPice = await convertCurrency({
        amounts,
        devise: countryInfo?.currency,
      });

      if (oldPrices.length > 0) {
        const convertOldPrice = await convertCurrency({
          amounts: oldPrices,
          devise: countryInfo?.currency,
        });


        return {
          convertPice: convertPice?.data,
          convertOldPrice: convertOldPrice?.data,
        };
      }
    } catch (error) {
      consoleLog("error convert currency: ", error);
      return {
        convertPice: [],
        convertOldPrice: [],
      };
    }
  }, [countryInfo]);

  useEffect(() => {
    const userData = getConnectedUser();
    convertedCurrencies.length <= 0 &&
      handleConvertDevise().then((res) => {
        setConvertedCurrencies(res.convertPice);
        setConvertedOldCurrencies(res.convertOldPrice);
      });
    setCountry(countriesWithDevise.find((c) => c?.label === userData?.country));
  }, [handleConvertDevise, convertedCurrencies.length]);

  return (
    <div className="article">
      <div onClick={() => setOpen(!open)} className="images">
        <CarousselImage images={article.images} />
      </div>
      <div className="content">
        <div className="name">{article.name}</div>
        {/* <div className="description">{article.description}</div> */}
        <div className="price">
          <span>
            {convertedCurrencies.length > 0
              ? convertedCurrencies
                  .find((c) => c.amount === article.price.toString())
                  ?.result[countryInfo?.currency]?.toFixed(2)
              : article.price}{" "}
            {convertedCurrencies.length > 0 ? countryInfo?.symbole : euro}
          </span>
          <span style={{ fontSize: "small", fontWeight: "100" }}>
            {" "}
            / {t(article.unite)}
          </span>
          {article?.oldPrice && (
            <Typography
              sx={{ textDecoration: "line-through" }}
              color={"red"}
              fontSize={"small"}
            >
              <span>
                {convertedOldCurrencies.length > 0
                  ? convertedOldCurrencies
                      .find((c) => c.amount === article?.oldPrice?.toString())
                      ?.result[countryInfo?.currency]?.toFixed(2)
                  : article.oldPrice}{" "}
                {convertedOldCurrencies.length > 0
                  ? countryInfo?.symbole
                  : euro}
              </span>
              {
                <span style={{ fontSize: "small", fontWeight: "100" }}>
                  / {t(article.unite)}
                </span>
              }
            </Typography>
          )}
          <Typography color={"GrayText"} fontSize={"small"}>
            {article.mesure}
          </Typography>
        </div>
        <Button
          className="button"
          sx={{ marginTop: "1rem" }}
          color="warning"
          variant="contained"
          disabled={!article.active}
        >
          <a
            style={{ textDecoration: "none", color: "white" }}
            href={`${paymentLink({ key: article?.idLink, email: email })}`}
          >
            {t("shop_page_button_buy")}
          </a>
        </Button>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div style={style} className="modalContent">
            <Close
              onClick={() => setOpen(false)}
              sx={{
                backgroundColor: "whitesmoke",
                color: "gray",
                borderRadius: "2rem",
                fontSize: "1.7rem",
                textAlign: "left",
                position: "absolute",
                right: 0,
                top: "-4rem",
                margin: "1rem",
                cursor: "pointer",
              }}
            />
            <div
              style={{
                padding: "1rem",
                borderRadius: "1rem",
              }}
            >
              <CarousselImage
                indicators
                fade={false}
                controls
                images={article.images}
                forDetail
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  // bgcolor: "background.paper",

  p: "1rem",
};
const Shop = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    myGa();
    if (!hasAuthentication()) {
      navigate("/manager/menu/login");
      return;
    }

    const userData = getConnectedUser();
    setUser(userData);
  }, []);

  return (
    <FadeComponent>
      <div className="shop">
        <div className="presentation">
          <div className="title">{t("shop_page_title")}</div>
          <div className="subTitle">{t("shop_page_subTitle")}</div>
        </div>

        <hr />

        <div className="title"> {t("shop_page_product_title")} </div>

        <Row className="articles">
          {articles.map((article) => (
            <Col xs={6} md={4} key={article.id}>
              <Article email={user?.email} article={article} />
            </Col>
          ))}
        </Row>
      </div>
    </FadeComponent>
    // <TeamCreator />
  );
};

export default Shop;
