import { DoneAllRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "../styles/SuccessPage.scss";
import { useNavigate } from "react-router";
import { Collapse } from "@mui/material";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import FadeComponent from "./FadeComponent";
import { useTranslation } from "react-i18next";
import myGa from "../Google-Analytics/myGa";

const SuccessPage = ({ message }) => {
  const navigate = useNavigate();
  const [compteur, setCompteur] = useState(5);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    myGa();
    const interval = setInterval(() => {
      if (compteur > 1) {
        setCompteur(compteur - 1);
      } else {
        clearInterval(interval);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          navigate("/manager/menu/login");
        }, 3000);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [compteur, navigate]);

  return (
    <FadeComponent>
      <div className="successPage">
        <div className="content">
          {loading ? (
            <LoaderThreeDot />
          ) : (
            <div>
              <div className="icon">
                <DoneAllRounded color="success" sx={{ fontSize: "5rem" }} />
              </div>

              <div className="message">{message}</div>

              <Collapse in={compteur > 0}>
                {compteur > 0 && (
                  <div className="redirect">
                    <span> {t("success_page_redirect")} </span>
                    <span className="compteur"> {compteur} </span>
                  </div>
                )}
              </Collapse>

              <div
                onClick={() => navigate("/manager/menu/login")}
                className="button"
              >
                {t("success_page_back_login")}
              </div>
            </div>
          )}
        </div>
      </div>
    </FadeComponent>
  );
};

export default SuccessPage;
