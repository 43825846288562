import {
  Close,
  Delete,
  DeleteForever,
  Lightbulb,
  Send,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "../styles/PageUpdate.scss";
import {
  Alert,
  Avatar,
  Button,
  Chip,
  Collapse,
  Divider,
  Fade,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  ToggleButton,
} from "@mui/material";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import { TransitionGroup } from "react-transition-group";
import { Carousel, CarouselItem } from "react-bootstrap";
import {
  consoleLog,
  extractFileNameFromUrl,
  findCountryDevise,
} from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  addProduct,
  createCategory,
  createSection,
  deleteCategory,
  deleteImage,
  deleteImages,
  deleteMenu,
  deleteProduct,
  deleteSection,
  getMenu,
  updateCategory,
  updateMenu,
  updateProduct,
  updateSection,
  uploadImages,
} from "../API/api";
import SelectWithCheckBoxes from "./SelectWithCheckBoxes";
import FadeComponent from "./FadeComponent";
import InputFile from "./InputFile";
import { setMenu } from "../redux/slice/restaurant.slice";
import { useTranslation } from "react-i18next";

export const MenuLabelUpdate = ({
  setOpen,
  oldLabel,
  restaurantId,
  isActive,
  id,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [label, setLabel] = useState(oldLabel);
  const [error, setError] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);
  const [errorMenu, setErrorMenu] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);

  const handleUpdateMenu = () => {
    setLoading(true);

    updateMenu({ label, oldLabel, restaurantId, isActive })
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setLabel("");

        getMenu(restaurantId)
          .then((res) => {
            dispatch(setMenu(res.data));
            setOpen(false);
          })
          .catch((e) => {
            consoleLog("error get menu (page update) : ", e.response);
            setErrorMenu(true);
          });
      })
      .catch((e) => {
        consoleLog("error update menu : ", e.response);
        setLoading(false);
        setError(true);
      });
  };

  const handleDeleteMenu = () => {
    setLoadingDelete(true);

    deleteMenu({ id })
      .then((res) => {
        dispatch(setMenu(null));
        setLoadingDelete(false);
        setOpen(false);
      })
      .catch((e) => {
        setLoadingDelete(false);
        setErrorDelete(true);
        consoleLog("error delete menu : ", e.response);
      });
  };

  return (
    <div className="labelMenuUpdate">
      <div className="content">
        <div className="header">
          <div className="title"> {t("menu_update_title")} </div>
          <div onClick={() => setOpen(false)} className="closeBtn">
            <Close />
          </div>
        </div>

        <TextField
          label={t("menu_update_input_label")}
          color="warning"
          variant="standard"
          sx={{ width: "100%", marginBottom: "2rem" }}
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            disabled={label === "" || label === oldLabel}
            onClick={handleUpdateMenu}
            color="warning"
          >
            {loading ? <LoaderThreeDot /> : t("update_label")}
          </Button>
          <Button
            startIcon={!loadingDelete && <DeleteForever />}
            onClick={handleDeleteMenu}
            color="error"
          >
            {loadingDelete ? (
              <LoaderThreeDot />
            ) : (
              t("menu_update_delete_button")
            )}
          </Button>
        </div>

        <div>
          <TemporaryAlert
            open={error}
            setOpen={setError}
            severity="error"
            text={"An error has occurred !"}
          />

          <TemporaryAlert
            open={errorDelete}
            setOpen={setErrorDelete}
            severity="error"
            text={"Deletion failed !"}
          />

          <TemporaryAlert
            open={success}
            setOpen={setSuccess}
            severity="success"
            text={"Successul update !"}
          />

          <TemporaryAlert
            open={errorMenu}
            setOpen={setErrorMenu}
            severity="error"
            text={"Check your internet connection and reload the page !"}
          />
        </div>
      </div>
    </div>
  );
};

export const SectionAdd = ({ setOpen }) => {
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { restaurant, menu } = useSelector((state) => state.infosRestaurant);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleAddSection = () => {
    setLoading(true);

    createSection({ label, menuId: menu?._id })
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setLabel("");

        getMenu(restaurant?._id)
          .then((res) => {
            dispatch(setMenu(res.data));
          })
          .catch((e) => {
            consoleLog("error get menu (page update) : ", e.response);
          });
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        consoleLog("error add section : ", e.response);
      });
  };
  return (
    <div className="addSection">
      <div className="content">
        <div className="header">
          <div className="title"> {t("section_add_title")} </div>
          <div onClick={() => setOpen(false)} className="closeBtn">
            <Close />
          </div>
        </div>
        <div className="desc">
          <AlertInfo
            text={
              <div>
                {t("section_add_description_1")}{" "}
                <strong>"{t("enter_text")}" </strong>
                {t("section_add_description_2")}
              </div>
            }
          />
        </div>

        <TextField
          label={t("section_add_title")}
          color="warning"
          variant="standard"
          sx={{ width: "100%", marginBottom: "2rem" }}
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
        <Button
          disabled={label === ""}
          onClick={handleAddSection}
          color="warning"
        >
          {loading ? <LoaderThreeDot /> : t("add_button_label")}
        </Button>

        <div>
          <TemporaryAlert
            open={error}
            setOpen={setError}
            severity="error"
            text={"An error has occurred !"}
          />

          <TemporaryAlert
            open={success}
            setOpen={setSuccess}
            severity="success"
            text={"Section added successfully !"}
          />
        </div>
      </div>
    </div>
  );
};

export const TemporaryAlert = ({
  text,
  open,
  setOpen,
  severity = "success",
  delay = 4000,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);

    if (open) {
      setTimeout(() => {
        setOpen(false);
        setIsOpen(false);
      }, delay);
    }
  }, [open, delay]);

  return (
    <Collapse in={isOpen}>
      {
        <Alert sx={{ mt: 1, mb: 1 }} severity={severity}>
          {text}
        </Alert>
      }
    </Collapse>
  );
};

export const AlertInfo = ({ text }) => {
  return (
    <Alert sx={{ width: "100%" }} severity="warning" icon={<Lightbulb />}>
      {text}
    </Alert>
  );
};
function renderItem({ item, removeCateg, index }) {
  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          title="Delete"
          onClick={() => removeCateg(item)}
        >
          <Delete />
        </IconButton>
      }
    >
      <span
        style={{
          paddingRight: "1rem",
          fontSize: "large",
        }}
      >
        {index}.
      </span>{" "}
      <ListItemText
        sx={{ textTransform: "capitalize", fontSize: "large" }}
        primary={item}
      />
    </ListItem>
  );
}

//______________________ CATEGORY_______________________

export const CategoryUpdatePage = ({ setOpen, category }) => {
  const [label, setLabel] = useState("");
  const [error, setError] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);
  const [errorDeleteMsg, setErrorDeleteMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { restaurant } = useSelector((state) => state.infosRestaurant);
  const [title, setTitle] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setLabel(category?.label);
    setTitle(category?.label);
  }, [category]);

  const handleUpdate = () => {
    setLoading(true);

    updateCategory({
      label,
      sectionId: category?.sectionId,
      oldLabel: category?.label,
      id: category?._id,
    })
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        takeMenu(restaurant?._id);
        setTimeout(() => setOpen(false), 1000);
        setTitle(label);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setLabel(category?.label);
        consoleLog("error set categ : ", e);
        setErrorMsg(e.response.data.error);
      });
  };

  const handleDelete = () => {
    setLoadingDelete(true);

    deleteCategory({ id: category?._id })
      .then(() => {
        takeMenu(restaurant?._id);
        setOpen(false);
        setLoadingDelete(false);
      })
      .catch((e) => {
        setLoadingDelete(false);
        setErrorDelete(true);
        consoleLog("error delete categ : ", e);
        setErrorDeleteMsg(e.response.data.error);
      });
  };

  const takeMenu = (restaurantId) => {
    getMenu(restaurantId)
      .then((res) => {
        dispatch(setMenu(res.data));
      })
      .catch((e) => {
        consoleLog("error getMenu : ", e.response);
      });
  };

  return (
    <div className="categUpdate">
      <div className="content">
        <div className="header">
          <div className="title"> {title} </div>
          <div onClick={() => setOpen(false)} className="closeBtn">
            <Close />
          </div>
        </div>

        <TextField
          label={t("category_update_input_label")}
          color="warning"
          variant="standard"
          sx={{ width: "100%", marginBottom: "2rem" }}
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            disabled={label === "" || label === category?.label}
            onClick={handleUpdate}
            color="warning"
          >
            {loading ? <LoaderThreeDot /> : t("update_label")}
          </Button>
          <Button
            disabled={label !== category?.label}
            startIcon={!loadingDelete && <DeleteForever />}
            onClick={handleDelete}
            color="error"
          >
            {loadingDelete ? <LoaderThreeDot /> : t("delete_button_label")}
          </Button>
        </div>

        <TemporaryAlert
          open={error}
          setOpen={setError}
          severity="error"
          text={errorMsg || "An error has occurred !"}
        />

        <TemporaryAlert
          open={errorDelete}
          setOpen={setErrorDelete}
          severity="error"
          text={errorDeleteMsg || "Error deleting category !"}
        />

        <TemporaryAlert
          open={success}
          setOpen={setSuccess}
          severity="success"
          text={"Update successful!"}
        />
      </div>
    </div>
  );
};

// section update_____________________________

export const SectionUpdate = ({ setOpen, section }) => {
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [loadingCateg, setLoadingCateg] = useState(false);
  const [label, setLabel] = useState(section?.label);
  const [error, setError] = useState(false);
  const [errorSection, setErrorSection] = useState(false);
  const { t } = useTranslation();

  const [errorCateg, setErrorCateg] = useState(false);
  const [errorCategMsg, setErrorCategMsg] = useState(
    t("section_update_error_category")
  );

  const [success, setSuccess] = useState(false);
  const [successCateg, setSuccessCateg] = useState(false);
  const [category, setCategory] = useState("");
  const [categsToAdd, setCategsToAdd] = useState([]);

  const [title, setTitle] = useState(section.label);

  const { restaurant, menu } = useSelector((state) => state.infosRestaurant);
  const dispatch = useDispatch();

  const handleDeleteSection = () => {
    setLoadingDelete(true);
    deleteSection({ label, menuId: menu?._id, id: section?._id })
      .then((res) => {
        setLoadingDelete(false);

        takeMenu(restaurant?._id);

        setOpen(false);
      })
      .catch((e) => {
        setLoadingDelete(false);
        consoleLog("error delete sec : ", e.response);
        setErrorSection(true);
      });
  };

  const takeMenu = (restaurantId) => {
    getMenu(restaurantId)
      .then((res) => {
        dispatch(setMenu(res.data));
      })
      .catch((e) => {
        consoleLog("error getMenu : ", e.response);
      });
  };
  const handleUpdateSection = () => {
    setLoading(true);
    updateSection({
      label,
      menuId: menu?._id,
      oldLabel: section?.label,
      id: section?._id,
    })
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        takeMenu(restaurant?._id);
        setTitle(label);
      })
      .catch((e) => {
        setLoading(false);
        consoleLog("error update sec : ", e.response.data);
        setError(true);
      });
  };
  const handleAddCategory = () => {
    setLoadingCateg(true);
    processAddCategs()
      .then(() => {
        setLoadingCateg(false);
        setSuccessCateg(true);
        takeMenu(takeMenu(restaurant?._id));
      })
      .catch((e) => {
        setErrorCateg(true);
        setLoadingCateg(false);
        consoleLog("error add categ : ", e.response);
        setErrorCategMsg(e.response.data.error || "An error has occurred !");
      });
  };

  const processAddCategs = async () => {
    for (const c of categsToAdd) {
      const res = await createCategory({
        label: c,
        sectionId: section?._id,
      });

      removeCateg(c);
    }
  };

  const addCateg = () => {
    const categFound = categsToAdd.find((c) => c === category);
    if (!categFound) {
      setCategsToAdd([...categsToAdd, category]);
    }
  };

  const removeCateg = (item) => {
    setCategsToAdd((prev) => [...prev.filter((i) => i !== item)]);
  };

  return (
    <div className="sectionUpdate">
      <div className="content">
        <div className="header">
          <div className="title">Section "{title}" </div>
          <div onClick={() => setOpen(false)} className="closeBtn">
            <Close />
          </div>
        </div>

        <TextField
          label={t("section_add_input_label")}
          color="warning"
          variant="standard"
          sx={{ width: "100%", marginBottom: "2rem" }}
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
        <Button
          disabled={label === "" || label === section?.label}
          onClick={handleUpdateSection}
          color="warning"
        >
          {loading ? <LoaderThreeDot /> : t("button_update")}
        </Button>

        <div>
          <TemporaryAlert
            open={error}
            setOpen={setError}
            severity="error"
            text={"Error occurred while updating the section !"}
          />

          <TemporaryAlert
            open={success}
            setOpen={setSuccess}
            severity="success"
            text={"Section update successful!"}
          />
        </div>

        <div className="blocCategory">
          <div className="title"> {t("category_add_title")} </div>
          <div className="desc">
            <AlertInfo text={<div>{t("category_add_description")}</div>} />
          </div>

          <TextField
            label={t("category_label")}
            color="warning"
            variant="standard"
            sx={{ width: "100%", marginBottom: "2rem" }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />

          <Button
            disabled={category === ""}
            onClick={() => {
              addCateg();
              setCategory("");
            }}
            color="warning"
          >
            {t("add_button_label")}
          </Button>

          <div className="list">
            <List sx={{ mt: 1 }}>
              <TransitionGroup>
                {categsToAdd.map((item, k) => (
                  <Collapse key={item}>
                    {renderItem({ item, removeCateg, index: k + 1 })}
                    <Divider sx={{ color: "black" }} />
                  </Collapse>
                ))}
              </TransitionGroup>
            </List>
          </div>
          <Collapse in={categsToAdd?.length !== 0}>
            <Button
              sx={{ marginBottom: "1rem" }}
              disabled={categsToAdd?.length === 0}
              onClick={() => {
                handleAddCategory();
              }}
              color="warning"
            >
              {loadingCateg ? <LoaderThreeDot /> : t("category_add_save")}
            </Button>
          </Collapse>
          <Button
            startIcon={<DeleteForever />}
            onClick={() => {
              handleDeleteSection();
            }}
            color="error"
          >
            {loadingDelete ? <LoaderThreeDot /> : t("section_delete_button")}
          </Button>

          <TemporaryAlert
            open={errorCateg}
            setOpen={setErrorCateg}
            severity="error"
            text={errorCategMsg}
          />

          <TemporaryAlert
            open={errorSection}
            setOpen={setErrorSection}
            severity="error"
            text={"Error occurred while deleting the section !"}
          />

          <TemporaryAlert
            open={successCateg}
            setOpen={setSuccessCateg}
            severity="success"
            text={t("category_add_success")}
          />
        </div>
      </div>
    </div>
  );
};

//https://example.com/gluten-icon.png

function renderItemImage({ item, removeImage, index, label }) {
  return (
    <ListItem key={index}>
      <Chip
        onDelete={() => removeImage(item)}
        deleteIcon={<Close />}
        label={label}
        icon={!label && <Avatar sx={{ width: 24, height: 24 }} src={item} />}
      />
    </ListItem>
  );
}

export const ProductAdd = ({ setOpen, defaultProduct, categoryId }) => {
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [errorMsg, setErrorMsg] = useState("3 images max !");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorImage, setErrorImage] = useState(false);
  const [images, setImages] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [ingredient, setIngredient] = useState("");
  const [listAllergens, setListAllergens] = useState([]); //du back-end
  const [listNameAllergens, setListNameAllergens] = useState([]);
  const [listIdAllergens, setListIdAllergens] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const dispatch = useDispatch();
  const [loadingImage, setLoadingImage] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [errorDeleteImage, setErrorDeleteImage] = useState(false);
  const [errorUploadMsg, setErrorUploadMsg] = useState(
    "Error occurred while uploading the image!"
  );
  const [errorDeleteMsg, setErrorDeleteMsg] = useState(
    "Error occurred while deleting the image!"
  );
  const [fileDeleted, setFileDeleted] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const [name, setName] = useState("");
  const [price, setPrice] = useState();
  const { restaurant } = useSelector((state) => state.infosRestaurant);
  const { allergens } = useSelector((state) => state.allergens);
  const { t } = useTranslation();
  const [productActive, setProductActive] = useState(defaultProduct?.active);

  useEffect(() => {
    if (allergens) {
      setListAllergens(allergens);
      if (defaultProduct) {
        if (defaultProduct?.images?.length > productImages?.length)
          defaultProduct?.images?.forEach((image) => {
            setProductImages((prevImages) => [
              ...prevImages,
              { file: null, url: image },
            ]);
          });

        setIngredients(defaultProduct.ingredients);
        setName(defaultProduct?.label);
        setPrice(defaultProduct?.price);
        setProductActive(defaultProduct?.active);

        setListNameAllergens(
          extractAllergenNames(
            defaultProduct?.allergens?.map((a) => a?._id),
            allergens
          )
        );
        setListIdAllergens(defaultProduct?.allergens?.map((a) => a?._id));
      }
    } else {
      setErrorLoading(true);
    }
  }, [defaultProduct, allergens]);

  //new
  useEffect(() => {
    handleUploadImageBis(selectedFile);
  }, [selectedFile]);

  const takeMenu = (restaurantId) => {
    getMenu(restaurantId)
      .then((res) => {
        dispatch(setMenu(res.data));
      })
      .catch((e) => {
        consoleLog("error getMenu : ", e.response);
      });
  };

  //new
  const handleUploadImageBis = (file) => {
    if (file) {
      setLoadingImage(true);

      if (productImages.length >= 3) {
        setErrorImage(true);
        setErrorMsg("3 images max !");
      } else if (file.size > 5000000) {
        setErrorImage(true);
        setErrorMsg("Image size too big, max 5MB !");
      } else {
        const formData = new FormData();
        formData.append("image", file);
        const url = window.URL.createObjectURL(file);

        addImageBis({ url, file: file });
      }
      setLoadingImage(false);
    }
  };

  const handleDeleteImageBis = (url) => {
    if (url) {
      setLoadingImage(true);
      setProductImages(productImages.filter((img) => img?.url !== url));
      setLoadingImage(false);
    }
  };

  const handleDeleteProduct = () => {
    setLoadingDelete(true);

    if (defaultProduct) {
      deleteProduct({ product: defaultProduct })
        .then((res) => {
          setLoadingDelete(false);

          for (const url of images) {
            deleteImage({ fileName: extractFileNameFromUrl(url) });
          }

          takeMenu(restaurant?._id);
          setOpen(false);
        })
        .catch((e) => {
          consoleLog("error add product : ", e);
          setLoadingDelete(false);
          setError(true);
          setErrorMsg(e.response.data.error);
        });
    }
  };

  const handleUpdateProduct = async () => {
    setLoading(true);
    let images = undefined;

    let product;

    const newImages = productImages.filter((img) => img.file !== null);

    let totalsize = 0;

    for (const img of newImages) {
      totalsize += img.file.size;
    }

    if (totalsize > 5000000) {
      setErrorImage(true);
      setErrorMsg("Total image size too big, max 5MB !");
      return;
    }

    const imagesToDelete = defaultProduct?.images
      .filter((url) => !productImages.find((img) => img.url === url))
      .map((img) => img);

    try {
      if (imagesToDelete.length > 0) {
        const resultDelete = await deleteImages({
          fileNames: imagesToDelete.map((img) => extractFileNameFromUrl(img)),
        });
      }

      if (newImages?.length > 0) {
        const formData = new FormData();
        newImages.forEach((img) => {
          formData.append(`image`, img.file);
        });

        const resultUpload = await uploadImages({ formData });

        images = resultUpload.data.map((url) => url);
        images = [
          ...images,
          ...productImages
            .filter((img) => !img.file)
            .map((image) => image?.url),
        ];
      }

      if (imagesToDelete?.length > 0) {
        if (images) {
          images = images.filter((img) => !imagesToDelete.includes(img));
        } else {
          images = defaultProduct?.images?.filter(
            (img) => !imagesToDelete.includes(img)
          );
        }
      }

      product = {
        label: name,
        categoryId,
        price,
        ingredients,
        allergens: listIdAllergens,
        images,
        active: productActive,
      };

      await updateProduct({
        id: defaultProduct?._id,
        newProduct: product,
        oldLabel: defaultProduct?.label,
      });

      setLoading(false);
      setSuccess(true);
      takeMenu(restaurant?._id);
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    } catch (error) {
      consoleLog("Error", error);
      setLoading(false);
      setErrorMsg(error?.response?.data?.error || "Error updading product");
    }
  };

  const handleAddProduct = () => {
    if (defaultProduct) {
      handleUpdateProduct();
    } else {
      setLoading(true);

      const formData = new FormData();
      productImages.forEach((img) => {
        formData.append(`image`, img.file);
      });

      uploadImages({ formData })
        .then((res) => {
          consoleLog("res upload images", res);

          const product = {
            label: name,
            categoryId,
            price: parseFloat(price),
            ingredients,
            allergens: listIdAllergens,
            images: res.data?.length > 0 ? res.data.map((url) => url) : [],
            active: true,
          };

          addProduct(product)
            .then(() => {
              setLoading(false);
              setSuccess(true);
              setName("");
              setPrice("");
              setListNameAllergens([]);
              setIngredients([]);
              setProductImages([]);
              setListIdAllergens([]);
              setFileDeleted(true);
              setSelectedFile(undefined);
              takeMenu(restaurant?._id);
            })
            .catch((e) => {
              consoleLog("error add product : ", e);
              setError(true);
              setLoading(false);
              setErrorMsg(e.response.data.error);
            });
        })
        .catch((e) => {
          consoleLog("error upload images", e);
          setErrorUpload(true);
          setLoading(false);
        });
    }
  };

  function extractAllergenNames(allergenIds, allergenList) {
    return allergenIds.map((id) => {
      const allergen = allergenList.find((a) => a._id === id);
      return allergen ? allergen.name : null;
    });
  }

  const addImageBis = ({ url, file }) => {
    if (productImages?.length < 3) {
      if (file?.type?.startsWith("image")) {
        const imageFound = productImages.find(
          (i) => (i?.file?.name || extractFileNameFromUrl(url)) === file?.name
        );
        if (!imageFound) {
          setProductImages([...productImages, { file, url }]);
        }
      }
    }
  };

  const handleAddIngredient = () => {
    setIngredients([...ingredients, ingredient]);
  };

  const removeIngredient = (ingredient) => {
    setIngredients((prev) => [...prev.filter((i) => i !== ingredient)]);
  };

  return (
    <>
      <FadeComponent>
        <div className="addProduct">
          <div className="content">
            <div className="header">
              <div className="title">
                {defaultProduct ? t("update_label") : t("add_button_label")}{" "}
                {t("product_page_title")}{" "}
              </div>
              <div onClick={() => setOpen(false)} className="closeBtn">
                <Close />
              </div>
            </div>

            <TemporaryAlert
              severity="error"
              text={"Error loading product data. Please try again later."}
              open={errorLoading}
              setOpen={setErrorLoading}
            />

            <FormControlLabel
              className="switch"
              control={
                <Switch
                  
                  checked={productActive}
                  onChange={() => setProductActive(!productActive)}
                  color="warning"
                />
              }
              label={
                productActive
                  ? "The product is visible"
                  : "This product is hidden"
              }
            />

            <div style={{ marginBottom: "2rem" }}>
              <AlertInfo text={t("product_page_image_label")} />
            </div>

            <div className="content">
              <div className="blocImage">
                <div className="caroussel">
                  {productImages.length !== 0 && (
                    <Carousel
                      controls={productImages.length > 1}
                      indicators={productImages.length > 1}
                      fade
                    >
                      {productImages.map((i, k) => {
                        return (
                          <CarouselItem key={k}>
                            <img
                              loading="lazy"
                              src={i?.url}
                              alt={`caroussel ${k}`}
                            />
                          </CarouselItem>
                        );
                      })}
                    </Carousel>
                  )}
                  {productImages.length === 0 && (
                    <div>{t("product_page_image_box")}</div>
                  )}
                </div>
                <div className="listImage">
                  {loadingImage ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1rem",
                        marginTop: "1rem",
                      }}
                    >
                      <LoaderThreeDot />
                    </div>
                  ) : (
                    <div component="ul">
                      <TransitionGroup
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexWrap: "wrap",
                          listStyle: "none",
                          marginBottom: "2rem",
                          marginTop: "2rem",
                          width: "100%",
                        }}
                      >
                        {productImages.map((i, k) => {
                          return (
                            <Collapse key={k}>
                              {renderItemImage({
                                item: i?.url,
                                removeImage: handleDeleteImageBis,
                                index: k,
                              })}
                            </Collapse>
                          );
                        })}
                      </TransitionGroup>
                    </div>
                  )}
                  <TemporaryAlert
                    open={errorImage}
                    setOpen={setErrorImage}
                    text={errorMsg}
                    severity="error"
                  />

                  {/* différencier l'erreur reseau des autre erreurs  */}
                  <TemporaryAlert
                    open={errorUpload}
                    setOpen={setErrorUpload}
                    text={errorUploadMsg}
                    severity="error"
                  />
                  <TemporaryAlert
                    open={errorDeleteImage}
                    setOpen={setErrorDeleteImage}
                    text={errorDeleteMsg}
                    severity="error"
                  />
                </div>
                <div className="addImage">
                  <InputFile
                    setSelectedFile={setSelectedFile}
                    actionText={t("product_page_add_photo")}
                    fileDeleted={fileDeleted}
                    setFileDeleted={setFileDeleted}
                    showFileName={false}
                    //action={handleUploadImage}
                  />
                </div>
              </div>

              <div className="blocDetails">
                <div className="title"> {t("product_page_info_title")} </div>
                <div className="name">
                  <TextField
                    label={t("product_page_product_label")}
                    color="warning"
                    variant="standard"
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="price">
                  <TextField
                    label={t("price_label")}
                    color="warning"
                    variant="standard"
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    value={price}
                    placeholder="0.00"
                    onChange={(e) => {
                      let inputValue = e.target.value.replace(",", "."); // Remplace les virgules par des points
                      inputValue = inputValue.replace(/[^0-9.]/g, "");
                      setPrice(inputValue);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          sx={{ paddingRight: "5px" }}
                          position="start"
                        >
                          {findCountryDevise(restaurant?.country)}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="allergens">
                  <SelectWithCheckBoxes
                    items={listAllergens}
                    setValues={setListNameAllergens}
                    valuesSelected={defaultProduct?.allergens?.map(
                      (a) => a?.name
                    )}
                    setValuesSelected={setListIdAllergens}
                  />
                </div>

                <div className="ingredients">
                  <div className="subTitle">
                    {" "}
                    {t("product_page_ingredients_title")}{" "}
                  </div>
                  <TextField
                    label={t("product_page_ingredients_add_label")}
                    color="warning"
                    variant="standard"
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    value={ingredient}
                    onChange={(e) => {
                      setIngredient(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Fade in={ingredient !== ""}>
                            <Button
                              disabled={ingredient === ""}
                              onClick={() => {
                                handleAddIngredient();
                                setIngredient("");
                              }}
                              color="warning"
                              endIcon={<Send />}
                            />
                          </Fade>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <div className="listIngredient">
                    <List>
                      <TransitionGroup
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          overflowX: "auto",
                          backgroundColor:
                            ingredients.length > 0 && "whitesmoke",
                          paddingTop: ingredients.length > 0 && "1rem",
                          paddingBottom: ingredients.length > 0 && "1rem",
                          borderRadius: "5px",
                        }}
                      >
                        {ingredients.map((item, k) => (
                          <Collapse key={item}>
                            {renderItemImage({
                              item,
                              removeImage: removeIngredient,
                              index: k,
                              label: item,
                            })}
                          </Collapse>
                        ))}
                      </TransitionGroup>
                    </List>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <TemporaryAlert
              open={error}
              setOpen={setError}
              severity="error"
              text={errorMsg || "An error occurred while saving the product."}
            />

            <TemporaryAlert
              open={success}
              setOpen={setSuccess}
              severity="success"
              text={"Saved successfully!"}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5rem",
            }}
          >
            {loading ? (
              <LoaderThreeDot />
            ) : (
              <Button
                disabled={
                  name === "" || price === "" || ingredients.length === 0
                }
                onClick={handleAddProduct}
                color="warning"
              >
                {defaultProduct
                  ? t("button_update")
                  : t("product_page_save_button")}
              </Button>
            )}
            {defaultProduct && (
              <div>
                {loadingDelete ? (
                  <LoaderThreeDot />
                ) : (
                  <Button
                    startIcon={<DeleteForever />}
                    onClick={handleDeleteProduct}
                    color="error"
                  >
                    {t("delete_button_label")}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </FadeComponent>
    </>
  );
};
