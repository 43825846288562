import React from "react";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import { Typography } from "@mui/material";

const LoadingPage = ({text}) => {
  return (
    <div
      style={{
        position: "relative",

        height: "87.1vh",
      }}
    >
      <div
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          textAlign : 'center'
        }}
      >
        <LoaderThreeDot  />
        <Typography paddingTop={3} color={'GrayText'}>{text}</Typography>
      </div>
    </div>
  );
};

export default LoadingPage;
