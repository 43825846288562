import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countriesWithDevise } from "../utils/utils";
import { useTranslation } from "react-i18next";

const CountryInfo = ({
  selectCountryName = false,
  setValue,
  setInfoCountry,
  defaultValue,
  countriesProps = countriesWithDevise,
  showFlag = true,
  variant = "outlined",
  label,
  showDevise = false,
}) => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: "100%" }}
      options={countriesProps}
      autoHighlight
      getOptionLabel={(option) => {
        setInfoCountry && setInfoCountry(option);

        setValue && setValue(option.phone);
        if (showDevise) {
          return `${option.currency} (${option.symbole})`;
        }
        return selectCountryName ? option.label : `+${option.phone}`;
      }}
      contentEditable={false}
      disableClearable
      defaultValue={() => {
        setValue &&
          setValue(
            defaultValue ? defaultValue.phone : countriesProps[19].phone
          );
        return defaultValue || countriesProps[19];
      }}
      renderOption={(props, option) => (
        <Box
          key={option.code}
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {showFlag && (
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt="flag"
            />
          )}
          {selectCountryName ? option.label : ""}{" "}
          {selectCountryName && !showDevise ? `(${option.code})` : ""}{" "}
          {showDevise ? `(${option.currency})` : ""}{" "}
          {`${!selectCountryName ? "+" + option.phone : ""}`}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          fullWidth
          variant={variant}
          {...params}
          color="warning"
          label={`${
            label
              ? label
              : selectCountryName
              ? `${t("country_label")}*`
              : `Code`
          }`}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountryInfo;
