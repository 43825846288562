import React from "react";
import Carousel from "react-bootstrap/Carousel";
import CardMedia from "@mui/material/CardMedia";
import "../styles/Caroussel.scss";
import { imagePath } from "../utils/utils";

const CarousselImage = ({
  forList = true,
  images,
  controls = false,
  indicators = false,
  fade = true,
  showOne = false,
  forDetail = false,
  configMode,
  active = true,
}) => {
  const showImages = (image, key) => {
    return (
      <Carousel.Item key={key}>
        {forList ? (
          <CardMedia
            loading="lazy"
            className={`${forDetail ? "itemDetail" : "item"}`}
            component="img"
            image={image}
            // src={`${imagePath}/default-repas.png`}
            // srcSet={image}
            alt="plat"
          />
        ) : (
          <img className="itemNoList" loading="lazy" src={image} alt="plat" />
        )}
      </Carousel.Item>
    );
  };

  return (
    <div>
      {images?.length > 0 && (
        <Carousel
          className={!configMode ? "caroussel" : "carousselConfig"}
          indicators={indicators && images?.length > 1}
          controls={controls && images?.length > 1}
          fade={fade}
        >
          {showOne
            ? showImages(images[0], 1)
            : images?.map((image, key) => {
                return showImages(image, key);
              })}
        </Carousel>
      )}
      {images?.length === 0 && (
        <Carousel
          className={!configMode ? "caroussel" : "carousselConfig"}
          indicators={indicators && images?.length > 1}
          controls={controls && images?.length > 1}
          fade={fade}
        >
          {showImages(`${imagePath}/default_plat_oresto.jpg`, 1)}
        </Carousel>
      )}
    </div>
  );
};

export default CarousselImage;
