import parsePhoneNumberFromString, {
  isValidPhoneNumber,
} from "libphonenumber-js";
// import CreditCardCheckout from "../components/CreditCardCheckout";
// import MobileMoneyCheckout from "../components/MobileMoneyCheckout";
// import PaypalCheckout from "../components/PaypalCheckout";

export const imagePath = `${process.env.PUBLIC_URL}/assets/images`;
//
export const baseUrlWeb =
  process.env.NODE_ENV === "production"
    ? "https://www.oresto.company"
    : "http://localhost:3000";
export const greenOlive = "#F58216";
export const DEFAULT_TEMPLATE = "d-71a5294fe2334420b716340112c4b2b0";
export const PASSWORD_TEMPLATE = "d-511859e0891941caae515688c80b04a3";
export const urlImageProduct = (name) => {
  return `https://firebasestorage.googleapis.com/v0/b/o-resto-908c4.appspot.com/o/products%2F${name}?alt=media`;
};

export const EMAIL_COMPANY = "contact@oresto.company";

export const consoleLog = (message, variable) => {
  if (process.env.NODE_ENV !== "production") {
    if (message && variable) {
      console.log(`${message}: `, variable);
    } else if (message) {
      console.log(message);
    }
  }
};

const months = [
  "jan",
  "fév",
  "mar",
  "avr",
  "mai",
  "jun",
  "jul",
  "aoû",
  "sep",
  "oct",
  "nov",
  "déc",
];

export function generateCode() {
  return Math.floor(1000 + Math.random() * 9000);
}

export function convertArrayToNumber(values) {
  return Number(values.join(""));
}

export function validateNumber(numberStr) {
  if (numberStr) {
    let nombreEnCours = "";

    for (let c of numberStr) {
      const isDigit = !isNaN(c);
      if (isDigit) {
        nombreEnCours += c;
      }
    }

    return Number(nombreEnCours);
  }
}

export const dateToString = (date) => {
  const myDate = {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  };
  return `${myDate.day < 10 ? "0" : ""}${myDate.day} ${months[myDate.month]}. ${
    myDate.year
  }`;
};

export function getBaseUrl(url) {
  const parsedUrl = new URL(url);
  return `${parsedUrl.protocol}//${parsedUrl.hostname}${
    parsedUrl.pathname.split("/b")[0]
  }`;
}

export function extractFileNameFromUrl(url) {
  if (url) {
    // Divisez l'URL en utilisant '/' comme séparateur
    const parts = url.split("/");
    // Le nom du fichier est généralement la dernière partie de l'URL
    const fileName = parts[parts.length - 1];
    // Si l'URL contient des paramètres (comme "?alt=media"), nous devons les retirer
    const fileNameWithoutParams = fileName.split("?")[0];

    return decodeURIComponent(fileNameWithoutParams);
  }
}

export function areObjectsEqual(objA, objB) {
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    console.log("pas la meme  taille");
    return false;
  }

  for (const key of keysA) {
    if (objA[key] !== objB[key]) {
      console.log("pas les meme clé : ", key);
      return false;
    }
  }

  return true;
}

export function formaterPrix(prix) {
  const prixEnNombre = parseFloat(prix);

  // Utilisez la méthode toLocaleString() pour ajouter automatiquement le point comme séparateur des milliers
  return prixEnNombre.toFixed(2).toLocaleString(); // Remplacez 'fr-FR' par le code de la locale souhaitée
}

export const goodFormatPwd = (password) => {
  if (password && typeof password === "string") {
    const goodLength = password.length >= 6;
    const containsUpperCase = password
      .split("")
      .some((c) => c === c.toUpperCase());
    const containsLowerCase = password
      .split("")
      .some((c) => c === c.toLowerCase());
    const containsDigit = password.split("").some((c) => /\d/.test(c));

    return (
      goodLength && containsDigit && containsLowerCase && containsUpperCase
    );
  } else return false;

  // console.log(password);
  // const regex = /^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  // return regex.test(password);
};

// export const getPaymentInfo = ({
//   paymentMethod,
//   email,
//   currency,
//   country,
//   plan,
//   address,
//   city,
//   phone,
// }) => {
//   console.log("email utils : ", email);
//   let page;
//   switch (paymentMethod) {
//     case "Carte de crédit":
//       page = (
//         <CreditCardCheckout
//           infos={{ email, currency, country, plan, address, city, phone }}
//         />
//       );
//       break;
//     // case "Paypal":
//     //   page = <PaypalCheckout infos={{ email, currency, country, plan }} />;
//     //   break;
//     case "Mobile Money":
//       page = <MobileMoneyCheckout infos={{ email, currency, country, plan }} />;
//       break;

//     default:
//       page = <CreditCardCheckout infos={{ email, currency, country, plan }} />;
//       break;
//   }

//   return { plan, page };
// };
export const goodFormatEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const extractNumber = (code) => {
  // Utilise une expression régulière pour trouver tous les nombres dans le texte
  const nombresTrouves = code.match(/\d+(\.\d+)?/g);

  // Si des nombres ont été trouvés, les concatène en une seule chaîne
  if (nombresTrouves) {
    const resultat = nombresTrouves.join("");
    return parseInt(resultat);
  } else {
    return null;
  }
};

export const validPhoneNumber = ({ number, codeCountry }) => {
  if (extraireNumeroTelephone(number) <= 8) {
    return false;
  }

  try {
    const phoneNumber = parsePhoneNumberFromString(number);

    if (
      phoneNumber &&
      isValidPhoneNumber(phoneNumber?.nationalNumber, codeCountry)
    ) {
      return phoneNumber.number;
    }
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la validation du numéro de téléphone :",
      error.message
    );
  }
  return false;
};

export function extraireNumeroTelephone(numero, code) {
  // Supprimer les espaces et les tirets
  numero = numero.replace(/[- ]/g, "");

  // Supprimer le code pays s'il est présent
  let regex = new RegExp("^\\+" + code);
  numero = numero.replace(regex, "");

  // Supprimer le zéro initial s'il est présent, même entre parenthèses
  numero = numero.replace(/^(0|\(0\))/, "");

  return numero;
}

export const getCountry = (code, countries) => {
  return countries.find((c) => c.code === code);
};
//rgb(67, 121, 42)
//rgb(85, 107, 47)
//#dd3f05
//#F58216
//https://firebasestorage.googleapis.com/v0/b/o-resto-908c4.appspot.com/o/products%2Fbolo1.jpg?alt=media&token=04d36400-6b06-4f5c-8ef3-65d19b389595&_gl=1*e9ul3d*_ga*ODg2MjI2Njg2LjE2NTY5MTUxNzE.*_ga_CW55HF8NVT*MTY4NjY2NDc3Ny40LjEuMTY4NjY2Njk2Ni4wLjAuMA..

export const euro = "€";

export const paymentLink = ({ key, email }) => {
  return `https://buy.stripe.com/${key}?prefilled_email=${email}`;
};

export const accountLink = ({ key, email }) => {
  return `https://billing.stripe.com/`;
};

export const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Republic of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

// export const countriesWithDevise = [
//   { code: "AD", label: "Andorra", phone: "376", devise: "Euro", symbole: "€" },
//   {
//     code: "AE",
//     label: "United Arab Emirates",
//     phone: "971",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   {
//     code: "AF",
//     label: "Afghanistan",
//     phone: "93",
//     devise: "Afghani",
//     symbole: "؋",
//   },
//   {
//     code: "AG",
//     label: "Antigua and Barbuda",
//     phone: "1-268",
//     devise: "Dollar des Caraïbes orientales",
//     symbole: "EC$",
//   },
//   {
//     code: "AI",
//     label: "Anguilla",
//     phone: "1-264",
//     devise: "Dollar des Caraïbes orientales",
//     symbole: "EC$",
//   },
//   { code: "AL", label: "Albania", phone: "355", devise: "Lek", symbole: "L" },
//   {
//     code: "AM",
//     label: "Armenia",
//     phone: "374",
//     devise: "Dram arménien",
//     symbole: "֏",
//   },
//   {
//     code: "AO",
//     label: "Angola",
//     phone: "244",
//     devise: "Kwanza",
//     symbole: "Kz",
//   },
//   { code: "AQ", label: "Antarctica", phone: "672", devise: "", symbole: "" },
//   {
//     code: "AR",
//     label: "Argentina",
//     phone: "54",
//     devise: "Peso argentin",
//     symbole: "$",
//   },
//   {
//     code: "AS",
//     label: "American Samoa",
//     phone: "1-684",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   { code: "AT", label: "Austria", phone: "43", devise: "Euro", symbole: "€" },
//   {
//     code: "AU",
//     label: "Australia",
//     phone: "61",
//     devise: "Dollar australien",
//     symbole: "$",
//   },
//   {
//     code: "AW",
//     label: "Aruba",
//     phone: "297",
//     devise: "Florin arubais",
//     symbole: "ƒ",
//   },
//   {
//     code: "AX",
//     label: "Alland Islands",
//     phone: "358",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "AZ",
//     label: "Azerbaijan",
//     phone: "994",
//     devise: "Manat azéri",
//     symbole: "₼",
//   },
//   {
//     code: "BA",
//     label: "Bosnia and Herzegovina",
//     phone: "387",
//     devise: "Mark convertible",
//     symbole: "KM",
//   },
//   {
//     code: "BB",
//     label: "Barbados",
//     phone: "1-246",
//     devise: "Dollar de Barbade",
//     symbole: "$",
//   },
//   {
//     code: "BD",
//     label: "Bangladesh",
//     phone: "880",
//     devise: "Taka",
//     symbole: "৳",
//   },
//   { code: "BE", label: "Belgium", phone: "32", devise: "Euro", symbole: "€" },
//   {
//     code: "BF",
//     label: "Burkina Faso",
//     phone: "226",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "BG",
//     label: "Bulgaria",
//     phone: "359",
//     devise: "Lev bulgare",
//     symbole: "лв",
//   },
//   {
//     code: "BH",
//     label: "Bahrain",
//     phone: "973",
//     devise: "Dinar de Bahreïn",
//     symbole: ".د.ب",
//   },
//   {
//     code: "BI",
//     label: "Burundi",
//     phone: "257",
//     devise: "Franc burundais",
//     symbole: "FBu",
//   },
//   {
//     code: "BJ",
//     label: "Benin",
//     phone: "229",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "BL",
//     label: "Saint Barthelemy",
//     phone: "590",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "BM",
//     label: "Bermuda",
//     phone: "1-441",
//     devise: "Dollar des Bermudes",
//     symbole: "$",
//   },
//   {
//     code: "BN",
//     label: "Brunei Darussalam",
//     phone: "673",
//     devise: "Dollar de Brunei",
//     symbole: "$",
//   },
//   {
//     code: "BO",
//     label: "Bolivia",
//     phone: "591",
//     devise: "Boliviano",
//     symbole: "Bs.",
//   },
//   {
//     code: "BR",
//     label: "Brazil",
//     phone: "55",
//     devise: "Réal brésilien",
//     symbole: "R$",
//   },
//   {
//     code: "BS",
//     label: "Bahamas",
//     phone: "1-242",
//     devise: "Dollar des Bahamas",
//     symbole: "$",
//   },
//   {
//     code: "BT",
//     label: "Bhutan",
//     phone: "975",
//     devise: "Ngultrum",
//     symbole: "Nu.",
//   },
//   { code: "BV", label: "Bouvet Island", phone: "47", devise: "", symbole: "" },
//   { code: "BW", label: "Botswana", phone: "267", devise: "Pula", symbole: "P" },
//   {
//     code: "BY",
//     label: "Belarus",
//     phone: "375",
//     devise: "Rouble biélorusse",
//     symbole: "Br",
//   },
//   {
//     code: "BZ",
//     label: "Belize",
//     phone: "501",
//     devise: "Dollar bélizien",
//     symbole: "$",
//   },
//   {
//     code: "CA",
//     label: "Canada",
//     phone: "1",
//     devise: "Dollar canadien",
//     symbole: "$",
//   },
//   {
//     code: "CC",
//     label: "Cocos (Keeling) Islands",
//     phone: "61",
//     devise: "Dollar australien",
//     symbole: "$",
//   },
//   {
//     code: "CD",
//     label: "Congo, Democratic Republic of the",
//     phone: "243",
//     devise: "Franc congolais",
//     symbole: "FC",
//   },
//   {
//     code: "CF",
//     label: "Central African Republic",
//     phone: "236",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "CG",
//     label: "Congo, Republic of the",
//     phone: "242",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "CH",
//     label: "Switzerland",
//     phone: "41",
//     devise: "Franc suisse",
//     symbole: "CHF",
//   },
//   {
//     code: "CI",
//     label: "Cote d'Ivoire",
//     phone: "225",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "CK",
//     label: "Cook Islands",
//     phone: "682",
//     devise: "Dollar néo-zélandais",
//     symbole: "$",
//   },
//   {
//     code: "CL",
//     label: "Chile",
//     phone: "56",
//     devise: "Peso chilien",
//     symbole: "$",
//   },
//   {
//     code: "CM",
//     label: "Cameroon",
//     phone: "237",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "CN",
//     label: "China",
//     phone: "86",
//     devise: "Yuan chinois",
//     symbole: "¥",
//   },
//   {
//     code: "CO",
//     label: "Colombia",
//     phone: "57",
//     devise: "Peso colombien",
//     symbole: "$",
//   },
//   {
//     code: "CR",
//     label: "Costa Rica",
//     phone: "506",
//     devise: "Colon costaricien",
//     symbole: "₡",
//   },
//   {
//     code: "CU",
//     label: "Cuba",
//     phone: "53",
//     devise: "Peso cubain",
//     symbole: "₱",
//   },
//   {
//     code: "CV",
//     label: "Cape Verde",
//     phone: "238",
//     devise: "Escudo capverdien",
//     symbole: "$",
//   },
//   {
//     code: "CW",
//     label: "Curacao",
//     phone: "599",
//     devise: "Florin antillais",
//     symbole: "ƒ",
//   },
//   {
//     code: "CX",
//     label: "Christmas Island",
//     phone: "61",
//     devise: "Dollar australien",
//     symbole: "$",
//   },
//   { code: "CY", label: "Cyprus", phone: "357", devise: "Euro", symbole: "€" },
//   {
//     code: "CZ",
//     label: "Czech Republic",
//     phone: "420",
//     devise: "Couronne tchèque",
//     symbole: "Kč",
//   },
//   {
//     code: "DE",
//     label: "Germany",
//     phone: "49",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "DJ",
//     label: "Djibouti",
//     phone: "253",
//     devise: "Franc de Djibouti",
//     symbole: "Fdj",
//   },
//   {
//     code: "DK",
//     label: "Denmark",
//     phone: "45",
//     devise: "Couronne danoise",
//     symbole: "kr",
//   },
//   {
//     code: "DM",
//     label: "Dominica",
//     phone: "1-767",
//     devise: "Dollar de la Caraïbe orientale",
//     symbole: "$",
//   },
//   {
//     code: "DO",
//     label: "Dominican Republic",
//     phone: "1-809",
//     devise: "Peso dominicain",
//     symbole: "RD$",
//   },
//   {
//     code: "DZ",
//     label: "Algeria",
//     phone: "213",
//     devise: "Dinar algérien",
//     symbole: "د.ج",
//   },
//   {
//     code: "EC",
//     label: "Ecuador",
//     phone: "593",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   { code: "EE", label: "Estonia", phone: "372", devise: "Euro", symbole: "€" },
//   {
//     code: "EG",
//     label: "Egypt",
//     phone: "20",
//     devise: "Livre égyptienne",
//     symbole: "E£",
//   },
//   {
//     code: "EH",
//     label: "Western Sahara",
//     phone: "212",
//     devise: "Dirham marocain",
//     symbole: "د.م.",
//   },
//   {
//     code: "ER",
//     label: "Eritrea",
//     phone: "291",
//     devise: "Nakfa érythréen",
//     symbole: "Nfk",
//   },
//   { code: "ES", label: "Spain", phone: "34", devise: "Euro", symbole: "€" },
//   {
//     code: "ET",
//     label: "Ethiopia",
//     phone: "251",
//     devise: "Birr éthiopien",
//     symbole: "Br",
//   },
//   { code: "FI", label: "Finland", phone: "358", devise: "Euro", symbole: "€" },
//   {
//     code: "FJ",
//     label: "Fiji",
//     phone: "679",
//     devise: "Dollar fidjien",
//     symbole: "$",
//   },
//   {
//     code: "FK",
//     label: "Falkland Islands (Malvinas)",
//     phone: "500",
//     devise: "Livre des îles Falkland",
//     symbole: "FK£",
//   },
//   {
//     code: "FM",
//     label: "Micronesia, Federated States of",
//     phone: "691",
//     devise: "Dollar des États fédérés de Micronésie",
//     symbole: "$",
//   },
//   {
//     code: "FO",
//     label: "Faroe Islands",
//     phone: "298",
//     devise: "Couronne des îles Féroé",
//     symbole: "kr",
//   },
//   {
//     code: "FR",
//     label: "France",
//     phone: "33",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "GA",
//     label: "Gabon",
//     phone: "241",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "GB",
//     label: "United Kingdom",
//     phone: "44",
//     devise: "Livre sterling",
//     symbole: "£",
//   },
//   {
//     code: "GD",
//     label: "Grenada",
//     phone: "1-473",
//     devise: "Dollar de la Caraïbe orientale",
//     symbole: "$",
//   },
//   {
//     code: "GE",
//     label: "Georgia",
//     phone: "995",
//     devise: "Lari géorgien",
//     symbole: "₾",
//   },
//   {
//     code: "GF",
//     label: "French Guiana",
//     phone: "594",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "GG",
//     label: "Guernsey",
//     phone: "44",
//     devise: "Livre de Guernesey",
//     symbole: "£",
//   },
//   {
//     code: "GH",
//     label: "Ghana",
//     phone: "233",
//     devise: "Cedi ghanéen",
//     symbole: "¢",
//   },
//   {
//     code: "GI",
//     label: "Gibraltar",
//     phone: "350",
//     devise: "Livre de Gibraltar",
//     symbole: "£",
//   },
//   {
//     code: "GL",
//     label: "Greenland",
//     phone: "299",
//     devise: "Couronne danoise",
//     symbole: "kr",
//   },
//   {
//     code: "GM",
//     label: "Gambia",
//     phone: "220",
//     devise: "Dalasi gambien",
//     symbole: "D",
//   },
//   {
//     code: "GN",
//     label: "Guinea",
//     phone: "224",
//     devise: "Franc guinéen",
//     symbole: "FG",
//   },
//   {
//     code: "GP",
//     label: "Guadeloupe",
//     phone: "590",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "GQ",
//     label: "Equatorial Guinea",
//     phone: "240",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   { code: "GR", label: "Greece", phone: "30", devise: "Euro", symbole: "€" },
//   {
//     code: "GS",
//     label: "South Georgia and the South Sandwich Islands",
//     phone: "500",
//     devise: "Livre des îles Malouines",
//     symbole: "GS£",
//   },
//   {
//     code: "GT",
//     label: "Guatemala",
//     phone: "502",
//     devise: "Quetzal guatémaltèque",
//     symbole: "Q",
//   },
//   {
//     code: "GU",
//     label: "Guam",
//     phone: "1-671",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   {
//     code: "GW",
//     label: "Guinea-Bissau",
//     phone: "245",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "GY",
//     label: "Guyana",
//     phone: "592",
//     devise: "Dollar guyanais",
//     symbole: "$",
//   },
//   {
//     code: "HK",
//     label: "Hong Kong",
//     phone: "852",
//     devise: "Dollar de Hong Kong",
//     symbole: "$",
//   },
//   {
//     code: "HM",
//     label: "Heard Island and McDonald Islands",
//     phone: "672",
//     devise: "Dollar australien",
//     symbole: "$",
//   },
//   {
//     code: "HN",
//     label: "Honduras",
//     phone: "504",
//     devise: "Lempira hondurien",
//     symbole: "L",
//   },
//   {
//     code: "HR",
//     label: "Croatia",
//     phone: "385",
//     devise: "Kuna croate",
//     symbole: "kn",
//   },
//   {
//     code: "HT",
//     label: "Haiti",
//     phone: "509",
//     devise: "Gourde haïtienne",
//     symbole: "G",
//   },
//   {
//     code: "HU",
//     label: "Hungary",
//     phone: "36",
//     devise: "Forint hongrois",
//     symbole: "Ft",
//   },
//   {
//     code: "ID",
//     label: "Indonesia",
//     phone: "62",
//     devise: "Roupie indonésienne",
//     symbole: "Rp",
//   },
//   { code: "IE", label: "Ireland", phone: "353", devise: "Euro", symbole: "€" },
//   {
//     code: "IL",
//     label: "Israel",
//     phone: "972",
//     devise: "Nouveau shekel israélien",
//     symbole: "₪",
//   },
//   {
//     code: "IM",
//     label: "Isle of Man",
//     phone: "44",
//     devise: "Livre mannoise",
//     symbole: "£",
//   },
//   {
//     code: "IN",
//     label: "India",
//     phone: "91",
//     devise: "Roupie indienne",
//     symbole: "₹",
//   },
//   {
//     code: "IO",
//     label: "British Indian Ocean Territory",
//     phone: "246",
//     devise: "Roupie indienne",
//     symbole: "₹",
//   },
//   {
//     code: "IQ",
//     label: "Iraq",
//     phone: "964",
//     devise: "Dinar irakien",
//     symbole: "ع.د",
//   },
//   {
//     code: "IR",
//     label: "Iran, Islamic Republic of",
//     phone: "98",
//     devise: "Rial iranien",
//     symbole: "﷼",
//   },
//   {
//     code: "IS",
//     label: "Iceland",
//     phone: "354",
//     devise: "Couronne islandaise",
//     symbole: "kr",
//   },
//   { code: "IT", label: "Italy", phone: "39", devise: "Euro", symbole: "€" },
//   {
//     code: "JE",
//     label: "Jersey",
//     phone: "44",
//     devise: "Livre de Jersey",
//     symbole: "£",
//   },
//   {
//     code: "JM",
//     label: "Jamaica",
//     phone: "1-876",
//     devise: "Dollar jamaïcain",
//     symbole: "$",
//   },
//   {
//     code: "JO",
//     label: "Jordan",
//     phone: "962",
//     devise: "Dinar jordanien",
//     symbole: "د.ا",
//   },
//   {
//     code: "JP",
//     label: "Japan",
//     phone: "81",
//     devise: "Yen japonais",
//     symbole: "¥",
//   },
//   {
//     code: "KE",
//     label: "Kenya",
//     phone: "254",
//     devise: "Shilling kényan",
//     symbole: "KSh",
//   },
//   {
//     code: "KG",
//     label: "Kyrgyzstan",
//     phone: "996",
//     devise: "Som kirghize",
//     symbole: "с",
//   },
//   {
//     code: "KH",
//     label: "Cambodia",
//     phone: "855",
//     devise: "Riel cambodgien",
//     symbole: "៛",
//   },
//   {
//     code: "KI",
//     label: "Kiribati",
//     phone: "686",
//     devise: "Dollar australien",
//     symbole: "$",
//   },
//   {
//     code: "KM",
//     label: "Comoros",
//     phone: "269",
//     devise: "Franc des Comores",
//     symbole: "CF",
//   },
//   {
//     code: "KN",
//     label: "Saint Kitts and Nevis",
//     phone: "1-869",
//     devise: "Dollar de la Caraïbe orientale",
//     symbole: "$",
//   },
//   {
//     code: "KP",
//     label: "North Korea",
//     phone: "850",
//     devise: "Won nord-coréen",
//     symbole: "₩",
//   },
//   {
//     code: "KR",
//     label: "South Korea",
//     phone: "82",
//     devise: "Won sud-coréen",
//     symbole: "₩",
//   },
//   {
//     code: "KW",
//     label: "Kuwait",
//     phone: "965",
//     devise: "Dinar koweïtien",
//     symbole: "د.ك",
//   },
//   {
//     code: "KY",
//     label: "Cayman Islands",
//     phone: "1-345",
//     devise: "Dollar des îles Caïmans",
//     symbole: "$",
//   },
//   {
//     code: "KZ",
//     label: "Kazakhstan",
//     phone: "7",
//     devise: "Tenge kazakh",
//     symbole: "₸",
//   },
//   { code: "LA", label: "Laos", phone: "856", devise: "Kip lao", symbole: "₭" },
//   {
//     code: "LB",
//     label: "Lebanon",
//     phone: "961",
//     devise: "Livre libanaise",
//     symbole: "ل.ل",
//   },
//   {
//     code: "LC",
//     label: "Saint Lucia",
//     phone: "1-758",
//     devise: "Dollar de la Caraïbe orientale",
//     symbole: "$",
//   },
//   {
//     code: "LI",
//     label: "Liechtenstein",
//     phone: "423",
//     devise: "Franc suisse",
//     symbole: "CHF",
//   },
//   {
//     code: "LK",
//     label: "Sri Lanka",
//     phone: "94",
//     devise: "Roupie sri-lankaise",
//     symbole: "රු",
//   },
//   {
//     code: "LR",
//     label: "Liberia",
//     phone: "231",
//     devise: "Dollar libérien",
//     symbole: "$",
//   },
//   { code: "LS", label: "Lesotho", phone: "266", devise: "Loti", symbole: "L" },
//   {
//     code: "LT",
//     label: "Lithuania",
//     phone: "370",
//     devise: "Litas lituanien",
//     symbole: "Lt",
//   },
//   {
//     code: "LU",
//     label: "Luxembourg",
//     phone: "352",
//     devise: "Euro",
//     symbole: "€",
//   },
//   { code: "LV", label: "Latvia", phone: "371", devise: "Euro", symbole: "€" },
//   {
//     code: "LY",
//     label: "Libya",
//     phone: "218",
//     devise: "Dinar libyen",
//     symbole: "ل.د",
//   },
//   {
//     code: "MA",
//     label: "Morocco",
//     phone: "212",
//     devise: "Dirham marocain",
//     symbole: "د.م.",
//   },
//   { code: "MC", label: "Monaco", phone: "377", devise: "Euro", symbole: "€" },
//   {
//     code: "MD",
//     label: "Moldova, Republic of",
//     phone: "373",
//     devise: "Leu moldave",
//     symbole: "L",
//   },
//   {
//     code: "ME",
//     label: "Montenegro",
//     phone: "382",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "MF",
//     label: "Saint Martin",
//     phone: "590",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "MG",
//     label: "Madagascar",
//     phone: "261",
//     devise: "Ariary malgache",
//     symbole: "Ar",
//   },
//   {
//     code: "MH",
//     label: "Marshall Islands",
//     phone: "692",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   {
//     code: "MK",
//     label: "Macedonia",
//     phone: "389",
//     devise: "Denar macédonien",
//     symbole: "ден",
//   },
//   {
//     code: "ML",
//     label: "Mali",
//     phone: "223",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "MM",
//     label: "Myanmar",
//     phone: "95",
//     devise: "Kyat birman",
//     symbole: "Ks",
//   },
//   {
//     code: "MN",
//     label: "Mongolia",
//     phone: "976",
//     devise: "Tugrik mongol",
//     symbole: "₮",
//   },
//   {
//     code: "MO",
//     label: "Macao",
//     phone: "853",
//     devise: "Pataca de Macao",
//     symbole: "MOP$",
//   },
//   {
//     code: "MP",
//     label: "Northern Mariana Islands",
//     phone: "1-670",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   {
//     code: "MQ",
//     label: "Martinique",
//     phone: "596",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "MR",
//     label: "Mauritania",
//     phone: "222",
//     devise: "Ouguiya mauritanien",
//     symbole: "UM",
//   },
//   {
//     code: "MS",
//     label: "Montserrat",
//     phone: "1-664",
//     devise: "Dollar des Caraïbes orientales",
//     symbole: "$",
//   },
//   { code: "MT", label: "Malta", phone: "356", devise: "Euro", symbole: "€" },
//   {
//     code: "MU",
//     label: "Mauritius",
//     phone: "230",
//     devise: "Roupie mauricienne",
//     symbole: "₨",
//   },
//   {
//     code: "MV",
//     label: "Maldives",
//     phone: "960",
//     devise: "Rufiyaa maldivienne",
//     symbole: "Rf",
//   },
//   {
//     code: "MW",
//     label: "Malawi",
//     phone: "265",
//     devise: "Kwacha malawite",
//     symbole: "MK",
//   },
//   {
//     code: "MX",
//     label: "Mexico",
//     phone: "52",
//     devise: "Peso mexicain",
//     symbole: "$",
//   },
//   {
//     code: "MY",
//     label: "Malaysia",
//     phone: "60",
//     devise: "Ringgit malais",
//     symbole: "RM",
//   },
//   {
//     code: "MZ",
//     label: "Mozambique",
//     phone: "258",
//     devise: "Metical mozambicain",
//     symbole: "MT",
//   },
//   {
//     code: "NA",
//     label: "Namibia",
//     phone: "264",
//     devise: "Dollar namibien",
//     symbole: "$",
//   },
//   {
//     code: "NC",
//     label: "New Caledonia",
//     phone: "687",
//     devise: "Franc Pacifique",
//     symbole: "F",
//   },
//   {
//     code: "NE",
//     label: "Niger",
//     phone: "227",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "NF",
//     label: "Norfolk Island",
//     phone: "672",
//     devise: "Dollar australien",
//     symbole: "$",
//   },
//   {
//     code: "NG",
//     label: "Nigeria",
//     phone: "234",
//     devise: "Naira nigérian",
//     symbole: "₦",
//   },
//   {
//     code: "NI",
//     label: "Nicaragua",
//     phone: "505",
//     devise: "Cordoba nicaraguayen",
//     symbole: "C$",
//   },
//   {
//     code: "NL",
//     label: "Netherlands",
//     phone: "31",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "NO",
//     label: "Norway",
//     phone: "47",
//     devise: "Couronne norvégienne",
//     symbole: "kr",
//   },
//   {
//     code: "NP",
//     label: "Nepal",
//     phone: "977",
//     devise: "Roupie népalaise",
//     symbole: "रू",
//   },
//   {
//     code: "NR",
//     label: "Nauru",
//     phone: "674",
//     devise: "Dollar australien",
//     symbole: "$",
//   },
//   {
//     code: "NU",
//     label: "Niue",
//     phone: "683",
//     devise: "Dollar néo-zélandais",
//     symbole: "$",
//   },
//   {
//     code: "NZ",
//     label: "New Zealand",
//     phone: "64",
//     devise: "Dollar néo-zélandais",
//     symbole: "$",
//   },
//   {
//     code: "OM",
//     label: "Oman",
//     phone: "968",
//     devise: "Rial omanais",
//     symbole: "﷼",
//   },
//   {
//     code: "PA",
//     label: "Panama",
//     phone: "507",
//     devise: "Balboa panaméen",
//     symbole: "B/.",
//   },
//   {
//     code: "PE",
//     label: "Peru",
//     phone: "51",
//     devise: "Sol péruvien",
//     symbole: "S/.",
//   },
//   {
//     code: "PF",
//     label: "French Polynesia",
//     phone: "689",
//     devise: "Franc Pacifique",
//     symbole: "F",
//   },
//   {
//     code: "PG",
//     label: "Papua New Guinea",
//     phone: "675",
//     devise: "Kina papouan-néo-guinéen",
//     symbole: "K",
//   },
//   {
//     code: "PH",
//     label: "Philippines",
//     phone: "63",
//     devise: "Peso philippin",
//     symbole: "₱",
//   },
//   {
//     code: "PK",
//     label: "Pakistan",
//     phone: "92",
//     devise: "Roupie pakistanaise",
//     symbole: "₨",
//   },
//   {
//     code: "PL",
//     label: "Poland",
//     phone: "48",
//     devise: "Zloty polonais",
//     symbole: "zł",
//   },
//   {
//     code: "PM",
//     label: "Saint Pierre and Miquelon",
//     phone: "508",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "PN",
//     label: "Pitcairn",
//     phone: "64",
//     devise: "Dollar néo-zélandais",
//     symbole: "$",
//   },
//   {
//     code: "PR",
//     label: "Puerto Rico",
//     phone: "1-787",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   {
//     code: "PS",
//     label: "Palestine, State of",
//     phone: "970",
//     devise: "Nouveau shekel israélien",
//     symbole: "₪",
//   },
//   { code: "PT", label: "Portugal", phone: "351", devise: "Euro", symbole: "€" },
//   {
//     code: "PW",
//     label: "Palau",
//     phone: "680",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   {
//     code: "PY",
//     label: "Paraguay",
//     phone: "595",
//     devise: "Guarani paraguayen",
//     symbole: "₲",
//   },
//   {
//     code: "QA",
//     label: "Qatar",
//     phone: "974",
//     devise: "Rial qatari",
//     symbole: "﷼",
//   },
//   { code: "RE", label: "Reunion", phone: "262", devise: "Euro", symbole: "€" },
//   {
//     code: "RO",
//     label: "Romania",
//     phone: "40",
//     devise: "Leu roumain",
//     symbole: "lei",
//   },
//   {
//     code: "RS",
//     label: "Serbia",
//     phone: "381",
//     devise: "Dinar serbe",
//     symbole: "дин.",
//   },
//   {
//     code: "RU",
//     label: "Russian Federation",
//     phone: "7",
//     devise: "Rouble russe",
//     symbole: "₽",
//   },
//   {
//     code: "RW",
//     label: "Rwanda",
//     phone: "250",
//     devise: "Franc rwandais",
//     symbole: "R₣",
//   },
//   {
//     code: "SA",
//     label: "Saudi Arabia",
//     phone: "966",
//     devise: "Riyal saoudien",
//     symbole: "﷼",
//   },
//   {
//     code: "SB",
//     label: "Solomon Islands",
//     phone: "677",
//     devise: "Dollar des îles Salomon",
//     symbole: "S$",
//   },
//   {
//     code: "SC",
//     label: "Seychelles",
//     phone: "248",
//     devise: "Roupie des Seychelles",
//     symbole: "₨",
//   },
//   {
//     code: "SD",
//     label: "Sudan",
//     phone: "249",
//     devise: "Livre soudanaise",
//     symbole: "£",
//   },
//   {
//     code: "SE",
//     label: "Sweden",
//     phone: "46",
//     devise: "Couronne suédoise",
//     symbole: "kr",
//   },
//   {
//     code: "SG",
//     label: "Singapore",
//     phone: "65",
//     devise: "Dollar de Singapour",
//     symbole: "$",
//   },
//   {
//     code: "SH",
//     label: "Saint Helena",
//     phone: "290",
//     devise: "Livre de Sainte-Hélène",
//     symbole: "£",
//   },
//   { code: "SI", label: "Slovenia", phone: "386", devise: "Euro", symbole: "€" },
//   {
//     code: "SJ",
//     label: "Svalbard and Jan Mayen",
//     phone: "47",
//     devise: "Couronne norvégienne",
//     symbole: "kr",
//   },
//   { code: "SK", label: "Slovakia", phone: "421", devise: "Euro", symbole: "€" },
//   {
//     code: "SL",
//     label: "Sierra Leone",
//     phone: "232",
//     devise: "Leone",
//     symbole: "Le",
//   },
//   {
//     code: "SM",
//     label: "San Marino",
//     phone: "378",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "SN",
//     label: "Senegal",
//     phone: "221",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "SO",
//     label: "Somalia",
//     phone: "252",
//     devise: "Shilling somalien",
//     symbole: "S",
//   },
//   {
//     code: "SR",
//     label: "Suriname",
//     phone: "597",
//     devise: "Dollar surinamais",
//     symbole: "$",
//   },
//   {
//     code: "SS",
//     label: "South Sudan",
//     phone: "211",
//     devise: "Livre soudanaise",
//     symbole: "£",
//   },
//   {
//     code: "ST",
//     label: "Sao Tome and Principe",
//     phone: "239",
//     devise: "Dobra",
//     symbole: "Db",
//   },
//   {
//     code: "SV",
//     label: "El Salvador",
//     phone: "503",
//     devise: "Dollar des États-Unis",
//     symbole: "$",
//   },
//   {
//     code: "SX",
//     label: "Sint Maarten (Dutch part)",
//     phone: "1-721",
//     devise: "Dollar des Caraïbes orientales",
//     symbole: "$",
//   },
//   {
//     code: "SY",
//     label: "Syria",
//     phone: "963",
//     devise: "Livre syrienne",
//     symbole: "£",
//   },
//   {
//     code: "SZ",
//     label: "Swaziland",
//     phone: "268",
//     devise: "Lilangeni",
//     symbole: "L",
//   },
//   {
//     code: "TC",
//     label: "Turks and Caicos Islands",
//     phone: "1-649",
//     devise: "Dollar des îles Caïmans",
//     symbole: "$",
//   },
//   {
//     code: "TD",
//     label: "Chad",
//     phone: "235",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "TF",
//     label: "French Southern Territories",
//     phone: "262",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "TG",
//     label: "Togo",
//     phone: "228",
//     devise: "Franc CFA",
//     symbole: "CFA",
//   },
//   {
//     code: "TH",
//     label: "Thailand",
//     phone: "66",
//     devise: "Baht thaïlandais",
//     symbole: "฿",
//   },
//   {
//     code: "TJ",
//     label: "Tajikistan",
//     phone: "992",
//     devise: "Somoni tadjik",
//     symbole: "ЅМ",
//   },
//   {
//     code: "TK",
//     label: "Tokelau",
//     phone: "690",
//     devise: "Dollar néo-zélandais",
//     symbole: "$",
//   },
//   {
//     code: "TL",
//     label: "Timor-Leste",
//     phone: "670",
//     devise: "Dollar des États-Unis",
//     symbole: "$",
//   },
//   {
//     code: "TM",
//     label: "Turkmenistan",
//     phone: "993",
//     devise: "Manat turkmène",
//     symbole: "T",
//   },
//   {
//     code: "TN",
//     label: "Tunisia",
//     phone: "216",
//     devise: "Dinar tunisien",
//     symbole: "د.ت",
//   },
//   {
//     code: "TO",
//     label: "Tonga",
//     phone: "676",
//     devise: "Pa'anga",
//     symbole: "T$",
//   },
//   {
//     code: "TR",
//     label: "Turkey",
//     phone: "90",
//     devise: "Livre turque",
//     symbole: "₺",
//   },
//   {
//     code: "TT",
//     label: "Trinidad and Tobago",
//     phone: "1-868",
//     devise: "Dollar de Trinité-et-Tobago",
//     symbole: "TT$",
//   },
//   {
//     code: "TV",
//     label: "Tuvalu",
//     phone: "688",
//     devise: "Dollar australien",
//     symbole: "$",
//   },
//   {
//     code: "TW",
//     label: "Taiwan",
//     phone: "886",
//     devise: "Nouveau dollar de Taïwan",
//     symbole: "NT$",
//   },
//   {
//     code: "TZ",
//     label: "Tanzania, United Republic of",
//     phone: "255",
//     devise: "Shilling tanzanien",
//     symbole: "TSh",
//   },
//   {
//     code: "UA",
//     label: "Ukraine",
//     phone: "380",
//     devise: "Hryvnia ukrainienne",
//     symbole: "₴",
//   },
//   {
//     code: "UG",
//     label: "Uganda",
//     phone: "256",
//     devise: "Shilling ougandais",
//     symbole: "USh",
//   },
//   {
//     code: "UM",
//     label: "United States Minor Outlying Islands",
//     phone: "1",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   {
//     code: "US",
//     label: "United States",
//     phone: "1",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   {
//     code: "UY",
//     label: "Uruguay",
//     phone: "598",
//     devise: "Peso uruguayen",
//     symbole: "$U",
//   },
//   {
//     code: "UZ",
//     label: "Uzbekistan",
//     phone: "998",
//     devise: "Sum ouzbek",
//     symbole: "лв",
//   },
//   {
//     code: "VA",
//     label: "Holy See (Vatican City State)",
//     phone: "379",
//     devise: "Euro",
//     symbole: "€",
//   },
//   {
//     code: "VC",
//     label: "Saint Vincent and the Grenadines",
//     phone: "1-784",
//     devise: "Dollar de la Caraïbe orientale",
//     symbole: "$",
//   },
//   {
//     code: "VE",
//     label: "Venezuela",
//     phone: "58",
//     devise: "Bolívar vénézuélien",
//     symbole: "Bs",
//   },
//   {
//     code: "VG",
//     label: "British Virgin Islands",
//     phone: "1-284",
//     devise: "Dollar des Caraïbes orientales",
//     symbole: "$",
//   },
//   {
//     code: "VI",
//     label: "U.S. Virgin Islands",
//     phone: "1-340",
//     devise: "Dollar américain",
//     symbole: "$",
//   },
//   {
//     code: "VN",
//     label: "Vietnam",
//     phone: "84",
//     devise: "Dong vietnamien",
//     symbole: "₫",
//   },
//   { code: "VU", label: "Vanuatu", phone: "678", devise: "Vatu", symbole: "VT" },
//   {
//     code: "WF",
//     label: "Wallis and Futuna",
//     phone: "681",
//     devise: "Franc Pacifique",
//     symbole: "F",
//   },
//   {
//     code: "WS",
//     label: "Samoa",
//     phone: "685",
//     devise: "Tala samoan",
//     symbole: "T",
//   },
//   { code: "XK", label: "Kosovo", phone: "383", devise: "Euro", symbole: "€" },
//   {
//     code: "YE",
//     label: "Yemen",
//     phone: "967",
//     devise: "Rial yéménite",
//     symbole: "﷼",
//   },
//   { code: "YT", label: "Mayotte", phone: "262", devise: "Euro", symbole: "€" },
//   {
//     code: "ZA",
//     label: "South Africa",
//     phone: "27",
//     devise: "Rand",
//     symbole: "R",
//   },
//   {
//     code: "ZM",
//     label: "Zambia",
//     phone: "260",
//     devise: "Kwacha zambien",
//     symbole: "ZK",
//   },
//   {
//     code: "ZW",
//     label: "Zimbabwe",
//     phone: "263",
//     devise: "Dollar zimbabwéen",
//     symbole: "Z$",
//   },
// ];

export const countriesWithDevise = [
  {
    code: "AD",
    label: "Andorra",
    phone: "376",
    currency: "EUR",
    symbole: "€",
  },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
    currency: "USD",
    symbole: "$",
  },
  {
    code: "AF",
    label: "Afghanistan",
    phone: "93",
    currency: "AFN",
    symbole: "؋",
  },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
    currency: "XCD",
    symbole: "EC$",
  },
  {
    code: "AI",
    label: "Anguilla",
    phone: "1-264",
    currency: "XCD",
    symbole: "EC$",
  },
  {
    code: "AL",
    label: "Albania",
    phone: "355",
    currency: "ALL",
    symbole: "L",
  },
  {
    code: "AM",
    label: "Armenia",
    phone: "374",
    currency: "AMD",
    symbole: "֏",
  },
  {
    code: "AO",
    label: "Angola",
    phone: "244",
    currency: "AOA",
    symbole: "Kz",
  },
  {
    code: "AQ",
    label: "Antarctica",
    phone: "672",
    currency: "",
    symbole: "",
  },
  {
    code: "AR",
    label: "Argentina",
    phone: "54",
    currency: "ARS",
    symbole: "$",
  },
  //dfdf
  {
    code: "AS",
    label: "American Samoa",
    phone: "1-684",
    currency: "USD",
    symbole: "$",
  },
  {
    code: "AT",
    label: "Austria",
    phone: "43",
    currency: "EUR",
    symbole: "€",
  },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    currency: "AUD",
    symbole: "$",
  },
  {
    code: "AW",
    label: "Aruba",
    phone: "297",
    currency: "AWG",
    symbole: "ƒ",
  },
  {
    code: "AX",
    label: "Alland Islands",
    phone: "358",
    currency: "EUR",
    symbole: "€",
  },
  {
    code: "AZ",
    label: "Azerbaijan",
    phone: "994",
    currency: "AZN",
    symbole: "₼",
  },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
    currency: "BAM",
    symbole: "KM",
  },
  {
    code: "BB",
    label: "Barbados",
    phone: "1-246",
    currency: "BBD",
    symbole: "$",
  },
  {
    code: "BD",
    label: "Bangladesh",
    phone: "880",
    currency: "BDT",
    symbole: "৳",
  },
  {
    code: "BE",
    label: "Belgium",
    phone: "32",
    currency: "EUR",
    devise: "Euro",
    symbole: "€",
  },
  {
    code: "BF",
    label: "Burkina Faso",
    phone: "226",
    currency: "XOF",
    devise: "Franc CFA",
    symbole: "CFA",
  },
  {
    code: "BG",
    label: "Bulgaria",
    phone: "359",
    currency: "BGN",
    devise: "Lev bulgare",
    symbole: "лв",
  },
  {
    code: "BH",
    label: "Bahrain",
    phone: "973",
    currency: "BHD",
    devise: "Dinar de Bahreïn",
    symbole: ".د.ب",
  },
  {
    code: "BI",
    label: "Burundi",
    phone: "257",
    currency: "BIF",
    devise: "Franc burundais",
    symbole: "FBu",
  },
  {
    code: "BJ",
    label: "Benin",
    phone: "229",
    currency: "XOF",
    devise: "Franc CFA",
    symbole: "CFA",
  },
  {
    code: "BL",
    label: "Saint Barthelemy",
    phone: "590",
    currency: "EUR",
    devise: "Euro",
    symbole: "€",
  },
  {
    code: "BM",
    label: "Bermuda",
    phone: "1-441",
    currency: "BMD",
    devise: "Dollar des Bermudes",
    symbole: "$",
  },
  {
    code: "BN",
    label: "Brunei Darussalam",
    phone: "673",
    currency: "BND",
    devise: "Dollar de Brunei",
    symbole: "$",
  },
  {
    code: "BO",
    label: "Bolivia",
    phone: "591",
    currency: "BOB",
    devise: "Boliviano",
    symbole: "Bs.",
  },
  {
    code: "BR",
    label: "Brazil",
    phone: "55",
    currency: "BRL",
    devise: "Réal brésilien",
    symbole: "R$",
  },
  {
    code: "BS",
    label: "Bahamas",
    phone: "1-242",
    currency: "BSD",
    devise: "Dollar des Bahamas",
    symbole: "$",
  },

  {
    code: "BT",
    label: "Bhutan",
    phone: "975",
    devise: "Ngultrum",
    symbole: "Nu.",
    currency: "BTN"
  },
  {
    code: "BV",
    label: "Bouvet Island",
    phone: "47",
    devise: "",
    symbole: "",
    currency: ""
  },
  {
    code: "BW",
    label: "Botswana",
    phone: "267",
    devise: "Pula",
    symbole: "P",
    currency: "BWP"
  },
  {
    code: "BY",
    label: "Belarus",
    phone: "375",
    devise: "Rouble biélorusse",
    symbole: "Br",
    currency: "BYN"
  },
  {
    code: "BZ",
    label: "Belize",
    phone: "501",
    devise: "Dollar bélizien",
    symbole: "$",
    currency: "BZD"
  },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    devise: "Dollar canadien",
    symbole: "$",
    currency: "CAD"
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
    devise: "Dollar australien",
    symbole: "$",
    currency: "AUD"
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
    devise: "Franc congolais",
    symbole: "FC",
    currency: "CDF"
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XAF"
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XAF"
  },
  {
    code: "CH",
    label: "Switzerland",
    phone: "41",
    devise: "Franc suisse",
    symbole: "CHF",
    currency: "CHF"
  },
  {
    code: "CI",
    label: "Cote d'Ivoire",
    phone: "225",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XOF"
  },
  {
    code: "CK",
    label: "Cook Islands",
    phone: "682",
    devise: "Dollar néo-zélandais",
    symbole: "$",
    currency: "NZD"
  },
  {
    code: "CL",
    label: "Chile",
    phone: "56",
    devise: "Peso chilien",
    symbole: "$",
    currency: "CLP"
  },
  {
    code: "CM",
    label: "Cameroon",
    phone: "237",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XAF"
  },
  {
    code: "CN",
    label: "China",
    phone: "86",
    devise: "Yuan chinois",
    symbole: "¥",
    currency: "CNY"
  },
  {
    code: "CO",
    label: "Colombia",
    phone: "57",
    devise: "Peso colombien",
    symbole: "$",
    currency: "COP"
  },
  {
    code: "CR",
    label: "Costa Rica",
    phone: "506",
    devise: "Colon costaricien",
    symbole: "₡",
    currency: "CRC"
  },
  {
    code: "CU",
    label: "Cuba",
    phone: "53",
    devise: "Peso cubain",
    symbole: "₱",
    currency: "CUP"
  },
  {
    code: "CV",
    label: "Cape Verde",
    phone: "238",
    devise: "Escudo capverdien",
    symbole: "$",
    currency: "CVE"
  },
  {
    code: "CW",
    label: "Curacao",
    phone: "599",
    devise: "Florin antillais",
    symbole: "ƒ",
    currency: "ANG"
  },
  {
    code: "CX",
    label: "Christmas Island",
    phone: "61",
    devise: "Dollar australien",
    symbole: "$",
    currency: "AUD"
  },
  {
    code: "CY",
    label: "Cyprus",
    phone: "357",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "CZ",
    label: "Czech Republic",
    phone: "420",
    devise: "Couronne tchèque",
    symbole: "Kč",
    currency: "CZK"
  },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "DJ",
    label: "Djibouti",
    phone: "253",
    devise: "Franc de Djibouti",
    symbole: "Fdj",
    currency: "DJF"
  },
  {
    code: "DK",
    label: "Denmark",
    phone: "45",
    devise: "Couronne danoise",
    symbole: "kr",
    currency: "DKK"
  },
  {
    code: "DM",
    label: "Dominica",
    phone: "1-767",
    devise: "Dollar de la Caraïbe orientale",
    symbole: "$",
    currency: "XCD"
  },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
    devise: "Peso dominicain",
    symbole: "RD$",
    currency: "DOP"
  },
  {
    code: "DZ",
    label: "Algeria",
    phone: "213",
    devise: "Dinar algérien",
    symbole: "د.ج",
    currency: "DZD"
  },
  {
    code: "EC",
    label: "Ecuador",
    phone: "593",
    devise: "Dollar américain",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "EE",
    label: "Estonia",
    phone: "372",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "EG",
    label: "Egypt",
    phone: "20",
    devise: "Livre égyptienne",
    symbole: "E£",
    currency: "EGP"
  },
  {
    code: "EH",
    label: "Western Sahara",
    phone: "212",
    devise: "Dirham marocain",
    symbole: "د.م.",
    currency: "MAD"
  },
  {
    code: "ER",
    label: "Eritrea",
    phone: "291",
    devise: "Nakfa érythréen",
    symbole: "Nfk",
    currency: "ERN"
  },
  {
    code: "ES",
    label: "Spain",
    phone: "34",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "ET",
    label: "Ethiopia",
    phone: "251",
    devise: "Birr éthiopien",
    symbole: "Br",
    currency: "ETB"
  },
  {
    code: "FI",
    label: "Finland",
    phone: "358",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "FJ",
    label: "Fiji",
    phone: "679",
    devise: "Dollar fidjien",
    symbole: "$",
    currency: "FJD"
  },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
    devise: "Livre des îles Falkland",
    symbole: "FK£",
    currency: "FKP"
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
    devise: "Dollar des États fédérés de Micronésie",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "FO",
    label: "Faroe Islands",
    phone: "298",
    devise: "Couronne des îles Féroé",
    symbole: "kr",
    currency: "DKK"
  },
  {
    code: "FR",
    label: "France",
    phone: "33",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "GA",
    label: "Gabon",
    phone: "241",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XAF"
  },
  {
    code: "GB",
    label: "United Kingdom",
    phone: "44",
    devise: "Livre sterling",
    symbole: "£",
    currency: "GBP"
  },
  {
    code: "GD",
    label: "Grenada",
    phone: "1-473",
    devise: "Dollar de la Caraïbe orientale",
    symbole: "$",
    currency: "XCD"
  },
  {
    code: "GE",
    label: "Georgia",
    phone: "995",
    devise: "Lari géorgien",
    symbole: "₾",
    currency: "GEL"
  },
  {
    code: "GF",
    label: "French Guiana",
    phone: "594",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "GG",
    label: "Guernsey",
    phone: "44",
    devise: "Livre de Guernesey",
    symbole: "£",
    currency: "GGP"
  },
  {
    code: "GH",
    label: "Ghana",
    phone: "233",
    devise: "Cedi ghanéen",
    symbole: "¢",
    currency: "GHS"
  },
  {
    code: "GI",
    label: "Gibraltar",
    phone: "350",
    devise: "Livre de Gibraltar",
    symbole: "£",
    currency: "GIP"
  },
  {
    code: "GL",
    label: "Greenland",
    phone: "299",
    devise: "Couronne danoise",
    symbole: "kr",
    currency: "DKK"
  },
  {
    code: "GM",
    label: "Gambia",
    phone: "220",
    devise: "Dalasi gambien",
    symbole: "D",
    currency: "GMD"
  },
  {
    code: "GN",
    label: "Guinea",
    phone: "224",
    devise: "Franc guinéen",
    symbole: "FG",
    currency: "GNF"
  },
  {
    code: "GP",
    label: "Guadeloupe",
    phone: "590",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "GQ",
    label: "Equatorial Guinea",
    phone: "240",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XAF"
  },
  {
    code: "GR",
    label: "Greece",
    phone: "30",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
    devise: "Livre des îles Malouines",
    symbole: "GS£",
    currency: "FKP"
  },
  {
    code: "GT",
    label: "Guatemala",
    phone: "502",
    devise: "Quetzal guatémaltèque",
    symbole: "Q",
    currency: "GTQ"
  },
  {
    code: "GU",
    label: "Guam",
    phone: "1-671",
    devise: "Dollar américain",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "GW",
    label: "Guinea-Bissau",
    phone: "245",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XOF"
  },
  {
    code: "GY",
    label: "Guyana",
    phone: "592",
    devise: "Dollar guyanais",
    symbole: "$",
    currency: "GYD"
  },
  {
    code: "HK",
    label: "Hong Kong",
    phone: "852",
    devise: "Dollar de Hong Kong",
    symbole: "$",
    currency: "HKD"
  },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
    devise: "Dollar australien",
    symbole: "$",
    currency: "AUD"
  },
  {
    code: "HN",
    label: "Honduras",
    phone: "504",
    devise: "Lempira hondurien",
    symbole: "L",
    currency: "HNL"
  },
  {
    code: "HR",
    label: "Croatia",
    phone: "385",
    devise: "Kuna croate",
    symbole: "kn",
    currency: "HRK"
  },
  {
    code: "HT",
    label: "Haiti",
    phone: "509",
    devise: "Gourde haïtienne",
    symbole: "G",
    currency: "HTG"
  },
  {
    code: "HU",
    label: "Hungary",
    phone: "36",
    devise: "Forint hongrois",
    symbole: "Ft",
    currency: "HUF"
  },
  {
    code: "ID",
    label: "Indonesia",
    phone: "62",
    devise: "Roupie indonésienne",
    symbole: "Rp",
    currency: "IDR"
  },
  {
    code: "IE",
    label: "Ireland",
    phone: "353",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "IL",
    label: "Israel",
    phone: "972",
    devise: "Nouveau shekel israélien",
    symbole: "₪",
    currency: "ILS"
  },
  {
    code: "IM",
    label: "Isle of Man",
    phone: "44",
    devise: "Livre mannoise",
    symbole: "£",
    currency: "IMP"
  },
  {
    code: "IN",
    label: "India",
    phone: "91",
    devise: "Roupie indienne",
    symbole: "₹",
    currency: "INR"
  },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
    devise: "Roupie indienne",
    symbole: "₹",
    currency: "INR"
  },
  {
    code: "IQ",
    label: "Iraq",
    phone: "964",
    devise: "Dinar irakien",
    symbole: "ع.د",
    currency: "IQD"
  },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
    devise: "Rial iranien",
    symbole: "﷼",
    currency: "IRR"
  },
  {
    code: "IS",
    label: "Iceland",
    phone: "354",
    devise: "Couronne islandaise",
    symbole: "kr",
    currency: "ISK"
  },
  {
    code: "IT",
    label: "Italy",
    phone: "39",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "JE",
    label: "Jersey",
    phone: "44",
    devise: "Livre de Jersey",
    symbole: "£",
    currency: "JEP"
  },
  {
    code: "JM",
    label: "Jamaica",
    phone: "1-876",
    devise: "Dollar jamaïcain",
    symbole: "$",
    currency: "JMD"
  },
  {
    code: "JO",
    label: "Jordan",
    phone: "962",
    devise: "Dinar jordanien",
    symbole: "د.ا",
    currency: "JOD"
  },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    devise: "Yen japonais",
    symbole: "¥",
    currency: "JPY"
  },
  {
    code: "KE",
    label: "Kenya",
    phone: "254",
    devise: "Shilling kényan",
    symbole: "KSh",
    currency: "KES"
  },
  {
    code: "KG",
    label: "Kyrgyzstan",
    phone: "996",
    devise: "Som kirghize",
    symbole: "с",
    currency: "KGS"
  },
  {
    code: "KH",
    label: "Cambodia",
    phone: "855",
    devise: "Riel cambodgien",
    symbole: "៛",
    currency: "KHR"
  },
  {
    code: "KI",
    label: "Kiribati",
    phone: "686",
    devise: "Dollar australien",
    symbole: "$",
    currency: "AUD"
  },
  {
    code: "KM",
    label: "Comoros",
    phone: "269",
    devise: "Franc des Comores",
    symbole: "CF",
    currency: "KMF"
  },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
    devise: "Dollar de la Caraïbe orientale",
    symbole: "$",
    currency: "XCD"
  },
  {
    code: "KP",
    label: "North Korea",
    phone: "850",
    devise: "Won nord-coréen",
    symbole: "₩",
    currency: "KPW"
  },
  {
    code: "KR",
    label: "South Korea",
    phone: "82",
    devise: "Won sud-coréen",
    symbole: "₩",
    currency: "KRW"
  },
  {
    code: "KW",
    label: "Kuwait",
    phone: "965",
    devise: "Dinar koweïtien",
    symbole: "د.ك",
    currency: "KWD"
  },
  {
    code: "KY",
    label: "Cayman Islands",
    phone: "1-345",
    devise: "Dollar des îles Caïmans",
    symbole: "$",
    currency: "KYD"
  },
  {
    code: "KZ",
    label: "Kazakhstan",
    phone: "7",
    devise: "Tenge kazakh",
    symbole: "₸",
    currency: "KZT"
  },
  {
    code: "LA",
    label: "Laos",
    phone: "856",
    devise: "Kip lao",
    symbole: "₭",
    currency: "LAK"
  },
  {
    code: "LB",
    label: "Lebanon",
    phone: "961",
    devise: "Livre libanaise",
    symbole: "ل.ل",
    currency: "LBP"
  },
  {
    code: "LC",
    label: "Saint Lucia",
    phone: "1-758",
    devise: "Dollar de la Caraïbe orientale",
    symbole: "$",
    currency: "XCD"
  },
  {
    code: "LI",
    label: "Liechtenstein",
    phone: "423",
    devise: "Franc suisse",
    symbole: "CHF",
    currency: "CHF"
  },
  {
    code: "LK",
    label: "Sri Lanka",
    phone: "94",
    devise: "Roupie sri-lankaise",
    symbole: "රු",
    currency: "LKR"
  },
  {
    code: "LR",
    label: "Liberia",
    phone: "231",
    devise: "Dollar libérien",
    symbole: "$",
    currency: "LRD"
  },
  {
    code: "LS",
    label: "Lesotho",
    phone: "266",
    devise: "Loti",
    symbole: "L",
    currency: "LSL"
  },
  {
    code: "LT",
    label: "Lithuania",
    phone: "370",
    devise: "Litas lituanien",
    symbole: "Lt",
    currency: "LTL"
  },
  {
    code: "LU",
    label: "Luxembourg",
    phone: "352",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "LV",
    label: "Latvia",
    phone: "371",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "LY",
    label: "Libya",
    phone: "218",
    devise: "Dinar libyen",
    symbole: "ل.د",
    currency: "LYD"
  },
  {
    code: "MA",
    label: "Morocco",
    phone: "212",
    devise: "Dirham marocain",
    symbole: "د.م.",
    currency: "MAD"
  },
  {
    code: "MC",
    label: "Monaco",
    phone: "377",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
    devise: "Leu moldave",
    symbole: "L",
    currency: "MDL"
  },
  {
    code: "ME",
    label: "Montenegro",
    phone: "382",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "MF",
    label: "Saint Martin",
    phone: "590",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "MG",
    label: "Madagascar",
    phone: "261",
    devise: "Ariary malgache",
    symbole: "Ar",
    currency: "MGA"
  },
  {
    code: "MH",
    label: "Marshall Islands",
    phone: "692",
    devise: "Dollar américain",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "MK",
    label: "Macedonia",
    phone: "389",
    devise: "Denar macédonien",
    symbole: "ден",
    currency: "MKD"
  },
  {
    code: "ML",
    label: "Mali",
    phone: "223",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XOF"
  },
  {
    code: "MM",
    label: "Myanmar",
    phone: "95",
    devise: "Kyat birman",
    symbole: "Ks",
    currency: "MMK"
  },
  {
    code: "MN",
    label: "Mongolia",
    phone: "976",
    devise: "Tugrik mongol",
    symbole: "₮",
    currency: "MNT"
  },
  {
    code: "MO",
    label: "Macao",
    phone: "853",
    devise: "Pataca de Macao",
    symbole: "MOP$",
    currency: "MOP"
  },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
    devise: "Dollar américain",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "MQ",
    label: "Martinique",
    phone: "596",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "MR",
    label: "Mauritania",
    phone: "222",
    devise: "Ouguiya mauritanien",
    symbole: "UM",
    currency: "MRU"
  },
  {
    code: "MS",
    label: "Montserrat",
    phone: "1-664",
    devise: "Dollar des Caraïbes orientales",
    symbole: "$",
    currency: "XCD"
  },
  {
    code: "MT",
    label: "Malta",
    phone: "356",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "MU",
    label: "Mauritius",
    phone: "230",
    devise: "Roupie mauricienne",
    symbole: "₨",
    currency: "MUR"
  },
  {
    code: "MV",
    label: "Maldives",
    phone: "960",
    devise: "Rufiyaa maldivienne",
    symbole: "Rf",
    currency: "MVR"
  },
  {
    code: "MW",
    label: "Malawi",
    phone: "265",
    devise: "Kwacha malawite",
    symbole: "MK",
    currency: "MWK"
  },
  {
    code: "MX",
    label: "Mexico",
    phone: "52",
    devise: "Peso mexicain",
    symbole: "$",
    currency: "MXN"
  },
  {
    code: "MY",
    label: "Malaysia",
    phone: "60",
    devise: "Ringgit malais",
    symbole: "RM",
    currency: "MYR"
  },
  {
    code: "MZ",
    label: "Mozambique",
    phone: "258",
    devise: "Metical mozambicain",
    symbole: "MT",
    currency: "MZN"
  },
  {
    code: "NA",
    label: "Namibia",
    phone: "264",
    devise: "Dollar namibien",
    symbole: "$",
    currency: "NAD"
  },
  {
    code: "NC",
    label: "New Caledonia",
    phone: "687",
    devise: "Franc Pacifique",
    symbole: "F",
    currency: "XPF"
  },
  {
    code: "NE",
    label: "Niger",
    phone: "227",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XOF"
  },
  {
    code: "NF",
    label: "Norfolk Island",
    phone: "672",
    devise: "Dollar australien",
    symbole: "$",
    currency: "AUD"
  },
  {
    code: "NG",
    label: "Nigeria",
    phone: "234",
    devise: "Naira nigérian",
    symbole: "₦",
    currency: "NGN"
  },
  {
    code: "NI",
    label: "Nicaragua",
    phone: "505",
    devise: "Cordoba nicaraguayen",
    symbole: "C$",
    currency: "NIO"
  },
  {
    code: "NL",
    label: "Netherlands",
    phone: "31",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "NO",
    label: "Norway",
    phone: "47",
    devise: "Couronne norvégienne",
    symbole: "kr",
    currency: "NOK"
  },
  {
    code: "NP",
    label: "Nepal",
    phone: "977",
    devise: "Roupie népalaise",
    symbole: "रू",
    currency: "NPR"
  },
  {
    code: "NR",
    label: "Nauru",
    phone: "674",
    devise: "Dollar australien",
    symbole: "$",
    currency: "AUD"
  },
  {
    code: "NU",
    label: "Niue",
    phone: "683",
    devise: "Dollar néo-zélandais",
    symbole: "$",
    currency: "NZD"
  },
  {
    code: "NZ",
    label: "New Zealand",
    phone: "64",
    devise: "Dollar néo-zélandais",
    symbole: "$",
    currency: "NZD"
  },
  {
    code: "OM",
    label: "Oman",
    phone: "968",
    devise: "Rial omanais",
    symbole: "﷼",
    currency: "OMR"
  },
  {
    code: "PA",
    label: "Panama",
    phone: "507",
    devise: "Balboa panaméen",
    symbole: "B/.",
    currency: "PAB"
  },
  {
    code: "PE",
    label: "Peru",
    phone: "51",
    devise: "Sol péruvien",
    symbole: "S/.",
    currency: "PEN"
  },
  {
    code: "PF",
    label: "French Polynesia",
    phone: "689",
    devise: "Franc Pacifique",
    symbole: "F",
    currency: "XPF"
  },
  {
    code: "PG",
    label: "Papua New Guinea",
    phone: "675",
    devise: "Kina papouan-néo-guinéen",
    symbole: "K",
    currency: "PGK"
  },
  {
    code: "PH",
    label: "Philippines",
    phone: "63",
    devise: "Peso philippin",
    symbole: "₱",
    currency: "PHP"
  },
  {
    code: "PK",
    label: "Pakistan",
    phone: "92",
    devise: "Roupie pakistanaise",
    symbole: "₨",
    currency: "PKR"
  },
  {
    code: "PL",
    label: "Poland",
    phone: "48",
    devise: "Zloty polonais",
    symbole: "zł",
    currency: "PLN"
  },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "PN",
    label: "Pitcairn",
    phone: "64",
    devise: "Dollar néo-zélandais",
    symbole: "$",
    currency: "NZD"
  },
  {
    code: "PR",
    label: "Puerto Rico",
    phone: "1-787",
    devise: "Dollar américain",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
    devise: "Nouveau shekel israélien",
    symbole: "₪",
    currency: "ILS"
  },
  {
    code: "PT",
    label: "Portugal",
    phone: "351",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "PW",
    label: "Palau",
    phone: "680",
    devise: "Dollar américain",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "PY",
    label: "Paraguay",
    phone: "595",
    devise: "Guarani paraguayen",
    symbole: "₲",
    currency: "PYG"
  },
  {
    code: "QA",
    label: "Qatar",
    phone: "974",
    devise: "Rial qatari",
    symbole: "﷼",
    currency: "QAR"
  },
  {
    code: "RE",
    label: "Reunion",
    phone: "262",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "RO",
    label: "Romania",
    phone: "40",
    devise: "Leu roumain",
    symbole: "lei",
    currency: "RON"
  },
  {
    code: "RS",
    label: "Serbia",
    phone: "381",
    devise: "Dinar serbe",
    symbole: "дин.",
    currency: "RSD"
  },
  {
    code: "RU",
    label: "Russian Federation",
    phone: "7",
    devise: "Rouble russe",
    symbole: "₽",
    currency: "RUB"
  },
  {
    code: "RW",
    label: "Rwanda",
    phone: "250",
    devise: "Franc rwandais",
    symbole: "R₣",
    currency: "RWF"
  },
  {
    code: "SA",
    label: "Saudi Arabia",
    phone: "966",
    devise: "Riyal saoudien",
    symbole: "﷼",
    currency: "SAR"
  },
  {
    code: "SB",
    label: "Solomon Islands",
    phone: "677",
    devise: "Dollar des îles Salomon",
    symbole: "S$",
    currency: "SBD"
  },
  {
    code: "SC",
    label: "Seychelles",
    phone: "248",
    devise: "Roupie des Seychelles",
    symbole: "₨",
    currency: "SCR"
  },
  {
    code: "SD",
    label: "Sudan",
    phone: "249",
    devise: "Livre soudanaise",
    symbole: "£",
    currency: "SDG"
  },
  {
    code: "SE",
    label: "Sweden",
    phone: "46",
    devise: "Couronne suédoise",
    symbole: "kr",
    currency: "SEK"
  },
  {
    code: "SG",
    label: "Singapore",
    phone: "65",
    devise: "Dollar de Singapour",
    symbole: "$",
    currency: "SGD"
  },
  {
    code: "SH",
    label: "Saint Helena",
    phone: "290",
    devise: "Livre de Sainte-Hélène",
    symbole: "£",
    currency: "SHP"
  },
  {
    code: "SI",
    label: "Slovenia",
    phone: "386",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
    devise: "Couronne norvégienne",
    symbole: "kr",
    currency: "NOK"
  },
  {
    code: "SK",
    label: "Slovakia",
    phone: "421",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "SL",
    label: "Sierra Leone",
    phone: "232",
    devise: "Leone",
    symbole: "Le",
    currency: "SLL"
  },
  {
    code: "SM",
    label: "San Marino",
    phone: "378",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "SN",
    label: "Senegal",
    phone: "221",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XOF"
  },
  {
    code: "SO",
    label: "Somalia",
    phone: "252",
    devise: "Shilling somalien",
    symbole: "S",
    currency: "SOS"
  },
  {
    code: "SR",
    label: "Suriname",
    phone: "597",
    devise: "Dollar surinamais",
    symbole: "$",
    currency: "SRD"
  },
  {
    code: "SS",
    label: "South Sudan",
    phone: "211",
    devise: "Livre soudanaise",
    symbole: "£",
    currency: "SSP"
  },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
    devise: "Dobra",
    symbole: "Db",
    currency: "STN"
  },
  {
    code: "SV",
    label: "El Salvador",
    phone: "503",
    devise: "Dollar des États-Unis",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
    devise: "Dollar des Caraïbes orientales",
    symbole: "$",
    currency: "ANG"
  },
  {
    code: "SY",
    label: "Syria",
    phone: "963",
    devise: "Livre syrienne",
    symbole: "£",
    currency: "SYP"
  },
  {
    code: "SZ",
    label: "Swaziland",
    phone: "268",
    devise: "Lilangeni",
    symbole: "L",
    currency: "SZL"
  },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
    devise: "Dollar des îles Caïmans",
    symbole: "$",
    currency: "KYD"
  },
  {
    code: "TD",
    label: "Chad",
    phone: "235",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XAF"
  },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "TG",
    label: "Togo",
    phone: "228",
    devise: "Franc CFA",
    symbole: "CFA",
    currency: "XOF"
  },
  {
    code: "TH",
    label: "Thailand",
    phone: "66",
    devise: "Baht thaïlandais",
    symbole: "฿",
    currency: "THB"
  },
  {
    code: "TJ",
    label: "Tajikistan",
    phone: "992",
    devise: "Somoni tadjik",
    symbole: "ЅМ",
    currency: "TJS"
  },
  {
    code: "TK",
    label: "Tokelau",
    phone: "690",
    devise: "Dollar néo-zélandais",
    symbole: "$",
    currency: "NZD"
  },
  {
    code: "TL",
    label: "Timor-Leste",
    phone: "670",
    devise: "Dollar des États-Unis",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "TM",
    label: "Turkmenistan",
    phone: "993",
    devise: "Manat turkmène",
    symbole: "T",
    currency: "TMT"
  },
  {
    code: "TN",
    label: "Tunisia",
    phone: "216",
    devise: "Dinar tunisien",
    symbole: "د.ت",
    currency: "TND"
  },
  {
    code: "TO",
    label: "Tonga",
    phone: "676",
    devise: "Pa'anga",
    symbole: "T$",
    currency: "TOP"
  },
  {
    code: "TR",
    label: "Turkey",
    phone: "90",
    devise: "Livre turque",
    symbole: "₺",
    currency: "TRY"
  },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
    devise: "Dollar de Trinité-et-Tobago",
    symbole: "TT$",
    currency: "TTD"
  },
  {
    code: "TV",
    label: "Tuvalu",
    phone: "688",
    devise: "Dollar australien",
    symbole: "$",
    currency: "AUD"
  },
  {
    code: "TW",
    label: "Taiwan",
    phone: "886",
    devise: "Nouveau dollar de Taïwan",
    symbole: "NT$",
    currency: "TWD"
  },
  {
    code: "TZ",
    label: "Tanzania, United Republic of",
    phone: "255",
    devise: "Shilling tanzanien",
    symbole: "TSh",
    currency: "TZS"
  },
  {
    code: "UA",
    label: "Ukraine",
    phone: "380",
    devise: "Hryvnia ukrainienne",
    symbole: "₴",
    currency: "UAH"
  },
  {
    code: "UG",
    label: "Uganda",
    phone: "256",
    devise: "Shilling ougandais",
    symbole: "USh",
    currency: "UGX"
  },
  {
    code: "UM",
    label: "United States Minor Outlying Islands",
    phone: "1",
    devise: "Dollar américain",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "US",
    label: "United States",
    phone: "1",
    devise: "Dollar américain",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "UY",
    label: "Uruguay",
    phone: "598",
    devise: "Peso uruguayen",
    symbole: "$U",
    currency: "UYU"
  },
  {
    code: "UZ",
    label: "Uzbekistan",
    phone: "998",
    devise: "Sum ouzbek",
    symbole: "лв",
    currency: "UZS"
  },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
    devise: "Dollar de la Caraïbe orientale",
    symbole: "$",
    currency: "XCD"
  },
  {
    code: "VE",
    label: "Venezuela",
    phone: "58",
    devise: "Bolívar vénézuélien",
    symbole: "Bs",
    currency: "VES"
  },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
    devise: "Dollar des Caraïbes orientales",
    symbole: "$",
    currency: "XCD"
  },
  {
    code: "VI",
    label: "U.S. Virgin Islands",
    phone: "1-340",
    devise: "Dollar américain",
    symbole: "$",
    currency: "USD"
  },
  {
    code: "VN",
    label: "Vietnam",
    phone: "84",
    devise: "Dong vietnamien",
    symbole: "₫",
    currency: "VND"
  },
  {
    code: "VU",
    label: "Vanuatu",
    phone: "678",
    devise: "Vatu",
    symbole: "VT",
    currency: "VUV"
  },
  {
    code: "WF",
    label: "Wallis and Futuna",
    phone: "681",
    devise: "Franc Pacifique",
    symbole: "F",
    currency: "XPF"
  },
  {
    code: "WS",
    label: "Samoa",
    phone: "685",
    devise: "Tala samoan",
    symbole: "T",
    currency: "WST"
  },
  {
    code: "XK",
    label: "Kosovo",
    phone: "383",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "YE",
    label: "Yemen",
    phone: "967",
    devise: "Rial yéménite",
    symbole: "﷼",
    currency: "YER"
  },
  {
    code: "YT",
    label: "Mayotte",
    phone: "262",
    devise: "Euro",
    symbole: "€",
    currency: "EUR"
  },
  {
    code: "ZA",
    label: "South Africa",
    phone: "27",
    devise: "Rand",
    symbole: "R",
    currency: "ZAR"
  },
  {
    code: "ZM",
    label: "Zambia",
    phone: "260",
    devise: "Kwacha zambien",
    symbole: "ZK",
    currency: "ZMW"
  },
  {
    code: "ZW",
    label: "Zimbabwe",
    phone: "263",
    devise: "Dollar zimbabwéen",
    symbole: "Z$",
    currency: "ZWL"
  },
];

// Find the country information for the given country code
export function findCountryDevise(country) {
  const countryFound = countriesWithDevise.find(
    (c) => c.label.toLowerCase() === country?.toLowerCase()
  );
  return countryFound ? countryFound.symbole : euro;
}
