import React, { useState } from "react";
import "../styles/HorizontalItem.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Drawer, useMediaQuery } from "@mui/material";
import { findCountryDevise, formaterPrix } from "../utils/utils";
import CarousselImage from "./CarousselImage";
import Modal from "./Modal";
import ProductDetails from "./ProductDetails";
import { ProductAdd } from "./PagesUpdate";
import { t } from "i18next";
import { RemoveRedEye, VisibilityOff } from "@mui/icons-material";

const HorizontalItem = ({
  product,
  section,
  sectionList,
  configMode = false,
  country,
}) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const mobileTabletteScreen = useMediaQuery("(max-width:898px)");
  // const { restaurant } = useSelector((state) => state.infosRestaurant);

  return (
    <div className={product?.active ? "" : "inactiveProduct"}>
      {product && (
        <Card
          onClick={() => (!configMode ? setOpenModal(true) : setOpen(true))}
          variant="outlined"
          id={!configMode ? "horizontalItem" : "productConfig"}
        >
          <CardActionArea sx={{ color: "white" }}>
            <div className="blocCar">
              <CarousselImage
                configMode={configMode}
                images={product.images}
                active={product?.active}
              />
              {!product?.active && <div className="activeProduct">
                <VisibilityOff sx={{ fontSize: "2rem" }} />
              </div>}
            </div>

            <CardContent className="content">
              <Typography
                className="productName"
                color={"ButtonText"}
                gutterBottom
                noWrap
                variant="h5"
                component="div"
              >
                {product.label}
              </Typography>
              {parseFloat(product?.price) && (
                <Typography
                  className="price"
                  variant="body2"
                  color="text.secondary"
                >
                  {formaterPrix(product.price)} {findCountryDevise(country)}
                </Typography>
              )}
              <Typography
                className="ingredients"
                variant="body2"
                color="text.secondary"
              >
                <div className="listIngredient">
                  <span className="title">{t("ingredient_label")} :</span>
                  {product.ingredients?.map((ingredient, k) => {
                    return (
                      <li style={{ listStyle: "none" }} key={k}>
                        {" "}
                        {ingredient}
                        {k + 1 >= product.ingredients?.length ? "" : ","}
                      </li>
                    );
                  })}
                </div>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
      <Modal
        fullScreen={true}
        title={t("details")}
        open={openModal}
        setOpen={setOpenModal}
        component={
          <ProductDetails
            sectionList={sectionList}
            section={section}
            product={product}
          />
        }
      />
      <Drawer
        PaperProps={{
          style: { width: mobileTabletteScreen ? "100%" : "50%" },
        }}
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <ProductAdd
          defaultProduct={product}
          categoryId={product?.categoryId}
          setOpen={setOpen}
        />
      </Drawer>
    </div>
  );
};

export default HorizontalItem;
