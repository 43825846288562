import React, { useEffect, useState } from "react";
import FadeComponent from "./FadeComponent";
import {
  Close,
  Done,
  Download,
  Error,
  HourglassBottom,
  Lightbulb,
  Warning,
  WarningAmber,
} from "@mui/icons-material";
import "../styles/AbonnementUser.scss";
import { Button, Chip, Typography } from "@mui/material";
import { plans } from "./Tarifs";
import {
  DEFAULT_TEMPLATE,
  EMAIL_COMPANY,
  consoleLog,
  countriesWithDevise,
  dateToString,
  euro,
  getCountry,
  greenOlive,
  paymentLink,
} from "../utils/utils";
import { getConnectedUser, hasAuthentication } from "../services/Auth";
import { useNavigate } from "react-router";
import {
  cancelSubscription,
  convertCurrency,
  getSubscription,
  manageSubscription,
  sendMail,
} from "../API/api";
import LoadingPage from "./LoadingPage";
import { AlertInfo, TemporaryAlert } from "./PagesUpdate";
import { Accordions } from "./Accordion";
import { useSelector } from "react-redux";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import myGa from "../Google-Analytics/myGa";

const AbonnementUser = () => {
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingManage, setLoadingManage] = useState(false);
  const [loadingDeleteSub, setLoadingDeleteSub] = useState(false);

  const [trialling, setTrialling] = useState(false);
  const [active, setActive] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [pastDue, setPastDue] = useState(false);

  const [successDelete, setSuccessDelete] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [convertedCurrencies, setConvertedCurrencies] = useState([]);
  const [country, setCountry] = useState(countriesWithDevise[19]);
  const [invoice, setInvoice] = useState(null);
  const [nonPayé, setNonPayé] = useState(false);
  const { t } = useTranslation();

  const { restaurant } = useSelector((state) => state.infosRestaurant);

  useEffect(() => {
    myGa();
    if (!hasAuthentication()) {
      navigate("/manager/menu/login");
      return;
    }

    const userData = getConnectedUser();

    setUser(userData);

    setLoading(true);
    getSubscription({ email: userData?.email })
      .then((res) => {
        const sub = res.data;
        setSubscription(sub);
        setInvoice(sub?.invoice);

        const dateOfTheDay = Date.now();
        setNonPayé(
          !sub?.invoice.paid && new Date(sub?.end_date * 1000) > dateOfTheDay
        );

        setActive(
          sub.status === "active" &&
            sub.pause_collection?.behavior !== "keep_as_draft"
        );

        setCancelled(
          sub.status === "canceled" ||
            sub.cancellation_details?.reason === "cancellation_requested" ||
            sub.pause_collection?.behavior === "keep_as_draft"
        );

        setPastDue(sub.status === "past_due");

        setTrialling(
          sub.status === "trialing" &&
            sub.pause_collection?.behavior !== "keep_as_draft"
        );

        const p = plans?.find((p) => {
          return p?.plan === res.data.plan?.id;
        });

        setPlan(p);
        setLoading(false);
      })
      .catch((e) => {
        consoleLog("error get subscription : ", e);
        setLoading(false);
        setSubscription(undefined);
      });

    setCountry(countriesWithDevise.find((c) => c?.label === userData?.country));
    country && handleConvertDevise();
  }, [country]);

  const handleConvertDevise = () => {
    const amounts = plans.map((p) => p.price);
    convertCurrency({ amounts, devise: country?.currency })
      .then((res) => {
        setConvertedCurrencies(res.data);
      })
      .catch((e) => {
        consoleLog("error convert currency: ", e);
        setConvertedCurrencies([]);
      });
  };

  const handleManageSuscription = () => {
    setLoadingManage(true);
    manageSubscription({ customer: subscription?.customer })
      .then((res) => {
        const link = document.createElement("a");

        link.href = res.data.url;

        link.click();
        setLoadingManage(false);
      })
      .catch((e) => {
        consoleLog("error manage sub : ", e);
        setLoadingManage(false);
      });
  };

  const handleCancelSuscription = () => {
    if (trialling) {
      setLoadingDeleteSub(true);
      cancelSubscription({
        id: subscription?.id,
        restaurantId: restaurant?._id,
      })
        .then((res) => {
          setLoadingDeleteSub(false);
          setSuccessMsg(res.data.message);
          setSubscription(null);
        })
        .catch((e) => {
          consoleLog("error cancel subscription : ", e);
          setLoadingDeleteSub(false);
          setErrorMsg(e.response.data.error);
        });
    } else {
      //envoi une demande par mail

      if (hasAuthentication()) {
        setLoadingDeleteSub(true);
        sendMail({
          to: user?.email,
          templateId: DEFAULT_TEMPLATE,
          data: {
            subject: "Cancellation Request Confirmation",
            preheder: `Your Subscription Cancellation Request Has Been Successfully Received!`,
            message: `Your ${subscription?.product?.name} Subscription Cancellation Request Has Been Successfully Received!. 
            We are processing your request as quickly as possible. You will be notified by email or phone of our final decision.
             Thank you for your understanding.
            `,
          },
        })
          .then(() => {
            sendMail({
              to: EMAIL_COMPANY,
              templateId: DEFAULT_TEMPLATE,
              data: {
                subject: "Demande de résiliation",
                preheder: `Demande de résiliation d'abonnement de ${restaurant?.name}`,
                message: `Bonjour, je souhaite résilier mon abonnement actif auprès de ${
                  restaurant?.name
                }. Merci de bien vouloir traiter ma demande. Cordialement.
               Voici les détails de mon abonnement : PLAN : ${
                 subscription?.product?.name
               }, Date-end : ${dateToString(
                  new Date(subscription?.current_period_end * 1000)
                )}, Id : ${subscription?.id}`,
              },
            })
              .then(() => {
                setLoadingDeleteSub(false);
              })
              .catch((e) => {
                setLoadingDeleteSub(false);
              });
          })
          .catch((e) => {
            setLoadingDeleteSub(false);
          });
      }
    }
  };

  return (
    <div>
      {!loading ? (
        <FadeComponent>
          <div className="abonnement">
            {!subscription && (
              <div>
                <div className="header">
                  <div className="contenu">
                    <div className="icon">
                      <Error color="warning" sx={{ width: 70, height: 70 }} />
                    </div>
                    <div className="text">
                      <h5 className="title"> {t("sub_page_empty_title")} </h5>
                      <div className="desc">{t("sub_page_empty_subTitle")}</div>
                    </div>
                  </div>
                </div>
                <hr style={{ width: "90%", margin: "0 auto" }} />

                <div className="plans">
                  <div>
                    <Typography
                      className="title"
                      paddingBottom={"2rem"}
                      variant="h4"
                      color={"GrayText"}
                    >
                      {t("sub_page_empty_choose_plan")}
                    </Typography>

                    {plans.map((p, k) => {
                      return (
                        <div key={k} className="plan">
                          <div className="infos">
                            <div className="name"> {p?.title} </div>
                            <div className="price">
                              {" "}
                              {convertedCurrencies.length > 0
                                ? convertedCurrencies
                                    .find((c) => c.amount == p.price.toString())
                                    ?.result[country?.currency].toFixed(2)
                                : p?.price.toFixed(2)}{" "}
                              {country?.symbole} HT{" "}
                              <Typography variant="caption" color={"GrayText"}>
                                / {t("mois")}
                              </Typography>{" "}
                            </div>
                            <Chip label={t(p.items[2])} />
                          </div>
                          <div className="button">
                            {p?.prefered && (
                              <Chip color="warning" label={"RECOMMANDED"} />
                            )}
                            <Button disabled={p?.disable} color={"inherit"}>
                              <a
                                style={{
                                  color: `${p?.disable ? "gray" : greenOlive}`,
                                  textDecoration: "none",
                                }}
                                href={paymentLink({
                                  key: p?.idLink,
                                  email: user?.email,
                                })}
                              >
                                {t("button_choose")}
                              </a>
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {subscription && (
              <div className="subPresent">
                <div className="blocHead">
                  <div className="title"> {t("sub_page_title")} </div>

                  <div className="name">
                    {plan?.title}{" "}
                    {!subscription.livemode && (
                      <Chip
                        label="T E S T"
                        variant="outlined"
                        color="warning"
                      />
                    )}
                  </div>
                  <div className="desc">
                    {`${
                      !invoice || invoice?.amount_due == 0
                        ? (subscription?.plan.amount / 100).toFixed(2)
                        : (invoice?.amount_due / 100).toFixed(2)
                    } ${subscription?.currency} ${
                      !invoice || invoice?.amount_due == 0
                        ? `HT / ${t("mois")}`
                        : ""
                    }`}{" "}
                    {t(plan?.items[plan?.items.length - 1])}{" "}
                  </div>
                  <div className="status">
                    <Typography
                      color={"InfoText"}
                      component={"div"}
                      variant="caption"
                      paddingTop={4}
                      textTransform={"uppercase"}
                    >
                      Status :
                      {trialling && (
                        <Button
                          sx={{ fontSize: "small" }}
                          endIcon={<HourglassBottom />}
                          color="warning"
                        >
                          Trialing
                        </Button>
                      )}
                      {pastDue && (
                        <Button
                          sx={{ fontSize: "small", color: "red" }}
                          startIcon={<WarningAmber />}
                          color="inherit"
                        >
                          past due
                        </Button>
                      )}
                      {active && (
                        <Button
                          sx={{ fontSize: "small" }}
                          endIcon={<Done />}
                          color="success"
                        >
                          active
                        </Button>
                      )}
                      {cancelled && (
                        <Button
                          sx={{ fontSize: "small" }}
                          endIcon={<Close />}
                          color="error"
                        >
                          Suspended
                        </Button>
                      )}
                    </Typography>
                  </div>
                  {!cancelled && (
                    <div className="date">
                      <div className="begin">
                        <div className="label">
                          {" "}
                          {t("sub_page_begin_title")}{" "}
                        </div>
                        <div className="time">
                          {dateToString(new Date(subscription?.created * 1000))}
                        </div>
                      </div>
                      <div className="end">
                        <div className="label">
                          {" "}
                          {t("sub_page_next_title")}{" "}
                        </div>
                        <div className="time">
                          {`${
                            !invoice || invoice?.amount_due == 0
                              ? (subscription?.plan.amount / 100).toFixed(2)
                              : (invoice?.amount_due / 100).toFixed(2)
                          } ${subscription?.currency} ${
                            !invoice || invoice?.amount_due == 0
                              ? `HT / ${t("mois")}`
                              : ""
                          }`}{" "}
                          {dateToString(
                            new Date(subscription?.current_period_end * 1000)
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {cancelled && (
                    <AlertInfo
                      text={
                        "Access your customer area if you wish to retrieve your subscription !"
                      }
                    />
                  )}
                </div>

                <hr style={{ marginBottom: "2rem", marginTop: "2rem" }} />
                {/* 
                <Collapse sx={{ paddingBottom: "1rem" }} in={nonPayé}>
                  <Alert severity="error">
                    <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                      ATTENTION
                    </span>{" "}
                    : Votre facture du{" "}
                    {dateToString(new Date(invoice.effective_at * 1000))} n'a
                    pas été payée ! veuillez mettre à jour votre moyen de
                    paiement via{" "}
                    <span style={{ fontWeight: "bold" }}>
                      "GERER MON ABONNEMENT" !
                    </span>
                  </Alert>
                </Collapse> */}
                {/* 
                <Collapse in={!nonPayé}>
                  <Alert severity="warning">
                    Nous avons rencontré un problème lors du paiement de votre
                    prochaine facture. veuillez mettre à jour votre moyen de
                    paiement au plus vite via{" "}
                    <span style={{ fontWeight: "bold" }}>
                      "GERER MON ABONNEMENT" !
                    </span>
                  </Alert>
                </Collapse> */}

                <div onClick={handleManageSuscription} className="btn">
                  {loadingManage ? (
                    <LoaderThreeDot />
                  ) : (
                    <Button color="warning">
                      {" "}
                      {t("sub_page_manage_sub")}{" "}
                    </Button>
                  )}
                </div>

                {(trialling || active) && (
                  <div
                    style={{
                      backgroundColor: !loadingDeleteSub
                        ? greenOlive
                        : "lightgrey",
                      color: "white",
                    }}
                    marginTop={2}
                    className="btn"
                    onClick={handleCancelSuscription}
                  >
                    {loadingDeleteSub ? (
                      <LoaderThreeDot />
                    ) : (
                      <Button color="inherit">
                        {" "}
                        {trialling
                          ? t("sub_page_delete_sub")
                          : t("sub_page_delete_request_sub")}{" "}
                      </Button>
                    )}
                  </div>
                )}

                <div className="btn">
                  <Button
                    disabled={!invoice?.invoice_pdf}
                    startIcon={<Download />}
                    color="warning"
                  >
                    <Link
                      to={invoice?.invoice_pdf}
                      style={{
                        color: invoice?.invoice_pdf ? greenOlive : "gray",
                        textDecoration: "none",
                      }}
                    >
                      {t("sub_page_download_pdf")}
                    </Link>
                  </Button>
                </div>

                <div
                  style={{ textAlign: "center", marginTop: "2rem" }}
                  className="accordions"
                >
                  {trialling && (
                    <Accordions
                      key={0}
                      title={
                        <Typography color={"ButtonText"}>
                          <Lightbulb
                            sx={{ marginRight: "1rem", color: greenOlive }}
                          />
                          {t("sub_page_change_sub_title")}
                        </Typography>
                      }
                      body={
                        <Typography
                          variant="body2"
                          sx={{ textAlign: "left", paddingRight: "2rem" }}
                        >
                          {t("sub_page_change_sub_body")}
                        </Typography>
                      }
                    />
                  )}

                  <Accordions
                    key={1}
                    title={
                      <Typography color={"ButtonText"}>
                        <Lightbulb
                          sx={{ marginRight: "1rem", color: greenOlive }}
                        />
                        {t("sub_page_delete_sub_title")}
                      </Typography>
                    }
                    body={
                      <Typography
                        variant="body2"
                        sx={{ textAlign: "left", paddingRight: "2rem" }}
                      >
                        {t("sub_page_delete_sub_body")}
                      </Typography>
                    }
                  />
                </div>
              </div>
            )}
            <TemporaryAlert
              text={successMsg}
              open={successDelete}
              setOpen={setSuccessDelete}
              severity="success"
            />
            <TemporaryAlert
              text={errorMsg}
              open={errorDelete}
              setOpen={setErrorDelete}
              severity="error"
            />
          </div>
        </FadeComponent>
      ) : (
        <LoadingPage text={"Chargement..."} />
      )}
    </div>
  );
};

export default AbonnementUser;
