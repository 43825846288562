import React, { Suspense, useEffect, useState } from "react";
import "../styles/Login.scss";
import {
  Alert,
  Box,
  CardMedia,
  Collapse,
  FormHelperText,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import { greenOlive, imagePath } from "../utils/utils";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

import "../styles/Footer.scss";
import { Footer } from "./Footer";
import { NavLink, useNavigate } from "react-router-dom";
import { hasAuthentication, login } from "../services/Auth";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import FadeComponent from "./FadeComponent";
import { useTranslation } from "react-i18next";
import ShowPassword from "./ShowPassword";
import myGa from "../Google-Analytics/myGa";
import { useSelector } from "react-redux";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailHasError, setEmailHasError] = useState(false);
  const [pwdHasError, setPwdHasError] = useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgPwd, setErrorMsgPwd] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [userNotFound, setUserNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {referral} = useSelector((state) => state.referral);


  useEffect(() => {
    hasAuthentication() && navigate("/manage/menu/");
    myGa();
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleConnect = () => {
    if (contentEmptyField()) {
      if (email === "") {
        setEmailHasError(true);
        setErrorMsgEmail("loginpage_error_required");
      }

      if (password === "") {
        setPwdHasError(true);
        setErrorMsgPwd("loginpage_error_required");
      }
    } else {
      setLoading(true);

      login({ email, password })
        .then((res) => {
          if (res) {
            navigate("/manage");
          }
          setUserNotFound(!res);
          setLoading(false);
        })
        .catch((e) => {
          setUserNotFound(true);
          setErrorMsg(e.response?.data?.error || "Server error !");
          setLoading(false);
        });
    }
  };

  const contentEmptyField = () => {
    return email === "" || password === "";
  };

  return (
    <FadeComponent>
      <div>
        <Box display={"flex"} className={"login"}>
          <Box className="imageBox"></Box>
          <Box className="form">
            <Stack gap={6}>
              <CardMedia
                component={"img"}
                className="logo"
                image={`${imagePath}/orestoLogo.png`}
              />
              <CardMedia
                component={"img"}
                className="logo2"
                image={`${imagePath}/oresto.png`}
              />

              <Stack gap={6} className="formContent">
                <Collapse in={userNotFound}>
                  <Alert severity="error">
                    {/* Email ou mot de passe incorrect ! */}
                    {errorMsg}
                  </Alert>
                </Collapse>

                <FormControl className="field" variant="outlined">
                  <InputLabel color="warning" htmlFor="email">
                    {t("email_address_label")}*
                  </InputLabel>

                  <Input
                    value={email}
                    onChange={(e) => {
                      setEmailHasError(false);
                      setEmail(e.target.value);
                      setUserNotFound(false);
                    }}
                    color="warning"
                    id="email"
                    type={"email"}
                    label="email"
                    error={emailHasError}
                  />
                  <FormHelperText id="component-error-text">
                    {" "}
                    {emailHasError && t(errorMsgEmail)}{" "}
                  </FormHelperText>
                </FormControl>

                <Stack gap={2}>
                  <FormControl className="field" variant="outlined">
                    <InputLabel
                      color="warning"
                      htmlFor="standard-adornment-password"
                    >
                      {t("password_label")}*
                    </InputLabel>
                    <Input
                      error={pwdHasError}
                      color="warning"
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => {
                        setPwdHasError(false);
                        setPassword(e.target.value);
                        setUserNotFound(false);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <ShowPassword
                            show={showPassword}
                            setShow={handleClickShowPassword}
                          />
                        </InputAdornment>
                      }
                      label="Password"
                    />
                    <FormHelperText id="component-error-text">
                      {" "}
                      {pwdHasError && t(errorMsgPwd)}{" "}
                    </FormHelperText>
                  </FormControl>

                  <div
                    onClick={() => {
                      navigate("/manager/reset-password");
                    }}
                    style={{ textAlign: "right" }}
                  >
                    <Typography
                      className="forgotPassword"
                      variant="caption"
                      align="right"
                      color={greenOlive}
                    >
                      {t("loginpage_password_forgot")}
                    </Typography>
                  </div>
                </Stack>

                <Box>
                  <Stack gap={2}>
                    <div
                      onClick={() => {
                        if (!loading) {
                          handleConnect();
                        }
                      }}
                      style={{ backgroundColor: loading && "lightgray" }}
                      className="connexion"
                    >
                      {loading ? <LoaderThreeDot /> : t("loginpage_login_btn")}
                    </div>
                    <Typography variant="caption" color="text.secondary">
                      {t("loginpage_not_member")}{" "}
                      <NavLink
                        style={{ textDecoration: "none" }}
                        to={`/manager/menu/signIn/${referral ? referral : ""}`}
                      >
                        <span className="inscription">
                          {t("loginpage_inscrivez_vous")}
                        </span>
                      </NavLink>
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Box>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </FadeComponent>
  );
};

export default Login;
