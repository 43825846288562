import { createSlice } from "@reduxjs/toolkit";

export const choiceSlice = createSlice({
  name: "choiceSlice",
  initialState: {
    choices: [],
  },
  reducers: {
    addChoice: (state, action) => {
      const {choice, sectionList} = action.payload;
      const ordreSections = sectionList;


      let temp = [];
      temp = [...state.choices, choice];

      temp.sort((a, b) => {
        const indexA = ordreSections.indexOf(a.section);
        const indexB = ordreSections.indexOf(b.section);
      
        return indexA - indexB;
      });

      state.choices = temp;

    },
    removeChoice: (state, action) => {
      const choice = action.payload;

      const index = state.choices.findIndex(c => c?.product._id === choice?.product._id);

      if (index !== -1)
        state.choices.splice(index, 1);
    },
  },
});

export const { addChoice, removeChoice } = choiceSlice.actions;
