import React, { useState } from "react";
import "../styles/MenuCreationHome.scss";
import { Close, Done } from "@mui/icons-material";
import FadeComponent from "./FadeComponent";
import { Button, Drawer, TextField, useMediaQuery } from "@mui/material";
import { AlertInfo, TemporaryAlert } from "./PagesUpdate";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import { useDispatch, useSelector } from "react-redux";
import { createMenu, getMenu } from "../API/api";
import { setMenu } from "../redux/slice/restaurant.slice";
import { useTranslation } from "react-i18next";
import { consoleLog } from "../utils/utils";

const MenuCreationHome = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const mobileTabletteScreen = useMediaQuery("(max-width:898px)");
  const [label, setLabel] = useState("");
  const dispatch = useDispatch();
  const { restaurant } = useSelector((state) => state.infosRestaurant);
  const { t } = useTranslation();

  const handleCreateMenu = () => {
    setLoading(true);

    createMenu({ label, restaurantId: restaurant._id })
      .then((res) => {
        getMenu(restaurant._id)
          .then((res) => {
            dispatch(setMenu(res.data));
            setLoading(false);
            setOpen(false);
          })
          .catch((e) => {
            consoleLog("error get menu : ", e);
            setLoading(false);
            setErrorMsg(e.response.data.error);
            setError(true);
          });
      })
      .catch((e) => {
        consoleLog("error creation menu : ", e);
        setLoading(false);
        setErrorMsg(e.response.data.error);
        setError(true);
      });
  };

  return (
    <FadeComponent>
      <div className="menuCreationHome">
        <div className="title"> {t("home_label")} </div>
        <div className="desc">
        {t("menu_home_title")}
        </div>
        <div className="bloc">
          <div className="blocCreate section">
            <h4 className="title">{t("menu_home_creation_title")}</h4>
            <div className="desc">
            {t("menu_home_creation_subtitle")}
            </div>
            <div onClick={() => setOpen(true)} className="button">
            {t("button_begin")}
            </div>
            <Drawer
              PaperProps={{
                style: {
                  width: mobileTabletteScreen ? "100%" : "50%",
                },
              }}
              anchor="right"
              open={open}
              onClose={() => setOpen(false)}
            >
              <div
                style={{
                  padding: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: "larger",
                    }}
                  >
                    {t("update_menu_title")}
                  </div>
                  <div onClick={() => setOpen(false)}>
                    <Close />
                  </div>
                </div>

                <AlertInfo
                  text={
                    <div>
                      {t("update_menu_info")}
                    </div>
                  }
                />
                <TextField
                  label={t("update_menu_input_label")}
                  color="warning"
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                />

                {loading ? (
                  <LoaderThreeDot />
                ) : (
                  <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    {" "}
                    <Button
                      onClick={handleCreateMenu}
                      disabled={label === "" || loading}
                      color="warning"
                    >
                      {t("button_create")}
                    </Button>
                    <Button
                      onClick={handleCreateMenu}
                      disabled={label !== "" || loading}
                      color="warning"
                    >
                      {t("button_ignore")}
                    </Button>
                  </div>
                )}

                <TemporaryAlert
                  text={
                    errorMsg ||
                    "An error has occurred! Check your internet connection!"
                  }
                  severity="error"
                  open={error}
                  setOpen={setError}
                />
              </div>
            </Drawer>
          </div>
          <div className="blocConfig section">
            <div className="title">{t("menu_home_step_title")}</div>
            <div className="instructions">
              <div className="item">
                <div>
                  <Done color="warning" />
                </div>
                <div>{t("menu_home_step_create")}</div>
              </div>
              <div className="item">
                <div>
                  <Done color="warning" />
                </div>
                <div>{t("menu_home_step_update")}</div>
              </div>
              <div className="item">
                <div>
                  <Done color="warning" />
                </div>
                <div>
                {t("menu_home_step_subscription")}
                </div>
              </div>
              <div className="item">
                <div>
                  <Done color="warning" />
                </div>
                <div>{t("menu_home_step_active")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <h5>{t("menu_home_inspiration_title")}</h5>
          <div className="desc">
          {t("menu_home_inspiration_1")}{" "}
            <a
              href="https://www.oresto.company/menu/6497fd81e3cfe9daa193d0d4"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {t("menu_home_inspiration_2")}{" "}
            </a>
            {t("menu_home_inspiration_3")}
          </div>
        </div>
      </div>
    </FadeComponent>
  );
};

export default MenuCreationHome;
