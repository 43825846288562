import React from "react";
import { MDBFooter } from "mdb-react-ui-kit";

const FooterBase = () => {
  return (
    <MDBFooter className="bg-dark text-center text-white">
      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.4)", fontSize: 12 }}
      >

        © 2023 Copyright:
        <a
          className="text-white"
          style={{ textDecoration: "none" }}
          href="https://instagram.com/orestoworld"
        >
          {" O'resto"}
        </a>
        {" - All rights reserved"}
      </div>
    </MDBFooter>
  );
};

export default FooterBase;
