import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectWithCheckBoxes({
  items,
  setValues,
  width = "100%",
  valuesSelected = [],
  setValuesSelected,
}) {
  const [selectedValues, setSelectedValues] = React.useState(valuesSelected);
  const { t } = useTranslation();

  useEffect(() => {
    setValuesSelected(extractAllergenIds(selectedValues, items));
  }, [selectedValues, items]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedValues(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setValues(typeof value === "string" ? value.split(",") : value);
  };

  function extractAllergenIds(allergenNames, allergenList) {
    return allergenNames.map((name) => {
      const allergen = allergenList.find((a) => a.name === name);
      return allergen ? allergen._id : null;
    });
  }

  return (
    <div>
      <FormControl color="warning" sx={{ width: width }}>
        <InputLabel id="demo-multiple-checkbox-label">{t("allergen_label")}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<OutlinedInput label={t("allergen_label")} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          color="warning"
        >
          {items.map((i) => (
            <MenuItem key={i?._id} value={i?.name}>
              <Checkbox
                color="warning"
                checked={selectedValues.indexOf(i?.name) > -1}
              />
              <ListItemText primary={i?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
