import { createSlice } from "@reduxjs/toolkit";

export const referralCodeSlice = createSlice({
  name: "referralCodeSlice",
  initialState: {
    referral: undefined,
  },
  reducers: {
    setReferralCode: (state, action) => {
      state.referral = action.payload;
    },

   
  },
});

export const { setReferralCode } = referralCodeSlice.actions;
