import React, { useEffect, useState } from "react";
import "../styles/MenuCart.scss";
import { useParams } from "react-router";
import { getMenu } from "../API/api";
import { Box, Container, Divider, Typography } from "@mui/material";
import SearchBar from "./SearchBar";
import { useDispatch } from "react-redux";
import { setProducts } from "../redux/slice/product.slice";
import VerticalListProduct from "./VerticalListProduct";
import NavBar from "./NavBar";
import FooterBase from "./FooterBase";
import "../styles/Footer.scss";
import FooterIcon from "./FooterIcon";
import LoaderName from "./Loaders/LoaderName";
import FadeComponent from "./FadeComponent";
import { Section } from "./Section";
import { consoleLog } from "../utils/utils";

const MenuCart = () => {
  let { restaurantId } = useParams();
  const [menu, setMenu] = useState({});
 
  const [selectedSection, setSelectedSection] = useState();
  const [producsToShow, setProductsToShow] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (restaurantId) {
      setLoading(true);

      getMenu(restaurantId, true)
        .then((res) => {
          setMenu(res.data);
          setLoading(false);
          if (res?.data?.sections?.length > 0) {
            const products = getProductFromMenu(res.data);
            dispatch(setProducts(products));
          }
        })
        .catch((e) => {
          consoleLog("error menu : ", e);
          setLoading(false);
        });
    }
  }, [restaurantId, dispatch]);

  const getProductFromMenu = (menu) => {
    let products = [];
    const listSections = [];

    for (const section of menu?.sections) {
      listSections.push(section?.label);
      for (const category of section.categories) {
        for (const product of category.products) {
          products.push(product);
        }
      }
    }

    setSectionList(listSections);

    return products;
  };


  return (
    <FadeComponent>
      {!loading ? (
        <div className="menu">
          <NavBar />
          {restaurantId && menu ? (
            <div>
              <div className="restoBloc">
                <div>
                  <img src={menu?.restaurant?.logo} alt="logo restaurant" />
                </div>
                <div className="restoName">
                  <span>{menu?.restaurant?.name}</span>
                </div>
                {(menu?.isActive || menu?.testMode) && (
                  <SearchBar setProducts={setProductsToShow} />
                )}
              </div>
              {menu?.isActive || menu?.testMode ? (
                producsToShow?.length === 0 ? (
                  <Container className="menuContainer">
                    <h1 className="titleMenu"> {menu.label} </h1>
                    <div>
                      {menu.sections?.map((section, key) => {
                        return (
                          <div className="section" key={key}>
                            <Section
                              sectionList={sectionList}
                              keyProps={key}
                              className="sectionTitle"
                              section={section}
                              menu={menu}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Container>
                ) : (
                  <Container>
                    <Typography
                      variant="subTitle1"
                      color="text.secondary"
                      component="h2"
                      align="left"
                      paddingBottom={2}
                      textTransform={"uppercase"}
                    >
                      Résultat
                    </Typography>
                    <Divider color="orange" />
                    <VerticalListProduct
                      sectionList={sectionList}
                      section={selectedSection?.label}
                      products={producsToShow}
                      country={menu?.restaurant?.country}
                    />
                  </Container>
                )
              ) : (
                <Typography
                  variant="subTitle1"
                  color="text.secondary"
                  component="h2"
                  padding={2}
                  fontWeight={"200"}
                  height={"70vh"}
                >
                  Ce menu est actuellement inaccessible. Revenez plus tard !
                </Typography>
              )}
            </div>
          ) : (
            <Box
              sx={{
                height: "90vh",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subTitle1"
                color="text.secondary"
                component="h2"
                padding={2}
                textTransform={"uppercase"}
                fontWeight={"200"}
                justifyContent={"center"}
              >
                Oups !! Impossible d'afficher le Menu pour le moment !
              </Typography>
            </Box>
          )}
          <div className="footer mt-2">
            <FooterIcon />
            <FooterBase />
          </div>
        </div>
      ) : (
        <div className="loaderSection">
          <LoaderName name={"O'resto"} />
        </div>
      )}
    </FadeComponent>
  );
};

export default MenuCart;
