import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
//import Home from "../components/Home";
// const MonComposantDiffere = lazy(() => import('./MonComposantDiffere.js'));
import MenuCart from "../components/MenuCart";
import { SecurizedElement } from "./SecurizedElement";
import EMenuPage from "../components/E-MenuPage";

import MenuCreaction from "../components/MenuCreation";
// const MenuCreaction = lazy(() => import("../components/MenuCreation"));
import ProfilUser from "../components/ProfilUser";
// const ProfilUser = lazy(() => import("../components/ProfilUser"));
import AbonnementUser from "../components/AbonnementUser";
// const AbonnementUser = lazy(() => import("../components/AbonnementUser"));
import QrCodeManage from "../components/QrCodeManage";
// const QrCodeManage = lazy(() => import("../components/QrCodeManage"));
import Shop from "../components/Shop";
// const Shop = lazy(() => import("../components/Shop"));
import SuccessPage from "../components/SuccessPage";
// const SuccessPage = lazy(() => import("../components/SuccessPage"));
import Dashbord from "../components/Dashbord";
// const Dashbord = lazy(() => import("../components/Dashbord"));
import ResetPasswordPage from "../components/ResetPasswordPage";

import MenuManagerHome from "../components/MenuManagerHome";
// const MenuManagerHome = lazy(() => import("../components/MenuManagerHome"));
// const EMenuPage = lazy(() => import("../components/E-MenuPage"));
import Tarifs from "../components/Tarifs";
// const Tarifs = lazy(() => import("../components/Tarifs"));
import Login from "../components/Login";
// const Login = lazy(() => import("../components/Login"));
import Inscription from "../components/Inscription";
// const Inscription = lazy(() => import("../components/Inscription"));

// const ResetPasswordPage = lazy(() => import("../components/ResetPasswordPage"));
import UpdatePasswordPage from "../components/UpdatePasswordPage";
// const UpdatePasswordPage = lazy(() =>
//   import("../components/UpdatePasswordPage")
// );

// const MenuCart = lazy(() => import("../components/MenuCart"));

const RouteApp = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path={"/menu/:restaurantId"} element={<MenuCart />} />
        {/* <Route path="/payment" element={<Checkout />} /> */}
        <Route
          path={"/manage"}
          element={<SecurizedElement element={<Dashbord />} />}
        >
          <Route
            path={"menu"}
            element={<SecurizedElement element={<MenuCreaction />} />}
          />
          <Route
            path={"profil"}
            element={<SecurizedElement element={<ProfilUser />} />}
          />
          <Route
            path={"abonnement"}
            element={<SecurizedElement element={<AbonnementUser />} />}
          />
          <Route
            path={"qrcode"}
            element={<SecurizedElement element={<QrCodeManage />} />}
          />
          <Route
            path={"shop"}
            element={<SecurizedElement element={<Shop />} />}
          />
          <Route path="" element={<Navigate to={"menu"} />} />
        </Route>
        <Route exact path={"/manager"} element={<MenuManagerHome />}>
          <Route path="menu" element={<EMenuPage />} />
          {/* <Route path="reservations" element={<ReservationPage />} /> */}
          <Route path="pricing" element={<Tarifs />} />
          <Route path="menu/login" element={<Login />} />
          <Route path="menu/signIn/:referralCode?" element={<Inscription />} />
          <Route path="reset-password/:code?" element={<ResetPasswordPage />} />
          <Route path="reset-password/reset" element={<UpdatePasswordPage />} />
          <Route
            path="success-subscription"
            element={<SuccessPage message={"Inscription réussie !"} />}
          />
          <Route path="" element={<Navigate to={"menu"} />} />
        </Route>
        <Route path="*" element={<Navigate to={"/manager"} />} />
      </Routes>
    </Router>
  );
};

export default RouteApp;
