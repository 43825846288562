import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  Box,
  InputAdornment,
  Stack,
  Typography,
  FormHelperText,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  LocationCity,
  LocationOn,
  MarkunreadMailbox,
  RestaurantMenu,
} from "@mui/icons-material";
import "../styles/dataForm.scss";
import CountryInfo from "./CountryInfo";
import { countries, imagePath } from "../utils/utils";
import FadeComponent from "./FadeComponent";

const RestaurantDatasForm = forwardRef(
  (
    { handleComplet, setContainsError, setRestauranDatas, restaurantDatas, t },
    ref
  ) => {
    const [infoCountry, setInfoCountry] = useState();
    // const [countryInfoGeo, setCountryInfoGeo] = useState();
    const [detectedCountry, setDetectedCountry] = useState(() =>
      countries.find((c) => c.label === restaurantDatas?.country)
    );
    const [restaurantName, setRestaurantName] = useState(restaurantDatas?.name);
    const [address, setAddress] = useState(restaurantDatas?.address);
    const [city, setCity] = useState(restaurantDatas?.city);
    const [zipCode, setZipCode] = useState(restaurantDatas?.zipCode);

    const [addressHasError, setAddressHasError] = useState(false);
    const [cityHasError, setCityHasError] = useState(false);
    const [restoNameHasError, setRestoNameHasError] = useState(false);
    const [zipCodeHasError, setZipCodeHasError] = useState(false);

    const [addressMsgError, setAddressMsgError] = useState("");
    const [cityMsgError, setCityMsgError] = useState("");
    const [restoNameMsgError, setRestoNameMsgError] = useState("");
    const [zipCodeMsgError, setZipCodeMsgError] = useState("");

    const errorPresent = () => {
      let error = false;

      if (!restaurantName) {
        error = true;
        setRestoNameHasError(error);
        setRestoNameMsgError("loginpage_error_required");
      }

      if (!city) {
        error = true;
        setCityHasError(error);
        setCityMsgError("loginpage_error_required");
      }

      if (!address) {
        error = true;
        setAddressHasError(error);
        setAddressMsgError("loginpage_error_required");
      }

      if (!zipCode) {
        error = true;
        setZipCodeHasError(error);
        setZipCodeMsgError("loginpage_error_required");
      }

      return error;
    };

    const checkForErrors = () => {
      const isError = errorPresent(); // Remplacez cela par votre logique de vérification

      if (isError) {
        setContainsError(true);
        return false;
      } else {
        // Aucune erreur
        setRestauranDatas({
          name: restaurantName,
          country: infoCountry?.label,
          city,
          address,
          zipCode,
          logo: `${imagePath}/default-logo-resto.png`,
          certified: false,
        });

        return true;
      }
    };

    // Exposer la fonction de vérification d'erreur au parent via la ref
    useImperativeHandle(ref, () => ({
      checkForErrors,
    }));

    return (
      <FadeComponent>
        <div className="container formular">
          <Typography align="left" variant="h5">
            {t("register_restaurant_form_title")}
          </Typography>
          <Box className="form" component="form" noValidate autoComplete="on">
            <Stack>
              <TextField
                error={restoNameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RestaurantMenu />
                    </InputAdornment>
                  ),
                }}
                id="outlined-basic"
                label={`${t("register_restaurant_name_label")}*`}
                variant="outlined"
                color="warning"
                type="text"
                className="item"
                value={restaurantName}
                onChange={(e) => setRestaurantName(e.target.value)}
              />
              <FormHelperText className="errorText" id="component-error-text">
                {restoNameHasError && t(restoNameMsgError)}
              </FormHelperText>

              <div className="item">
                <CountryInfo
                  defaultValue={detectedCountry}
                  setInfoCountry={setInfoCountry}
                  selectCountryName={true}
                />
              </div>

              <TextField
                error={addressHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                id="outlined-basic"
                label={`${t("address_label")}*`}
                variant="outlined"
                color="warning"
                type="text"
                className="item"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <FormHelperText className="errorText" id="component-error-text">
                {addressHasError && t(addressMsgError)}
              </FormHelperText>

              <TextField
                error={cityHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCity />
                    </InputAdornment>
                  ),
                }}
                id="outlined-basic"
                label={`${t("city_label")}*`}
                variant="outlined"
                color="warning"
                type="text"
                className="item"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <FormHelperText className="errorText" id="component-error-text">
                {cityHasError && t(cityMsgError)}
              </FormHelperText>

              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MarkunreadMailbox />
                    </InputAdornment>
                  ),
                }}
                label={`${t("zipcode_label")}*`}
                variant="outlined"
                color="warning"
                type="text"
                className="item"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />

              <FormHelperText className="errorText" id="component-error-text">
                {zipCodeHasError && t(zipCodeMsgError)}
              </FormHelperText>

              <Typography
                fontStyle={"italic"}
                paddingTop={2}
                textAlign={"left"}
                color={"GrayText"}
                variant="caption"
              >
                {t("instruction_required_field")}
              </Typography>
            </Stack>
            <div onClick={handleComplet} className="validateBtn">
              {t("register_next_btn")}
            </div>
          </Box>
        </div>
      </FadeComponent>
    );
  }
);

export default RestaurantDatasForm;
