import React from "react";
import { MDBFooter, MDBIcon } from "mdb-react-ui-kit";


const FooterIcon = () => {
  return (
    <MDBFooter className="bg-dark text-center text-white">
      <div className="container p-4 pb-0">
        <section>
          <a
            className="btn btn-link btn-floating text-light m-1"
            href="https://www.facebook.com/profile.php?id=100093522254480"
            role="button"
            data-mdb-ripple-color="light"
          >
            <i className="fab fa-facebook-f"></i>
          </a>

          <a
            className="btn btn-link btn-floating text-light m-1"
            href="https://www.twitter.com/Oresto__"
            role="button"
            data-mdb-ripple-color="light"
          >
            <i className="fab fa-twitter"></i>
          </a>

          <a
            className="btn btn-link btn-floating text-light m-1"
            href="https://instagram.com/oresto.company"
            role="button"
            data-mdb-ripple-color="light"
          >
            <i className="fab fa-instagram"></i>
          </a>

          {/* <a
            className="btn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"
          >
            <i className="fab fa-linkedin-in"></i>
          </a> */}
          

          <a
            className="btn btn-link btn-floating text-light m-1"
            data-mdb-ripple-color="light"
            href="mailto:contact@oresto.company"
            role="button"
          >
            <MDBIcon fas icon="envelope" />
          </a>
        </section>
      </div>
    </MDBFooter>
  );
};

export default FooterIcon;
