import React from "react";
import "../styles/ChoiceItem.scss";
import { euro } from "../utils/utils";
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { removeChoice } from "../redux/slice/choice.slice";

const ChoiceItem = ({ choice }) => {
    const dispatch = useDispatch();

  return (
    <div className="item">
      <div> {choice?.product.label} </div>
      <div className="price">
        <div>
          {choice?.product.price} {euro}
        </div>
        <div onClick={() => dispatch(removeChoice(choice))}><Close /></div>
      </div>
    </div>
  );
};

export default ChoiceItem;
