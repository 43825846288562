import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import "../styles/dataForm.scss";
import { Box, Stack, Typography, Collapse, Alert } from "@mui/material";

import { useNavigate } from "react-router";
import { registration, sendMail } from "../API/api";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import FadeComponent from "./FadeComponent";
import { countries } from "../utils/utils";

const ValidationInscriptionForm = forwardRef(
  ({ handleComplet, personnalDatas, restaurantDatas, t, referral }, ref) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const navigate = useNavigate();
    const [code, setCode] = useState("");

    useEffect(() => {
      const c = countries.find((c) => c.label === personnalDatas?.country);
      setCode(c?.phone);
    }, [personnalDatas?.country]);

    const handleFinalize = () => {
      setLoading(true);
      setError(undefined);
   
      const {
        hasGoodSize,
        hasUpper,
        hasLower,
        hasDigit,
        hasSpecialChar,
        ...user
      } = personnalDatas;

      restaurantDatas.referral = referral || "ORESTO";

      registration({ user: user, restaurant: restaurantDatas })
        .then((res) => {
          if (res.data) {
            setLoading(false);
            setError(undefined);

            //envoi de l'email de bienvenu
            sendMail({
              to: personnalDatas?.email,
              templateId: "d-855539e2343a4e548e241ced535c7f46",
              data: { restoName: restaurantDatas?.name },
            })
              .then((res) => {
                handleComplet();

                navigate("/manager/success-subscription");
              })
              .catch((e) => {
                console.error(e);
              });
          } else setLoading(false);
        })
        .catch((e) => {
          console.error("error registration : ", e);
          setError(
            e?.response?.data?.error ||
              "Une erreur s'est produite ! veuillez réssayer"
          );
          setLoading(false);
        });

      return true;
    };

    const checkForErrors = () => {
      const isError = !handleFinalize();
      return !isError;
    };

    // Exposer la fonction de vérification d'erreur au parent via la ref
    useImperativeHandle(ref, () => ({
      checkForErrors,
    }));

    return (
      <FadeComponent>
        <div className=" formular container">
          <Typography align="left" variant="h4">
            {t("register_validation_recap_title")}
          </Typography>
          <Box className="formular2">
            <Box className="section">
              <Typography className="title" align="left" variant="h6">
                {t("register_personalform_title")}
              </Typography>
              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("email_label")} :
                </Typography>
                <Typography align="right" variant="subtitle1">
                  {personnalDatas?.email}
                </Typography>
              </Stack>

              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("phone_number_label")} :
                </Typography>
                <Typography align="right" variant="subtitle1">
                  {`(+${code})${personnalDatas?.phone}`}
                </Typography>
              </Stack>

              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("address_label")} :
                </Typography>
                <Typography className="data" align="right" variant="subtitle1">
                  {personnalDatas?.address}
                </Typography>
              </Stack>

              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("city_label")} :
                </Typography>
                <Typography className="data" align="right" variant="subtitle1">
                  {personnalDatas?.city}
                </Typography>
              </Stack>
              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("zipcode_label")} :
                </Typography>
                <Typography align="right" variant="subtitle1">
                  {personnalDatas?.zipCode}
                </Typography>
              </Stack>
              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("country_label")} :
                </Typography>
                <Typography align="right" variant="subtitle1">
                  {personnalDatas?.country}
                </Typography>
              </Stack>
            </Box>
            <Box className="section">
              <Typography className="title" align="left" variant="h6">
                {t("register_validation_recap_restaurant_form_title")}
              </Typography>

              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("lastname_label")} :
                </Typography>
                <Typography align="right" variant="subtitle1">
                  {restaurantDatas?.name}
                </Typography>
              </Stack>

              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("address_label")} :
                </Typography>
                <Typography className="data" align="right" variant="subtitle1">
                  {restaurantDatas?.address}
                </Typography>
              </Stack>

              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("city_label")} :
                </Typography>
                <Typography className="data" align="right" variant="subtitle1">
                  {restaurantDatas?.city}
                </Typography>
              </Stack>
              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("zipcode_label")} :
                </Typography>
                <Typography align="right" variant="subtitle1">
                  {restaurantDatas?.zipCode}
                </Typography>
              </Stack>
              <Stack className="line" direction={"row"} gap={3}>
                <Typography align="left" variant="overline">
                  {t("country_label")} :
                </Typography>
                <Typography align="right" variant="subtitle1">
                  {restaurantDatas?.country}
                </Typography>
              </Stack>
            </Box>

            <div
              style={{ backgroundColor: loading && "lightgray" }}
              onClick={() => {
                if (!loading) handleFinalize();
              }}
              className="validateBtn"
            >
              {loading ? <LoaderThreeDot /> : t("register_final_btn")}
            </div>
            <Collapse in={error !== undefined}>
              <Alert sx={{ marginTop: "1rem" }} severity="error">
                {" "}
                {error}{" "}
              </Alert>
            </Collapse>
          </Box>
        </div>
      </FadeComponent>
    );
  }
);

export default ValidationInscriptionForm;
