import "./App.css";
import RouteApp from "./routes/RouteApp";
import { store } from "./redux/store";
import { Provider } from "react-redux";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";


function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n} defaultNS={'translation'}>
        <div className="App">
          <RouteApp />
        </div>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
