import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

export default function ShowPassword({ show, setShow }) {
  return (
    <div>
      <IconButton
        className="eyesButton"
        aria-label="toggle password visibility"
        onClick={setShow}
        edge="end"
      >
        {show ? (
          <VisibilityOff fontSize="small" />
        ) : (
          <Visibility fontSize="small" />
        )}
      </IconButton>
    </div>
  );
}
