import { AddAPhoto } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../styles/InputFile.scss";
import { greenOlive } from "../utils/utils";

const InputFile = ({
  setSelectedFile, //fonction callback transmise par le composant parent pour récuperer le fichier
  startIcon = <AddAPhoto />, //mettre à null si vous ne souhaitez pas que ceet icon apparaisse
  actionText, //le texte qui apparait à coté startIcon
  text = "Aucune photo sélectionnée", //texte qui affiche le nom du fichier. mettre à null si pas souhaité
  action, //fonction callback à exécuter après que le fichier soit récupéré (facultatif)
  fileDeleted, //indique si le fichier a été supprimé
  showFileName = true,
  setFileDeleted,
  color = greenOlive,
}) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState();


  useEffect(() => {
    if (fileDeleted) {
      setFile(undefined);
      setSelectedFile(undefined);
      setFileDeleted(false);
    }
  }, [fileDeleted]);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Déclenche le clic sur l'élément d'entrée de type fichier
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      setSelectedFile && setSelectedFile(selectedFile);
      action && action(selectedFile);
    }
  };
  return (
    <div className="inputFile">
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => handleFileChange(e)}
      />
      <div className="blocBtn">
        <div id="btn">
          <Button
            onClick={handleButtonClick}
            color="inherit"
            sx={{ color: color }}
            startIcon={startIcon}
          >
            {" "}
            {actionText}{" "}
          </Button>
        </div>
        {showFileName && <div id="fileName">{file ? file.name : text}</div>}
      </div>
    </div>
  );
};

export default InputFile;
