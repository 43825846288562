import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { hasAuthentication } from "../services/Auth";

export const SecurizedElement = ({ element }) => {
  const navigate = useNavigate();

  useEffect(() => {
    !hasAuthentication() && navigate("/manager/menu/login");
  }, [navigate]);

  return (
    <div>
      {element}
    </div>
  );
};
