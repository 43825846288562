import React from "react";
import NavBarManager from "./NavBarManager";
import { Outlet } from "react-router";

const MenuManagerHome = () => {
  return (
    <div>
      <NavBarManager />
      <div style={{marginTop: '6rem'}}>
        
        <Outlet />
      </div>
    </div>
  );
};

export default MenuManagerHome;
