import React, { useEffect, useState } from "react";
import { TranslateSharp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Button, Fade, Menu, MenuItem } from "@mui/material";

const languages = ["fr","en",  "de", "nl"];

export const LanguageSelect = ({ text, setOpen }) => {
  const [value, setValue] = useState();
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (lang) => {
    setAnchorEl(null);
    setValue(lang);
  };

  useEffect(() => {
    if (value) {
        i18n.changeLanguage(value);
        localStorage.setItem("preferedLanguage", value);
    } else {
      const preferedLang = localStorage.getItem("preferedLanguage");
      if (preferedLang) {
        i18n.changeLanguage(preferedLang);
        setValue(preferedLang);
      } else i18n.changeLanguage("en");
    }
  }, [value, i18n]);

  return (
    <div style={{margin : '1rem'}}>
      <Button
        // startIcon={<TranslateSharp />}
        color="inherit"
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<TranslateSharp />}
      >
         <span style={{paddingLeft : "1rem"}}>{text}</span>{" "}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(value)}
        TransitionComponent={Fade}
      >
        {languages.map((l, k) => {
          return (
            <MenuItem
              key={k}
              onClick={() => {
                handleClose(l);
                setOpen && setOpen(false);
              }}
            >
              <div>
                <img
                  loading="lazy"
                  width="18"
                  srcSet={`https://flagcdn.com/w40/${
                    l === "en" ? "gb" : l.toLowerCase()
                  }.png 2x`}
                  src={`https://flagcdn.com/w20/${
                    l === "en" ? "gb" : l.toLowerCase()
                  }.png`}
                  alt=""
                />{" "}
                {l}
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
