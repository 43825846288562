import React, { useEffect, useRef, useState } from "react";
import FadeComponent from "./FadeComponent";
import QRCode from "react-qr-code";
import "../styles/QrCode.scss";
import {
  Avatar,
  Button,
  CardMedia,
  Chip,
  Collapse,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Add, Check, CopyAll, Copyright, Download, FileCopy, GppGood, NavigateNext, Star } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { baseUrlWeb, consoleLog, imagePath } from "../utils/utils";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router";
import ColorPicker from "./ColorPicker";
import { useTranslation } from "react-i18next";
import myGa from "../Google-Analytics/myGa";
import { TemporaryAlert } from "./PagesUpdate";

const colors = [
  "#d49100",
  "rgba(15, 11, 11, 0.994)",
  // "rgb(162, 162, 219)",
  // "#d35400",
  // "#e67e22",
  // "#8e44ad",
  // "#27ae60",
  // "#2c3e50",
];

const QrCodeManage = () => {
  const { restaurant, menu } = useSelector((state) => state.infosRestaurant);
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const mobileTabletteScreen = useMediaQuery("(max-width:898px)");
  const qrcodeRef = useRef(null);
  const [image, setImage] = useState();
  const [hasMenu, setHasMenu] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const [reference, setReference] = useState(null);
  const { t } = useTranslation();
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    setHasMenu(menu !== undefined && menu !== null);
    myGa();

    setReference(ref);

    if (reference?.current) {
      reference.current.ownerDocument.body.scrollTop = 200;
    }

    getImage();
  }, [menu, reference]);

  const getQrCodeAsImage = async () => {
    const qrcodeElement = qrcodeRef.current;

    if (qrcodeElement) {
      const canvas = await html2canvas(qrcodeElement);

      const dataURL = canvas.toDataURL("image/png");
      return dataURL;
    }

    return qrcodeElement;
  };

  const getImage = () => {
    const img = new Image();
    img.src = restaurant?.logo;

    img.onload = () => {
      setImage(img);
    };
  };

  const handleCopyClick = (contentToCopy) => {
    navigator.clipboard
      .writeText(contentToCopy)
      .then(() => {
        // Gestion de la réussite de la copie
        console.log("Contenu copié dans le presse-papier !");
        setCopy(true);
      })
      .catch((error) => {
        // Gestion de l'erreur lors de la copie
        console.error("Erreur lors de la copie dans le presse-papier :", error);
        setCopy(false);
      });
  };

  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleDownloadQRCode = () => {
    getQrCodeAsImage()
      .then((imageData) => {
        const blob = dataURLToBlob(imageData);
        const blobURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobURL;
        downloadLink.download = `qrcode-${restaurant?.name}-oresto.png`;
        downloadLink.click();
        URL.revokeObjectURL(blobURL);
      })
      .catch((e) => {
        consoleLog("Error downloading QR code: ", e);
      });
  };

  return (
    <FadeComponent>
      <div className="qrcode">
        {hasMenu ? (
          <div onClick={() => open && setOpen(false)} className="contenu">
            {!mobileTabletteScreen && (
              <div ref={qrcodeRef} id="qrcode" className="code">
                <div className="title">
                  {/* <div>
                    <Avatar src={`${image?.src}`} />
                  </div> */}
                  {t("qrcode_page_title")}
                </div>
                <QRCode
                  value={`https://www.oresto.company/menu/${restaurant?._id}`}
                  level="H"
                  xlinkTitle={`Menu ${restaurant?.name}`}
                  fgColor={selectedColor}
                />

                <div className="logo">
                  <img src={`${imagePath}/orestoLogo.png`} alt="logo" />
                </div>
              </div>
            )}
            <div className="blocContent">
              <div className="title">{t("qrcode_page_subTitle")}</div>
              <div className="desc">{t("qrcode_page_description")}</div>

              {/* <div className="indic">couleur</div> */}

              {mobileTabletteScreen && (
                <div ref={qrcodeRef} className="code">
                  <div className="inner">
                    <div className="title">
                      {/* <div>
                        <Avatar src={`${image?.src}`} />
                      </div> */}
                      <div>{t("qrcode_title")}</div>
                      <div className="restoName"> @{restaurant?.name} </div>
                    </div>
                    <QRCode
                      size={200}
                      value={`https://www.oresto.company/menu/${restaurant?._id}`}
                      level="H"
                      xlinkTitle={`Menu ${restaurant?.name}`}
                      fgColor={selectedColor}
                    />
                    <div className="logo">
                      <img src={`${imagePath}/orestoLogo.png`} alt="logo" />
                    </div>
                  </div>
                </div>
              )}

              <div ref={ref} className="colors">
                {colors.map((c, key) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedColor(c);
                      }}
                      key={key}
                      className="color"
                    >
                      <Avatar
                        sx={{
                          backgroundColor: c,
                          color: c,
                          boxShadow:
                            selectedColor === c &&
                            "5px 6px 7px rgba(0, 0, 0, 0.5);",
                        }}
                      />
                    </div>
                  );
                })}
                <div className="">
                  <ColorPicker
                    isOpen={open}
                    setIsOpen={setOpen}
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                  />
                </div>
              </div>
              <Button
                onClick={() => handleDownloadQRCode()}
                className="button"
                sx={{ padding: 0, display: "flex", marginTop: "2rem" }}
                endIcon={<Download />}
                color="warning"
              >
                {t("qrcode_download")}
              </Button>
              <div>
                <Tooltip title={copy ? "Copied" : "Click to copy"}>
                  <Chip
                    label={
                      copy
                        ? "Copied !"
                        : `${baseUrlWeb}/menu/${restaurant?._id}`
                    }
                    icon={copy ? <Check /> : <FileCopy />}
                    color={copy ? "success" : "default"}
                    variant={copy ? "outlined" : "filled"}
                    sx={{ padding: "1rem", width: "90%", marginTop: "1rem" }}
                    clickable
                    onClick={() =>
                      handleCopyClick(`${baseUrlWeb}/menu/${restaurant?._id}`)
                    }
                  />
                </Tooltip>
                <Collapse in={false}>
                  <div style={{ width: "40%", margin: "1rem" }}>
                    <TemporaryAlert
                      text={"Copied"}
                      open={copy}
                      setOpen={setCopy}
                      severity="success"
                      delay={2000}
                    />
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ margin: "0 auto" }}>
            <div
              style={{
                fontSize: "xx-large",
                textAlign: "center",
                fontWeight: "100",
              }}
              className="title"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: mobileTabletteScreen ? "50%" : "20%",
                    height: mobileTabletteScreen ? "50%" : "20%",
                  }}
                  image={`${imagePath}/qr-code.png`}
                />
              </div>
              {t("qrcode_page_empty_title")}
              <div>
                <Button
                  onClick={() => navigate("/manage/menu")}
                  endIcon={<NavigateNext />}
                  color="warning"
                >
                  {" "}
                  {t("qrcode_button_create")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </FadeComponent>
  );
};

export default QrCodeManage;
