import React from "react";
import "../styles/VerticalProductList.scss";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import { FixedSizeList } from "react-window";
import VerticalItem from "./VerticalItem";
import { useMediaQuery } from "@mui/material";

const VerticalListProduct = ({ products, section, sectionList, country }) => {
  const maxWidth564 = useMediaQuery("(max-width:564px)");

  // lors de la recherche, la section du produit est introuvable

  function renderRow(props) {
    const { index, style } = props;
    const product = products[index];

    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <VerticalItem
          sectionList={sectionList}
          section={section}
          product={product}
          country={country}
        />
      </ListItem>
    );
  }
  //140
  return (
    <div id="verticalList">
      <Box
        sx={{
          width: "100%",

          bgcolor: "background.paper",
        }}
      >
        <FixedSizeList
          className="liste"
          height={800}
          width={"100%"}
          style={{ minHeight: "100vh" }}
          itemSize={maxWidth564 ? 140 : 200}
          itemCount={products?.length}
          overscanCount={4}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
    </div>
  );
};

export default VerticalListProduct;
