import React from 'react';
import VerticalListProduct from './VerticalListProduct';

const CategoryPage = ({category, section, sectionList}) => {
    return (
        <div className='category'>
            <VerticalListProduct sectionList={sectionList} section={section} products={category?.products} />
        </div>
    );
};

export default CategoryPage;