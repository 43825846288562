import React, { useEffect, useRef, useState } from "react";
import "../styles/MenuCart.scss";
import FadeComponent from "./FadeComponent";
import Choice from "./Choice";
import { consoleLog, greenOlive, imagePath } from "../utils/utils";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Drawer,
  Fab,
  Typography,
  Zoom,
  useMediaQuery,
} from "@mui/material";
import SearchBar from "./SearchBar";
import {
  AddBoxRounded,
  Edit,
  KeyboardArrowUpSharp,
  Lightbulb,
  RemoveRedEye,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import { getConnectedUser, hasAuthentication } from "../services/Auth";
import { useDispatch, useSelector } from "react-redux";
import HorizontalProductList from "./HorizontalProductList";
import {
  AlertInfo,
  CategoryUpdatePage,
  MenuLabelUpdate,
  SectionAdd,
  SectionUpdate,
  TemporaryAlert,
} from "./PagesUpdate";
import LoadingPage from "./LoadingPage";
import MenuCreationHome from "./MenuCreationHome";
import { Accordions } from "./Accordion";
import { getSubscription, updateMenu } from "../API/api";
import { plans } from "./Tarifs";
import {
  changeActiveMenu,
  menuActivationStatus,
} from "../redux/slice/restaurant.slice";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import { useTranslation } from "react-i18next";
import myGa from "../Google-Analytics/myGa";

export const LIMITS_PRODUCT = 30;

const MenuCreaction = () => {
  const [productsToShow, setProductsToShow] = useState([]);
  const widthEdit = 20;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCateg, setOpenCateg] = useState(false);

  const [openUpdateSec, setOpenUpdateSec] = useState(false);
  const [openUpdateLabelMenu, setOpenUpdateLabelMenu] = useState(false);
  const [restaurantData, setRestaurant] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [selectedCateg, setSelectedCateg] = useState();

  const [menuData, setMenuData] = useState();
  const mobileTabletteScreen = useMediaQuery("(max-width:898px)");
  const { restaurant, menu, readyToActivate } = useSelector(
    (state) => state.infosRestaurant
  );

  const dispatch = useDispatch();

  const [trialling, setTrialling] = useState(false);
  const [active, setActive] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [plan, setPlan] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const ref = useRef();
  const [showButton, setShowButton] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    myGa();
    if (!hasAuthentication()) {
      navigate("/manager/menu/login");
      return;
    }

    const userData = getConnectedUser();

    getSubscription({ email: userData?.email })
      .then((res) => {
        const sub = res.data;
        setSubscription(sub);

        setActive(
          sub.status === "active" &&
            sub.pause_collection?.behavior !== "keep_as_draft"
        );

        setCancelled(
          sub.status === "canceled" ||
            sub.cancellation_details?.reason === "cancellation_requested" ||
            sub.pause_collection?.behavior === "keep_as_draft"
        );

        setTrialling(
          sub.status === "trialing" &&
            sub.pause_collection?.behavior !== "keep_as_draft"
        );

        const p = plans?.find((p) => p?.plan === res.data.plan?.id);

        setPlan(p);
      })
      .catch((e) => {
        setSubscription(undefined);
      });

    setRestaurant(restaurant);
    setMenuData(menu);
    setIsActive(menu?.isActive);
  }, [menu, restaurant]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 2) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const gotToTop = () => {
    ref.current.scrollTop = 0;
  };


  const setMenu = (isActive) => {
    setLoading(true);
    updateMenu({
      oldLabel: menuData?.label,
      restaurantId: menuData?.restaurantId,
      isActive,
    })
      .then((res) => {
        setIsActive(isActive);
        dispatch(changeActiveMenu(isActive));
        setLoading(false);

        setError(false);
      })
      .catch((e) => {
        consoleLog("error active menu : ", e);
        setError(true);
        setLoading(false);
        setMsgError(e?.response?.data?.error || "Something get wrong !");
      });
  };

  const handleUpdateActiveMenu = (isActive) => {
    if (!subscription) return;

    let readyToActivate = false;

    const valid =
      new Date(subscription?.current_period_end * 1000) >= Date.now();

    if (
      subscription?.product?.name.toLowerCase() === "basic" ||
      subscription?.product?.name.toLowerCase() === "welcome plan"
    ) {
      readyToActivate = subscription && valid;
    } else if (subscription?.product?.name.toLowerCase() === "vip") {
      readyToActivate = subscription;
    }

    dispatch(menuActivationStatus(readyToActivate));

    if (readyToActivate) {
      setMenu(isActive);
    } else {
      menu?.isActive && setMenu(false);
    }
  };

  const editBtn = () => {
    return <Edit sx={{ width: widthEdit }} />;
  };
  const addBtn = () => {
    return <AddBoxRounded sx={{ width: widthEdit }} />;
  };

  return (
    <div style={{ maxHeight: "100vh" }}>
      {menu === undefined ? (
        <div>
          <LoadingPage />
        </div>
      ) : (
        <FadeComponent>
          <div>
            {menu === null ? (
              <MenuCreationHome />
            ) : (
              <div ref={ref} id="head" className="menuCreation">
                <div className="header">
                  <div>
                    {!mobileTabletteScreen && (
                      <>
                        <div className="title">{t("menu_creation_title")}</div>
                        <div className="desc">
                          {t("menu_creation_description")}
                          <span
                            onClick={() => navigate("/manage/qrcode")}
                            style={{
                              color: greenOlive,
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            {t("qrcode_label")}
                          </span>
                          .
                          {subscription && (
                            <div className="blocActiveMenu">
                              {`${
                                !isActive
                                  ? `${t("menu_creation_active_menu")}`
                                  : `${t("menu_creation_desactivate_menu")}`
                              }`}
                              <div>
                                {loading ? (
                                  <LoaderThreeDot />
                                ) : (
                                  <Button
                                    disabled={!readyToActivate}
                                    onClick={() =>
                                      handleUpdateActiveMenu(!isActive)
                                    }
                                    style={{
                                      color:
                                        subscription && readyToActivate
                                          ? greenOlive
                                          : "darkgray",
                                    }}
                                    color="inherit"
                                  >
                                    {`${
                                      !isActive
                                        ? `${t(
                                            "menu_creation_button_activate"
                                          )}`
                                        : `${t(
                                            "menu_creation_button_deactivate"
                                          )}`
                                    }`}
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}
                          {!subscription && (
                            <Accordions
                              title={
                                <div>
                                  <Lightbulb
                                    sx={{
                                      marginRight: "1rem",
                                      color: greenOlive,
                                    }}
                                  />
                                  {t(
                                    "menu_creation_activate_menu_instruction_title"
                                  )}
                                </div>
                              }
                              body={
                                <span>
                                  {t(
                                    "menu_creation_activate_menu_instruction_step_1"
                                  )}{" "}
                                  <span
                                    onClick={() =>
                                      navigate("/manage/abonnement")
                                    }
                                    style={{
                                      color: greenOlive,
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {t(
                                      "menu_creation_activate_menu_instruction_step_2"
                                    )}
                                  </span>
                                </span>
                              }
                            />
                          )}
                        </div>
                      </>
                    )}

                    {mobileTabletteScreen && (
                      <>
                        <Accordions
                          title={
                            <div className="title">
                              {t("menu_creation_title")}
                            </div>
                          }
                          body={
                            <div className="desc">
                              {t("menu_creation_description")}{" "}
                              <span
                                onClick={() => navigate("/manage/qrcode")}
                                style={{
                                  color: greenOlive,
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                {t("qrcode_label")}
                              </span>
                              .
                              {subscription && (
                                <div className="blocActiveMenu">
                                  {`${
                                    !isActive
                                      ? `${t("menu_creation_active_menu")}`
                                      : `${t("menu_creation_desactivate_menu")}`
                                  }`}
                                  <div>
                                    {loading ? (
                                      <LoaderThreeDot />
                                    ) : (
                                      <Button
                                        disabled={!readyToActivate}
                                        onClick={() =>
                                          handleUpdateActiveMenu(!isActive)
                                        }
                                        style={{
                                          color:
                                            subscription && readyToActivate
                                              ? greenOlive
                                              : "darkgray",
                                        }}
                                        color="inherit"
                                      >
                                        {`${
                                          !isActive
                                            ? `${t(
                                                "menu_creation_button_activate"
                                              )}`
                                            : `${t(
                                                "menu_creation_button_deactivate"
                                              )}`
                                        }`}
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                        />
                        {!subscription && (
                          <Accordions
                            title={
                              <div>
                                <Lightbulb
                                  sx={{
                                    marginRight: "1rem",
                                    color: greenOlive,
                                  }}
                                />
                                {t(
                                  "menu_creation_activate_menu_instruction_title"
                                )}
                              </div>
                            }
                            body={
                              <span>
                                {t(
                                  "menu_creation_activate_menu_instruction_step_1"
                                )}{" "}
                                <span
                                  onClick={() => navigate("/manage/abonnement")}
                                  style={{
                                    color: greenOlive,
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  {t(
                                    "menu_creation_activate_menu_instruction_step_2"
                                  )}
                                </span>
                              </span>
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                  <TemporaryAlert
                    open={error}
                    setOpen={setError}
                    text={msgError}
                    severity="error"
                  />
                </div>

                <Collapse
                  in={subscription && !readyToActivate}
                  sx={{ padding: "1rem" }}
                >
                  <AlertInfo text={t("menu_creation_menu_disable_alert")} />
                </Collapse>

                <div className="contenu">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Chip
                      sx={{ textTransform: "uppercase" }}
                      label={`${t("menu_creation_total")} : ${menu?.total}`}
                    />

                    <Chip
                      sx={{ textTransform: "uppercase", cursor: "pointer" }}
                      label={`Online Preview`}
                      icon={<RemoveRedEye />}
                      color="secondary"
                      onClick={() => window.open("/menu/" + restaurant?._id, "_blank")}
                    />
                  </div>
                  <div className="navBar">
                    <Box>
                      <img
                        alt="logo"
                        className="logo"
                        src={`${imagePath}/orestoLogo.png`}
                      />
                    </Box>
                  </div>

                  <div className="restoBloc">
                    <div className="bloc">
                      <div className="name">
                        <div className="logoResto">
                          <Avatar
                            sx={{ width: "100%", height: "100%" }}
                            src={`${imagePath}/default-logo-resto.png`}
                            srcSet={restaurantData?.logo}
                          />
                        </div>

                        <div className="restoName">
                          <div>{restaurantData?.name || "resto name"}</div>
                        </div>
                      </div>
                    </div>
                    <div className="searchBar">
                      <SearchBar
                        configMode={true}
                        setProducts={setProductsToShow}
                      />
                    </div>
                  </div>

                  <div className="menuContainer">
                    <div className="bloc2">
                      <div className="titleMenu">
                        {`${
                          menuData?.label ||
                          t("menu_creation_default_menu_name")
                        }`}
                      </div>
                      <div
                        onClick={() => setOpenUpdateLabelMenu(true)}
                        className="edit"
                      >
                        {editBtn()}
                      </div>
                    </div>

                    <div className="sections">
                      {menuData?.sections?.map((s, k) => {
                        return (
                          <div key={k}>
                            <div className=" section">
                              <div
                                onClick={() => {
                                  setSelectedSection(s);
                                  setOpenUpdateSec(true);
                                }}
                                className="sectionTitle bloc2"
                              >
                                <div>{s.label}</div>
                                <div> {editBtn()} </div>
                              </div>

                              <div className="categories">
                                {s?.categories.map((c, key) => {
                                  return (
                                    <div key={key}>
                                      <Divider color="black" />
                                      <div className="category">
                                        <div className="blocTitre">
                                          <h4 className="categoryTitle bloc2">
                                            <div>{c?.label}</div>
                                            <div
                                              onClick={() => {
                                                setSelectedCateg(c);
                                                setOpenCateg(true);
                                              }}
                                            >
                                              <Typography
                                                color={"GrayText"}
                                                variant="caption"
                                                sx={{ cursor: "pointer" }}
                                              >
                                                {t("update_label")}
                                              </Typography>
                                            </div>
                                            <Drawer
                                              PaperProps={{
                                                style: {
                                                  width: mobileTabletteScreen
                                                    ? "100%"
                                                    : "50%",
                                                },
                                              }}
                                              anchor="right"
                                              open={openCateg}
                                              onClose={() =>
                                                setOpenCateg(false)
                                              }
                                            >
                                              <CategoryUpdatePage
                                                category={selectedCateg}
                                                setOpen={setOpenCateg}
                                              />
                                            </Drawer>
                                          </h4>
                                        </div>
                                      </div>
                                      <HorizontalProductList
                                        categoryId={c?._id}
                                        section={s?.label}
                                        configMode={true}
                                        products={c?.products}
                                        country={restaurant?.country}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <Drawer
                              PaperProps={{
                                style: {
                                  width: mobileTabletteScreen ? "100%" : "50%",
                                },
                              }}
                              anchor="right"
                              open={openUpdateSec}
                              onClose={() => setOpenUpdateSec(false)}
                            >
                              <SectionUpdate
                                section={selectedSection}
                                setOpen={setOpenUpdateSec}
                              />
                            </Drawer>
                          </div>
                        );
                      })}
                      <div className=" sectionEdit">
                        <div
                          onClick={() => setOpen(true)}
                          className="sectionTitle bloc2"
                        >
                          <div> {t("menu_creation_add_section")} </div>
                          <div> {addBtn()} </div>
                        </div>
                        <Drawer
                          PaperProps={{
                            style: {
                              width: mobileTabletteScreen ? "100%" : "50%",
                            },
                          }}
                          anchor="right"
                          open={open}
                          onClose={() => setOpen(!open)}
                        >
                          <SectionAdd setOpen={setOpen} />
                        </Drawer>

                        <Drawer
                          PaperProps={{
                            style: {
                              width: mobileTabletteScreen ? "100%" : "50%",
                            },
                          }}
                          anchor="right"
                          open={openUpdateLabelMenu}
                          onClose={() => setOpen(!openUpdateLabelMenu)}
                        >
                          <MenuLabelUpdate
                            oldLabel={menuData?.label}
                            setOpen={setOpenUpdateLabelMenu}
                            restaurantId={menu?.restaurantId}
                            id={menu?._id}
                          />
                        </Drawer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Zoom timeout={1000} in={menu}>
              <Fab
                onClick={() => gotToTop()}
                size={mobileTabletteScreen ? "medium" : "small"}
                sx={{
                  position: "absolute",
                  bottom: mobileTabletteScreen ? "6rem" : "1rem",
                  right: "1rem",
                }}
                color="warning"
              >
                <KeyboardArrowUpSharp />
              </Fab>
            </Zoom>
          </div>
        </FadeComponent>
      )}
    </div>
  );
};

export default MenuCreaction;
