import React from "react";
import "../../styles/buttons/FluidButton.scss";

const FluidButton = ({ action, text }) => {

  return (
    <div
    onClick={action}
      style={{ padding: "1rem", marginTop: "2rem" }}
      className="buttonFluid"
    >
      {text}
    </div>
  );
};

export default FluidButton;
