import React, { useEffect, useState } from "react";
import FadeComponent from "./FadeComponent";
import "../styles/ProfilUser.scss";
import { useNavigate } from "react-router";
import { getConnectedUser, hasAuthentication } from "../services/Auth";
import { useDispatch, useSelector } from "react-redux";
import {
  getRestaurantByUserId,
  getUserById,
  removeImage,
  updateRestaurant,
  updateUser,
  uploadImage,
} from "../API/api";
import {
  Close,
  Home,
  LocationCity,
  LocationOn,
  Mail,
  MarkunreadMailbox,
  NavigateNext,
  Phone,
  PhotoCamera,
  RestaurantMenu,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  FormHelperText,
  SwipeableDrawer,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { StyledTab, StyledTabs, TabPanel, a11yProps } from "./Tarifs";
import InputFile from "./InputFile";
import LoaderThreeDot from "./Loaders/LoaderThreeDot";
import { grey } from "@mui/material/colors";
import {
  consoleLog,
  countries,
  extractFileNameFromUrl,
  extraireNumeroTelephone,
  validPhoneNumber,
  validateNumber,
} from "../utils/utils";
import { TemporaryAlert } from "./PagesUpdate";
import { setRestaurant } from "../redux/slice/restaurant.slice";
import { setUser } from "../redux/slice/user.slice";
import CountryInfo from "./CountryInfo";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import myGa from "../Google-Analytics/myGa";

export const Puller = styled(Box)(({ theme }) => ({
  width: 50,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[700] : grey[900],
  borderRadius: 3,
  // position: "absolute",
  // top: 8,
  // left: "calc(50% - 15px)",
}));

const ProfilUser = () => {
  const { restaurant } = useSelector((state) => state.infosRestaurant);
  const { user } = useSelector((state) => state.user);
  const [loadinRestaurant, setLoadingRestaurant] = useState(false);
  const [errorUser, setErrorUser] = useState(false);
  const [errorRestaurant, setErrorRestaurant] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [successUser, setSuccessUser] = useState(false);
  const [successRestaurant, setSuccessRestaurant] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openNumber, setOpenNumber] = useState(false);
  // const [openMail, setOpenMail] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [openCity, setOpenCity] = useState(false);
  const [openZipCode, setOpenZipCode] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);

  const [openName, setOpenName] = useState(false);
  const [openAddressRestaurant, setOpenAddressRestaurant] = useState(false);
  const [openCityRestaurant, setOpenCityRestaurant] = useState(false);
  const [openZipCodeRestaurant, setOpenZipCodeRestaurant] = useState(false);
  const [openCountryRestaurant, setOpenCountryRestaurant] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneWithoutCode, setPhoneWithoutCode] = useState("");
  const [phoneField, setPhoneField] = useState("");
  const [code, setCode] = useState("");
  // const [mail, setMail] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [zipCode, setZipCode] = useState();
  const [country, setCountry] = useState();
  const [infoCountry, setInfoCountry] = useState();

  const [restaurantName, setRestaurantName] = useState();
  const [addressRestaurant, setAddressRestaurant] = useState();
  const [cityRestaurant, setCityRestaurant] = useState();
  const [zipCodeRestaurant, setZipCodeRestaurant] = useState();
  const [countryRestaurant, setCountryRestaurant] = useState();
  const mobileTabletteScreen = useMediaQuery("(max-width:898px)");

  const [file, setFile] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [loadingImage, setLoadingImage] = useState(false);
  const [image, setImage] = useState(undefined);
  const [errorUpload, setErrorUpload] = useState(false);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    myGa();
    if (!hasAuthentication()) {
      navigate("/manager/menu/login");
      return;
    }

    const { id } = getConnectedUser();

    getUser({ id, callback: getRestaurant({ id }) });
  }, []);

  useEffect(() => {
    handleUploadImage(file);
  }, [file]);

  const handleUploadImage = (file) => {
    if (
      file &&
      `products/${file?.name}` !== extractFileNameFromUrl(restaurant?.logo)
    ) {
      setLoadingImage(true);

      const formData = new FormData();
      formData.append("image", file);

      uploadImage({
        formData,
      })
        .then((res) => {
          setImage(res.data);

          const newResto = { _id: restaurant?._id, logo: res.data };
          const logoResto = restaurant?.logo;

          //update photo restaurant
          updateRestaurant({ id: restaurant?._id, newResto })
            .then((result) => {
              dispatch(setRestaurant({ ...restaurant, logo: res.data }));
              setOpen(false);
              setLoadingImage(false);

              removeImage({
                fileName: extractFileNameFromUrl(logoResto),
              });
            })
            .catch((e) => {
              consoleLog("Error updating restaurant logo", e);
              setLoadingImage(false);
              setErrorUpload(true);
            });
        })
        .catch((e) => {
          consoleLog("Error uploading image", e);
          setLoadingImage(false);
          setErrorUpload(true);
        });
    }
  };

  const getUser = ({ id, callback }) => {
    setLoadingUser(true);
    getUserById({ id })
      .then((res) => {
        const user = res.data;
        // setUser(res.data);
        dispatch(setUser(user));
        setLoadingUser(false);
        setSuccessUser(true);

        setPhone(user?.phone);
        const country = countries.find((c) => c.label === user?.country);
        setCountry(country);

        const phoneWithoutCode = extraireNumeroTelephone(
          user?.phone,
          country?.phone
        );

        setPhoneWithoutCode(phoneWithoutCode);
        setPhoneField(phoneWithoutCode);
        setCode(country?.phone);

        setAddress(user?.address);
        setCity(user?.city);
        setZipCode(user?.zipCode);

        if (callback) {
          callback({ id });
        }
      })
      .catch((e) => {
        consoleLog("Error getting Personnals data", e);
        setLoadingUser(false);
        setErrorUser(true);
      });
  };

  const getRestaurant = ({ id }) => {
    setLoadingRestaurant(true);

    getRestaurantByUserId({ id })
      .then((res) => {
        const restaurant = res.data;
        // setRestaurant(restaurant);
        dispatch(setRestaurant(restaurant));
        setLoadingRestaurant(false);
        setSuccessRestaurant(true);

        setRestaurantName(restaurant?.name);
        setAddressRestaurant(restaurant?.address);
        setCityRestaurant(restaurant?.city);
        setZipCodeRestaurant(restaurant?.zipCode);
        setCountryRestaurant(restaurant?.country);
      })
      .catch((e) => {
        consoleLog("Error getting Restaurant data", e);
        setLoadingRestaurant(false);
        setErrorRestaurant(true);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleUpdateNumber = () => {
  //   setLoading(true);
  //   setLoading(false);
  // };

  const handleUpdateRestaurant = ({ prop, value, setOpen, setError }) => {
    setLoading(true);

    updateRestaurant({
      id: restaurant?._id,
      newResto: {
        _id: restaurant?._id,
        [prop]: value,
      },
    })
      .then((result) => {
        setLoading(false);

        if (result.data.result.acknowledged) {
          dispatch(setRestaurant({ ...restaurant, [prop]: value }));
          setOpen(false);
        } else {
          setError(true);
        }
      })
      .catch((e) => {
        consoleLog(`error update ${prop} restaurant : `, e);
        setLoading(false);
        setError(true);
      });
  };

  const handleUpdateUser = ({ prop, value, setOpen }) => {
    setLoading(true);

    updateUser({
      id: user?._id,
      newUser: {
        [prop]: value,
      },
      oldEmail: user?.email,
    })
      .then((result) => {
        setLoading(false);

        if (result.data.result.acknowledged) {
          dispatch(setUser({ ...user, [prop]: value }));
          setOpen(false);
        } else {
          setErrorUser(true);
        }
      })
      .catch((e) => {
        consoleLog(`error update ${prop} restaurant : `, e);
        setLoading(false);
        setErrorUser(true);
      });
  };

  const handleUpdatePhoneCode = (code, number) => {
    setCode(code);
    setPhone(
      validPhoneNumber({
        number: "+" + code + number,
        codeCountry: infoCountry?.code,
      })
    );
  };

  const formular = ({
    oldValue,
    action,
    title,
    value,
    setValue,
    setOpen,
    error,
    setError,
    labelType = "standard",
  }) => {
    return (
      <div className="form">
        <div className="head">
          <div className="title"> {title} </div>
          <div style={{ cursor: "pointer" }} onClick={() => setOpen(false)}>
            <Close />
          </div>
        </div>

        {labelType === "standard" && (
          <TextField
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
            color="warning"
            variant="outlined"
          />
        )}

        {labelType === "country" && (
          <CountryInfo
            defaultValue={countries?.find((c) => c.label === oldValue)}
            setInfoCountry={setValue}
            selectCountryName={true}
            //  setValue={setNumber}
          />
        )}

        {labelType === "phone" && (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ width: "40%" }} variant="body">
                <CountryInfo
                  defaultValue={countries?.find(
                    (c) => c.label === country?.label
                  )}
                  setInfoCountry={setInfoCountry}
                  selectCountryName={false}
                  setValue={(e) => handleUpdatePhoneCode(e, value)}
                />
              </Typography>
              <TextField
                fullWidth
                error={!phone}
                variant="outlined"
                color="warning"
                className="item"
                value={value}
                onChange={(e) => {
                  const value = validateNumber(e.target.value);
                  setValue(value);
                  setPhone(
                    validPhoneNumber({
                      number: "+" + code + value,
                      codeCountry: infoCountry?.code,
                    })
                  );
                }}
                placeholder="(ex : 423445556)"
              />
            </div>
            <FormHelperText className="errorText">
              {!phone && t("bad_phone_format")}
            </FormHelperText>
          </div>
        )}

        <Box mt={3}>
          {!loading ? (
            <Button
              onClick={() => {
                action && action();
              }}
              sx={{ alignItems: "left" }}
              disabled={
                !value ||
                value === oldValue ||
                (labelType === "phone" && !phone)
              }
              color="warning"
            >
              {t("button_update")}
            </Button>
          ) : (
            <LoaderThreeDot />
          )}
        </Box>
        <div>
          <TemporaryAlert
            open={error}
            setOpen={setError}
            text={"Update failed! Try aigain"}
            severity="error"
          />
        </div>
      </div>
    );
  };

  return (
    <FadeComponent>
      <div className="profil">
        <div className="bloc">
          <div className="header">
            <div
              style={{
                background: `url(${restaurant?.logo}) no-repeat center/cover`,
                height: mobileTabletteScreen ? "30vh" : "50vh",
              }}
              className="bg"
            >
              {" "}
            </div>

            <div className="info">
              <div
                style={{ position: "relative" }}
                onClick={() => setOpen(!open)}
              >
                <Avatar
                  sx={{
                    width: 100,
                    backgroundColor: "white",
                    height: 100,
                    color: "white",
                    cursor: "pointer",
                    border: "1px solid gray",
                    // padding : "1px"
                  }}
                  src={restaurant?.logo}
                />
                <PhotoCamera
                  sx={{
                    position: "absolute",
                    bottom: "-3px",
                    left: "12px",
                    width: 22,
                    cursor: "pointer",
                  }}
                />
              </div>
              <div className="infoText">
                <div id="restoName">
                  {restaurant?.name}{" "}
                  {restaurant?.certified && (
                    <span
                      style={{ color: "#405DE6" }}
                      className="material-symbols-outlined"
                    >
                      verified
                    </span>
                  )}
                </div>
                <div id="email">{user?.email} </div>
                <div id="restoCity">
                  <LocationOn /> <span> {restaurant?.city} </span>
                </div>
              </div>

              <SwipeableDrawer
                swipeAreaWidth={4}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                anchor={mobileTabletteScreen ? "bottom" : "right"}
                disableSwipeToOpen={false}
                PaperProps={{
                  style: {
                    height: mobileTabletteScreen ? "30%" : "100%",
                    borderTopLeftRadius: mobileTabletteScreen && "2rem",
                    borderTopRightRadius: mobileTabletteScreen && "2rem",
                    padding: "1rem",
                  },
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Puller />
                  </div>
                  <div
                    style={{
                      alignSelf: "flex-start",
                      paddingTop: "1rem",
                      display: "flex",
                      // width : '100%',
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <InputFile
                      setSelectedFile={setFile}
                      actionText={t("product_page_add_photo")}
                      showFileName={false}
                      color="black"
                    />
                    {loadingImage && <LoaderThreeDot />}
                  </div>

                  <TemporaryAlert
                    open={errorUpload}
                    setOpen={setErrorUpload}
                    text={"download image error"}
                    severity="error"
                  />
                </div>
              </SwipeableDrawer>
            </div>
          </div>

          <div className="body">
            <div className="informations">
              <h2 className="title"> {t("profil_page_info_title")} </h2>
              <Divider color="black" />
              <StyledTabs value={value} onChange={handleChange} centered>
                <StyledTab
                  {...a11yProps("personnelles")}
                  key={0}
                  label={t("profil_page_info_perso")}
                />
                <StyledTab
                  {...a11yProps("restaurant")}
                  key={1}
                  label={t("profil_page_info_resto")}
                />
              </StyledTabs>
              <TabPanel value={value} index={0}>
                <div className="personnal">
                  <Item
                    label={t("email_address_label")}
                    data={user?.email}
                    editable={false}
                    icon={<Mail />}
                  />
                  <Item
                    onClick={() => setOpenNumber(true)}
                    data={user?.phone}
                    icon={<Phone />}
                    label={t("phone_number_label")}
                  />
                  <Item
                    onClick={() => setOpenAddress(true)}
                    icon={<LocationOn />}
                    data={user?.address}
                    label={t("address_label")}
                  />
                  <Item
                    onClick={() => setOpenCity(true)}
                    icon={<LocationCity />}
                    data={user?.city}
                    label={t("city_label")}
                  />
                  <Item
                    onClick={() => setOpenCountry(true)}
                    icon={<Home />}
                    data={user?.country}
                    label={t("country_label")}
                  />
                  <Item
                    onClick={() => setOpenZipCode(true)}
                    icon={<MarkunreadMailbox />}
                    data={user?.zipCode}
                    label={t("zipcode_label")}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="restoData">
                  <Item
                    onClick={() => setOpenName(true)}
                    data={restaurant?.name}
                    icon={<RestaurantMenu />}
                    label={t("restaurant_name_label")}
                  />

                  <Item
                    onClick={() => setOpenAddressRestaurant(true)}
                    icon={<LocationOn />}
                    data={restaurant?.address}
                    label={t("address_label")}
                  />

                  <Item
                    onClick={() => setOpenCityRestaurant(true)}
                    icon={<LocationCity />}
                    data={restaurant?.city}
                    label={t("city_label")}
                  />

                  <Item
                    onClick={() => setOpenCountryRestaurant(true)}
                    icon={<Home />}
                    data={restaurant?.country}
                    label={t("country_label")}
                  />

                  <Item
                    onClick={() => setOpenZipCodeRestaurant(true)}
                    icon={<MarkunreadMailbox />}
                    data={restaurant?.zipCode}
                    label={t("zipcode_label")}
                  />
                </div>
              </TabPanel>
              <DrawerUpdate
                open={openNumber}
                setOpen={setOpenNumber}
                children={() =>
                  formular({
                    label: "Phone number",
                    title: `${t("update_label")} ${t("phone_number_label")}`,
                    value: phoneField,
                    setValue: setPhoneField,
                    action: () =>
                      handleUpdateUser({
                        prop: "phone",
                        value: phone,
                        setOpen: setOpenNumber,
                      }),
                    setOpen: setOpenNumber,
                    error: errorUser,
                    setError: setErrorUser,
                    oldValue: phoneWithoutCode,
                    labelType: "phone",
                  })
                }
              />
              <DrawerUpdate
                open={openCity}
                setOpen={setOpenCity}
                children={() =>
                  formular({
                    label: "City",
                    title: `${t("update_label")} ${t("city_label")}`,
                    value: city,
                    setValue: setCity,
                    action: () =>
                      handleUpdateUser({
                        prop: "city",
                        value: city,
                        setOpen: setOpenCity,
                      }),
                    setOpen: setOpenCity,
                    error: errorUser,
                    setError: setErrorUser,
                    oldValue: user?.city,
                  })
                }
              />
              <DrawerUpdate
                open={openAddress}
                setOpen={setOpenAddress}
                children={() =>
                  formular({
                    label: "Address",
                    title: `${t("update_label")} ${t("address_label")}`,
                    value: address,
                    setValue: setAddress,
                    action: () =>
                      handleUpdateUser({
                        prop: "address",
                        value: address,
                        setOpen: setOpenAddress,
                      }),
                    setOpen: setOpenAddress,
                    error: errorUser,
                    setError: setErrorUser,
                    oldValue: user?.address,
                  })
                }
              />
              <DrawerUpdate
                open={openCountry}
                setOpen={setOpenCountry}
                children={() =>
                  formular({
                    label: "Country",
                    title: `${t("update_label")} ${t("country_label")}`,
                    value: country?.label,
                    setValue: setCountry,
                    action: () =>
                      handleUpdateUser({
                        prop: "country",
                        value: country?.label,
                        setOpen: setOpenCountry,
                      }),
                    setOpen: setOpenCountry,
                    error: errorUser,
                    setError: setErrorUser,
                    oldValue: user?.country,
                    labelType: "country",
                  })
                }
              />
              <DrawerUpdate
                open={openZipCode}
                setOpen={setOpenZipCode}
                children={() =>
                  formular({
                    label: "Zipcode",
                    title: `${t("update_label")} ${t("zipcode_label")}`,
                    value: zipCode,
                    setValue: setZipCode,
                    action: () =>
                      handleUpdateUser({
                        prop: "zipCode",
                        value: zipCode,
                        setOpen: setOpenZipCode,
                      }),
                    setOpen: setOpenZipCode,
                    error: errorUser,
                    setError: setErrorUser,
                    oldValue: user?.zipCode,
                  })
                }
              />

              {/* restaurant Drawer */}

              <DrawerUpdate
                open={openName}
                setOpen={setOpenName}
                children={() =>
                  formular({
                    label: "Name",
                    title: `${t("update_label")} ${t(
                      "register_restaurant_name_label"
                    )}`,
                    value: restaurantName,
                    setValue: setRestaurantName,
                    action: () =>
                      handleUpdateRestaurant({
                        prop: "name",
                        value: restaurantName,
                        setOpen: setOpenName,
                        setError: setErrorRestaurant,
                      }),
                    setOpen: setOpenName,
                    error: errorRestaurant,
                    setError: setErrorRestaurant,
                    oldValue: restaurant?.name,
                  })
                }
              />
              <DrawerUpdate
                open={openAddressRestaurant}
                setOpen={setOpenAddressRestaurant}
                children={() =>
                  formular({
                    label: "Address",
                    title: `${t("update_label")} ${t("address_label")}`,
                    value: addressRestaurant,
                    setValue: setAddressRestaurant,
                    action: () =>
                      handleUpdateRestaurant({
                        prop: "address",
                        value: addressRestaurant,
                        setOpen: setOpenAddressRestaurant,
                        setError: setErrorRestaurant,
                      }),
                    setOpen: setOpenAddressRestaurant,
                    error: errorRestaurant,
                    setError: setErrorRestaurant,
                    oldValue: restaurant?.address,
                  })
                }
              />
              <DrawerUpdate
                open={openCityRestaurant}
                setOpen={setOpenCityRestaurant}
                children={() =>
                  formular({
                    label: "City",
                    title: `${t("update_label")} ${t("city_label")}`,
                    value: cityRestaurant,
                    setValue: setCityRestaurant,
                    action: () =>
                      handleUpdateRestaurant({
                        prop: "city",
                        value: cityRestaurant,
                        setOpen: setOpenCityRestaurant,
                        setError: setErrorRestaurant,
                      }),
                    setOpen: setOpenCityRestaurant,
                    error: errorRestaurant,
                    setError: setErrorRestaurant,
                    oldValue: restaurant?.city,
                  })
                }
              />
              <DrawerUpdate
                open={openCountryRestaurant}
                setOpen={setOpenCountryRestaurant}
                children={() =>
                  formular({
                    label: "Country",
                    title: `${t("update_label")} ${t("country_label")}`,
                    value: countryRestaurant?.label,
                    setValue: setCountryRestaurant,
                    action: () =>
                      handleUpdateRestaurant({
                        prop: "country",
                        value: countryRestaurant?.label,
                        setOpen: setOpenCountryRestaurant,
                        setError: setErrorRestaurant,
                      }),
                    setOpen: setOpenCountryRestaurant,
                    error: errorRestaurant,
                    setError: setErrorRestaurant,
                    oldValue: restaurant?.country,
                    labelType: "country",
                  })
                }
              />
              <DrawerUpdate
                open={openZipCodeRestaurant}
                setOpen={setOpenZipCodeRestaurant}
                children={() =>
                  formular({
                    label: "ZipCode",
                    title: `${t("update_label")} ${t("zipcode_label")}`,
                    value: zipCodeRestaurant,
                    setValue: setZipCodeRestaurant,
                    action: () =>
                      handleUpdateRestaurant({
                        prop: "zipCode",
                        value: zipCodeRestaurant,
                        setOpen: setOpenZipCodeRestaurant,
                        setError: setErrorRestaurant,
                      }),
                    setOpen: setOpenZipCodeRestaurant,
                    error: errorRestaurant,
                    setError: setErrorRestaurant,
                    oldValue: restaurant?.zipCode,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </FadeComponent>
  );
};

const Item = ({ icon, data, editable = true, onClick, label = "label" }) => {
  return (
    <div onClick={onClick} className="item">
      <div className="datas">
        <div className="label"> {label} </div>
        <div className="data">
          <div>{data}</div>
          {editable && (
            <div className="nextBtn">
              <NavigateNext />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DrawerUpdate = ({ open, setOpen, children }) => {
  const mobileTabletteScreen = useMediaQuery("(max-width:898px)");

  return (
    <Drawer
      PaperProps={{
        style: {
          width: mobileTabletteScreen ? "100%" : "50%",
        },
      }}
      anchor="right"
      open={open}
      onClose={() => setOpen(!open)}
    >
      {children()}
    </Drawer>
  );
};

export default ProfilUser;
