import React from "react";
import "../../styles/LoadersStyle/LoaderName.scss";

const LoaderName = ({ name, size = "3rem" }) => {
  return (
    <div>
        
      <div className="loader">
        <span style={{fontSize : size}}> {name} </span>
        <span style={{fontSize : size}}>{name}</span>
      </div>
    </div>
  );
};

export default LoaderName;
