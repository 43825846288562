import React from "react";
import { MDBFooter} from "mdb-react-ui-kit";
import FooterBase from "./FooterBase";
import FooterIcon from "./FooterIcon";
import FooterLink from "./FooterLink";

export const Footer = () => {
  return (
    <MDBFooter>
      <FooterIcon />
      <FooterLink />
      <FooterBase />
    </MDBFooter>
  );
};
