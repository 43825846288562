import { createSlice } from "@reduxjs/toolkit";

export const restaurantSlice = createSlice({
  name: "restaurantSlice",
  initialState: {
    restaurant: undefined,
    menu: undefined,
    readyToActivate: false,
  },
  reducers: {
    setRestaurant: (state, action) => {
      state.restaurant = action.payload;
    },

    setMenu: (state, action) => {
      state.menu = action.payload;
    },

    changeActiveMenu: (state, action) => {
      state.menu.isActive = action.payload;
    },
    menuActivationStatus: (state, action) => {

      state.readyToActivate = action.payload;
    },
  },
});

export const {
  setRestaurant,
  setMenu,
  changeActiveMenu,
  menuActivationStatus,
} = restaurantSlice.actions;
