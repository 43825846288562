import React, { useEffect, useState } from "react";
import "../styles/NavBar.scss";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import { consoleLog, imagePath } from "../utils/utils";
import Choice from "./Choice";
import { useParams } from "react-router";
import { getRestaurantById } from "../API/api";
import { useSelector } from "react-redux";

const NavBar = () => {
  const { restaurantId } = useParams();
  const [restaurantData, setRestaurant] = useState(null);
  const { restaurant } = useSelector((state) => state.infosRestaurant);

  useEffect(() => {
    if (restaurantId) {
      getRestaurantById({ id: restaurantId })
        .then((res) => {
          setRestaurant(res.data);
        })
        .catch((err) => {
          consoleLog("Error fetching restaurant", err);
          setRestaurant(restaurant);
        });
    } else {
      setRestaurant(restaurant);
    }
  }, [restaurant, restaurantId]);

  return (
    <div className="navBar">
      <AppBar color="transparent" position="static">
        <Container maxWidth="xl">
          <Toolbar style={{ justifyContent: "space-between" }} disableGutters>
            <Box>
              <img
                alt="logo"
                className="logo"
                src={`${imagePath}/orestoLogo.png`}
              />
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open choices">
                <Choice country={restaurant?.country} />
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default NavBar;
