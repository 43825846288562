import { Avatar, Box, Chip } from "@mui/material";
import React, { useState } from "react";
import CarousselImage from "./CarousselImage";
import { euro, formaterPrix } from "../utils/utils";
import "../styles/ProductDetails.scss";
import { useDispatch } from "react-redux";
import { addChoice } from "../redux/slice/choice.slice";
import { Toast } from "react-bootstrap";

const ProductDetails = ({ product, section, sectionList }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  
  const addToChoice = () => {
    dispatch(addChoice({ choice: { product, section }, sectionList }));
    setShow(true);
    
  };

  return (
    <div className="product">
      {product ? (
        <Box>
          <CarousselImage
            //forList={false}
            indicators={true}
            controls={true}
            images={product.images}
            forDetail={true}
          />
          <div className="section">
            <div className="name"> {product.label} </div>
            <div className="price">
              {" "}
              {formaterPrix(product.price)} {euro}{" "}
            </div>
          </div>

          <div className="section">
            <div className="ingredients">
              <div className="title"> Ingrédients </div>
              <div className="liste">
                {product.ingredients.map((ingredient, k) => {
                  return (
                    <li key={k}>
                      {" "}
                      {ingredient}
                      {k + 1 >= product.ingredients?.length ? "" : ","}
                    </li>
                  );
                })}
              </div>
            </div>
          </div>

          {product.allergens.length > 0 && (
            <div className="section">
              <div className="title"> Allergènes </div>
              <div className="liste">
                {product.allergens.map((allergen, key) => {
                  return (
                    
                    <Chip
                      style={{ marginRight: 7 }}
                      key={key}
                      avatar={
                        
                        <Avatar
                          alt={`${allergen.name}`}
                          src={allergen?.icon}
                        />
                      }
                      label={`${allergen.name}`}
                      variant="outlined"
                    />
                  );
                })}
              </div>
            </div>
          )}
          <div className="blocToast">
            <Toast
              className="toast"
              onClose={() => setShow(false)}
              show={show}
              delay={1000}
              autohide
            >
              <Toast.Body className="body">{"+1"}</Toast.Body>
            </Toast>

            <div onClick={() => addToChoice()} className="button">
              {" "}
              ajouter comme choix{" "}
            </div>
          </div>
        </Box>
      ) : (
        <div>
          <h1>Impossible de charger le produit</h1>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
