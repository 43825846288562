import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Box,
  Collapse,
  FormHelperText,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  Done,
  Email,
  LocationCity,
  LocationOn,
  MarkunreadMailbox,
  Security,
} from "@mui/icons-material";
import "../styles/dataForm.scss";
import CountryInfo from "./CountryInfo";
import axios from "axios";
import {
  consoleLog,
  countries,
  getCountry,
  goodFormatEmail,
  goodFormatPwd,
  validateNumber,
} from "../utils/utils";
import FadeComponent from "./FadeComponent";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";
import { getUserByEmail } from "../API/api";
import ShowPassword from "./ShowPassword";
import { useDispatch } from "react-redux";
import { setReferralCode } from "../redux/slice/referralCode.slice";

const PersonalsDatasForm = forwardRef(
  (
    {
      handleComplet,
      setContainsError,
      setPersonnalDatas,
      personnalDatas,
      t,
      referral,
    },
    ref
  ) => {
    const [hasError, setHasError] = useState(true);
    const [number, setNumber] = useState();
    const [infoCountry, setInfoCountry] = useState();
    const [countryInfoGeo, setCountryInfoGeo] = useState();
    const [detectedCountry, setDetectedCountry] = useState(() =>
      countries.find((c) => c.label === personnalDatas?.country)
    );

    const [mail, setMail] = useState(personnalDatas?.email);
    const [password, setPassword] = useState(personnalDatas?.password);
    const [confirmPassword, setConfirmPassword] = useState(
      personnalDatas?.password
    );
    const [phone, setPhone] = useState(personnalDatas?.phone);
    const [address, setAddress] = useState(personnalDatas?.address);
    const [city, setCity] = useState(personnalDatas?.city);
    const [zipCode, setZipCode] = useState(personnalDatas?.zipCode);

    const [emailHasError, setEmailHasError] = useState(false);
    const [pwdHasError, setPwdHasError] = useState(false);
    const [countryHasError, setCountryHasError] = useState(false);
    const [phoneHasError, setPhoneHasError] = useState(false);
    const [confirmPwdHasError, setConfirmPwdHasError] = useState(false);
    const [confirmPwdMsgError, setConfirmPwdMsgError] = useState(
      "Passwords do not match"
    );

    const [emailMsgError, setEmailMsgError] = useState("");
    const [pwdMsgError, setPwdMsgError] = useState("");
    const [countryMsgError, setCountryMsgError] = useState("");
    const [phoneMsgError, setPhoneMsgError] = useState("");

    const [userExist, setUserExist] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [hasGoodSize, setHasGoodSize] = useState(personnalDatas?.hasGoodSize);
    const [hasUpper, setHasUpper] = useState(personnalDatas?.hasUpper);
    const [hasLower, setHasLower] = useState(personnalDatas?.hasLower);
    const [hasDigit, setHasDigit] = useState(personnalDatas?.hasDigit);
    const [hasSpecialChar, setHasSpecialChar] = useState(
      personnalDatas?.hasSpecialChar
    );
    const dispatch = useDispatch();
    const [referralCode, setReferral] = useState(referral);

    useEffect(() => {
      getUserLocation();
      setReferral(referral);
    }, [referral]);

    const validPhoneNumber = ({ number, codeCountry }) => {
      try {
        const phoneNumber = parsePhoneNumberFromString(number);

        if (
          phoneNumber &&
          isValidPhoneNumber(phoneNumber?.nationalNumber, codeCountry)
        ) {
          setPhone(phoneNumber.nationalNumber);

          return true;
        }
      } catch (error) {
        consoleLog(
          "Une erreur s'est produite lors de la validation du numéro de téléphone :",
          error.message
        );
      }
    };

    const getUserLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            const username = "demo";
            const apiUrl = `http://api.geonames.org/findNearbyJSON?lat=${latitude}&lng=${longitude}&username=${username}`;

            axios
              .get(apiUrl)
              .then((response) => {
                const data = response.data;

                setCountryInfoGeo(data?.geonames[0]);
                setDetectedCountry(
                  getCountry(data?.geonames[0]?.countryCode, countries)
                );
              })
              .catch((error) => {
                consoleLog(
                  "Erreur lors de la récupération des informations de géolocalisation :",
                  error
                );
              });
          },
          (error) => {
            consoleLog(
              "Erreur lors de la récupération de la géolocalisation :",
              error
            );
          }
        );
      } else {
        consoleLog(
          "La géolocalisation n'est pas prise en charge dans ce navigateur."
        );
      }
    };

    const errorPresent = () => {
      let error = false;

      if (mail) {
        if (!goodFormatEmail(mail)) {
          error = true;
          setEmailHasError(error);
          setEmailMsgError(t("bad_email_format"));
        }
        userExist && (error = userExist);
      } else {
        error = true;
        setEmailHasError(error);
        setEmailMsgError(t("loginpage_error_required"));
      }

      if (password) {
        if (!goodFormatPwd(password) || !hasSpecialChar) {
          error = true;
          setPwdHasError(error);
        }
      } else {
        error = true;
        setPwdHasError(error);
        setPwdMsgError(t("loginpage_error_required"));
      }

      if (confirmPassword) {
        if (confirmPassword !== password) {
          error = true;
          setConfirmPwdHasError(error);
          setConfirmPwdMsgError("Passwords do not match");
        }
      } else {
        error = true;
        setConfirmPwdHasError(error);
      }

      if (!infoCountry) {
        error = true;
        setCountryHasError(error);
        setCountryMsgError(t("loginpage_error_required"));
      }

      if (!phone) {
        error = true;
        setPhoneHasError(error);
        setPhoneMsgError(t("loginpage_error_required"));
      } else {
        if (
          phone.length > 8 &&
          !validPhoneNumber({
            number: "+" + infoCountry?.phone + phone,
            codeCountry: infoCountry?.code,
          })
        ) {
          error = true;
          setPhoneHasError(error);
          setPhoneMsgError(t("bad_phone_format"));
        }
      }

      return error;
    };

    const checkForErrors = () => {
      const isError = errorPresent(); // Remplacez cela par votre logique de vérification

      if (isError) {
        // Gérez l'erreur, par exemple, affichez un message d'erreur
        setContainsError(isError);
        return false;
      } else {
        // Aucune erreur
        dispatch(setReferralCode(referralCode));
        setPersonnalDatas({
          email: mail,
          password,
          country: infoCountry?.label,
          phone: phone,
          address,
          city,
          zipCode,

          hasDigit,
          hasGoodSize,
          hasLower,
          hasUpper,
          hasSpecialChar,
        });

        return true;
      }
    };

    // Exposer la fonction de vérification d'erreur au parent via la ref
    useImperativeHandle(ref, () => ({
      checkForErrors,
    }));

    const handleEmail = (e) => {
      setEmailMsgError("");
      setEmailHasError(false);
      const email = e.target.value;
      setMail(email);
      setUserExist(false);

      if (goodFormatEmail(email)) {
        getUserByEmail({ email })
          .then((res) => {
            const exist = res.data !== null && res.data !== undefined;
            setUserExist(exist);
            setEmailMsgError("error_email_exist");
            setEmailHasError(exist);
          })
          .catch((e) => {
            setUserExist(false);
          });
      }
    };

    const handleConfirmPassword = (e) => {
      setConfirmPwdHasError(false);
      setConfirmPwdMsgError("");
      setConfirmPassword(e.target.value);
    };

    const handlePassword = (e) => {
      setPwdMsgError("");
      setPwdHasError(false);
      const pass = e.target.value;
      setPassword(pass);

      setHasGoodSize(pass?.length >= 6);
      setHasUpper(/[A-Z]/.test(pass));
      setHasLower(/[a-z]/.test(pass));
      setHasDigit(/\d/.test(pass));
      setHasSpecialChar(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(pass));
    };

    return (
      <FadeComponent>
        <div className="container formular">
          <Typography align="left" variant="h5">
            {t("register_personalform_title")}
          </Typography>
          <Box className="form" component="form" noValidate autoComplete="on">
            <Stack>
              <TextField
                error={emailHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                label={`${t("email_address_label")}*`}
                variant="outlined"
                color="warning"
                type="email"
                className="item"
                value={mail}
                onChange={(e) => {
                  handleEmail(e);
                }}
              />
              <FormHelperText className="errorText" id="component-error-text">
                {emailHasError && t(emailMsgError)}
              </FormHelperText>

              <TextField
                error={pwdHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Security />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <ShowPassword
                        show={showPassword}
                        setShow={() => setShowPassword(!showPassword)}
                      />
                    </InputAdornment>
                  ),
                }}
                label={`${t("password_label")}*`}
                variant="outlined"
                color="warning"
                type={showPassword ? "text" : "password"}
                className="item"
                value={password}
                onChange={(e) => {
                  handlePassword(e);
                }}
              />
              <Collapse in={password}>
                <FormHelperText
                  className="contentPwd"
                  id="component-error-text"
                >
                  {/* {pwdHasError && pwdMsgError} */}
                  <div className="indicator">
                    <Done
                      sx={{ width: 17 }}
                      color={hasGoodSize ? "success" : "disabled"}
                    />{" "}
                    <span style={{ color: hasGoodSize && "green" }}>
                      {t("register_min_caracter")}
                    </span>
                  </div>
                  <div className="indicator">
                    <Done
                      sx={{ width: 17 }}
                      color={hasUpper ? "success" : "disabled"}
                    />{" "}
                    <span style={{ color: hasUpper && "green" }}>
                      {t("register_min_upperCase")}
                    </span>
                  </div>
                  <div className="indicator">
                    <Done
                      sx={{ width: 17 }}
                      color={hasLower ? "success" : "disabled"}
                    />{" "}
                    <span style={{ color: hasLower && "green" }}>
                      {t("register_min_lowercase")}
                    </span>
                  </div>
                  <div className="indicator">
                    <Done
                      sx={{ width: 17 }}
                      color={hasDigit ? "success" : "disabled"}
                    />{" "}
                    <span style={{ color: hasDigit && "green" }}>
                      {t("register_min_digit")}
                    </span>
                  </div>
                  <div className="indicator">
                    <Done
                      sx={{ width: 17 }}
                      color={hasSpecialChar ? "success" : "disabled"}
                    />{" "}
                    <span style={{ color: hasSpecialChar && "green" }}>
                      {t("register_min_special_caracter")}
                    </span>
                  </div>
                </FormHelperText>
              </Collapse>

              <TextField
                error={confirmPwdHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Security />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <ShowPassword
                        show={showConfirmPassword}
                        setShow={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    </InputAdornment>
                  ),
                }}
                label={`${t("confirm_password_label")}*`}
                variant="outlined"
                color="warning"
                type={showConfirmPassword ? "text" : "password"}
                className="item"
                value={confirmPassword}
                onChange={(e) => {
                  handleConfirmPassword(e);
                }}
              />
              <FormHelperText className="errorText" id="component-error-text">
                <Collapse in={confirmPassword && confirmPwdHasError}>
                  {confirmPwdMsgError}
                </Collapse>
              </FormHelperText>

              <div className="item">
                <CountryInfo
                  defaultValue={detectedCountry}
                  setInfoCountry={setInfoCountry}
                  selectCountryName={true}
                  setValue={setNumber}
                />
              </div>

              <TextField
                error={phoneHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="body">+{number}</Typography>
                    </InputAdornment>
                  ),
                }}
                label={`${t("phone_number_label")}*`}
                variant="outlined"
                color="warning"
                className="item"
                value={phone}
                onChange={(e) => {
                  setPhoneMsgError("");
                  setPhoneHasError(false);
                  setPhone(validateNumber(e.target.value));
                }}
                placeholder="(ex : 423445556)"
              />
              <FormHelperText className="errorText" id="component-error-text">
                {phoneHasError && t(phoneMsgError)}
              </FormHelperText>

              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                label={`${t("address_label")}`}
                variant="outlined"
                color="warning"
                type="text"
                className="item"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />

              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCity />
                    </InputAdornment>
                  ),
                }}
                label={`${t("city_label")}`}
                variant="outlined"
                color="warning"
                type="text"
                className="item"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />

              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MarkunreadMailbox />
                    </InputAdornment>
                  ),
                }}
                label={`${t("zipcode_label")}`}
                variant="outlined"
                color="warning"
                type="text"
                className="item"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />

              {(
                <TextField
                  label={`Referral Code`}
                  variant="outlined"
                  color="warning"
                  type="text"
                  className="item"
                  value={referralCode?.toUpperCase()}
                  onChange={(e) => setReferral(e.target.value)}
                  // disabled={referralCode && referralCode !== "undefined"}
                />
              )}

              <Typography
                fontStyle={"italic"}
                paddingTop={2}
                textAlign={"left"}
                color={"GrayText"}
                variant="caption"
              >
                {t("instruction_required_field")}
              </Typography>
            </Stack>
            <div onClick={handleComplet} className="validateBtn">
              {t("register_next_btn")}
            </div>
          </Box>
        </div>
      </FadeComponent>
    );
  }
);

export default PersonalsDatasForm;
