export function removeItem(localStorageName){
    localStorage.removeItem(localStorageName);
}

export function getItem(item){
    return localStorage.getItem(item);
}

export function addItem(localStorageName ,newItem){
    localStorage.setItem(localStorageName ,newItem);
    
}

